import React, { Component } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import '../../scss/components/CategorizedInvestments.scss'
import TotalInvestments from './TotalInvestments'
export default class CategorizedInvestments extends Component {
  state = {
    activeTab: 0,
    stockHoldings: null,
    cryptoHoldings: null,
    roboHoldings: null,
    aiStockHoldings: null,
    aiCryptoHoldings: null,
    goalsHoldings: null,
  }

  componentDidMount() {
    this.getHoldings()
  }

  getHoldings = () => {
    if (this.props.accounts) {
      let stockHoldings = null
      let cryptoHoldings = null
      let roboHoldings = null
      let aiStockHoldings = null
      let aiCryptoHoldings = null
      let goalsHoldings = null

      console.log(this.props.accounts)
      this.props.accounts.forEach((account) => {
        if (
          account?.product_assigned &&
          account?.holdings &&
          account.holdings.length > 0
        ) {
          switch (account.product_assigned) {
            case 'trade':
              stockHoldings = account.holdings
              break
            case 'equity':
              stockHoldings = account.holdings
              break
            case 'crypto':
              cryptoHoldings = account.holdings
              break
            case 'robo':
              roboHoldings = account.holdings
              break

            case 'group':
              aiStockHoldings = account.holdings
              break
            case 'group-crypto':
              aiCryptoHoldings = account.holdings
              break
            case 'goal':
              goalsHoldings = account.holdings
              break
            default:
              break
          }
        }
      })
      this.setState(
        {
          stockHoldings: stockHoldings,
          cryptoHoldings: cryptoHoldings,
          roboHoldings: roboHoldings,
          aiStockHoldings: aiStockHoldings,
          aiCryptoHoldings: aiCryptoHoldings,
          goalsHoldings: goalsHoldings,
        },
        () => {
          let activeIndex
          if (stockHoldings) activeIndex = 0
          else if (cryptoHoldings) activeIndex = 1
          else if (roboHoldings) activeIndex = 2
          else if (aiStockHoldings) activeIndex = 3
          else if (aiCryptoHoldings) activeIndex = 4
          else if (goalsHoldings) activeIndex = 5
          else activeIndex = 6
          this.setActiveIndex(activeIndex)
        }
      )
    }
  }

  setActiveIndex = (index) => {
    this.setState({
      activeTab: index,
    })
  }

  render() {
    if (
      !this.state.stockHoldings &&
      !this.state.cryptoHoldings &&
      !this.state.roboHoldings &&
      !this.state.aiStockHoldings &&
      !this.state.aiCryptoHoldings &&
      !this.state.goalsHoldings &&
      this.props.t
    ) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '100%',
            margin: 'auto',
            boxShadow:
              '-3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21)',
            borderRadius: '19px',
            height: 'auto',
            maxHeight: '100%',
            overflow: 'auto',
          }}
        >
          <p
            style={{
              fontFamil: 'Akkurat-Bold',
              fontSize: '25px',
              textAlign: 'center',
              padding: '25px',
              margin: 0,
            }}
          >
            {this.props.t('noinvestments')}
          </p>
        </div>
      )
    } else {
      return (
        <div className='categorized-investments-container'>
          <TabView
            activeIndex={this.state.activeTab}
            onTabChange={(e) => this.setActiveIndex(e.index)}
            className='categorized-investments-tab'
          >
            {this.state.stockHoldings ? (
              <TabPanel
                className='investments-tab'
                header={this.props.t('stock')}
              >
                <TotalInvestments
                  holdings={this.state.stockHoldings.filter(
                    (stock) => stock.symbol?.length < 6
                  )}
                  formatCurrency={this.props.formatCurrency}
                  isSmallScreen={this.props.isSmallScreen}
                  t={this.props.t}
                />
              </TabPanel>
            ) : null}
            {/* {this.state.stockHoldings ? (
              <TabPanel
                className='investments-tab'
                header={this.props.t('options')}
              >
                <TotalInvestments
                  holdings={this.state.stockHoldings.filter(
                    (stock) => stock.symbol?.length > 6
                  )}
                  formatCurrency={this.props.formatCurrency}
                  isSmallScreen={this.props.isSmallScreen}
                  t={this.props.t}
                />
              </TabPanel>
            ) : null} */}
            {this.state.cryptoHoldings ? (
              <TabPanel
                className='investments-tab'
                header={this.props.t('crypto')}
              >
                {' '}
                <TotalInvestments
                  holdings={this.state.cryptoHoldings}
                  formatCurrency={this.props.formatCurrency}
                  isSmallScreen={this.props.isSmallScreen}
                  t={this.props.t}
                />
              </TabPanel>
            ) : null}
            {this.state.roboHoldings ? (
              <TabPanel
                className='investments-tab'
                header={this.props.t('robo')}
              >
                {' '}
                <TotalInvestments
                  holdings={this.state.roboHoldings}
                  formatCurrency={this.props.formatCurrency}
                  isSmallScreen={this.props.isSmallScreen}
                  t={this.props.t}
                />
              </TabPanel>
            ) : null}
            {this.state.aiStockHoldings ? (
              <TabPanel
                className='investments-tab'
                header={this.props.t('ai stock trader')}
              >
                {' '}
                <TotalInvestments
                  holdings={this.state.aiStockHoldings}
                  formatCurrency={this.props.formatCurrency}
                  isSmallScreen={this.props.isSmallScreen}
                  t={this.props.t}
                />
              </TabPanel>
            ) : null}
            {this.state.aiCryptoHoldings ? (
              <TabPanel
                className='investments-tab'
                header={this.props.t('ai crypto trader')}
              >
                {' '}
                <TotalInvestments
                  holdings={this.state.aiCryptoHoldings}
                  formatCurrency={this.props.formatCurrency}
                  isSmallScreen={this.props.isSmallScreen}
                  t={this.props.t}
                />
              </TabPanel>
            ) : null}
            {this.state.goalsHoldings ? (
              <TabPanel
                className='investments-tab'
                header={this.props.t('goal')}
              >
                {' '}
                <TotalInvestments
                  holdings={this.state.goalsHoldings}
                  formatCurrency={this.props.formatCurrency}
                  isSmallScreen={this.props.isSmallScreen}
                  t={this.props.t}
                />
              </TabPanel>
            ) : null}
          </TabView>
        </div>
      )
    }
  }
}
