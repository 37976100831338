import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { RiArrowRightDoubleLine } from 'react-icons/ri'

export default function TradePreviewRedirect({
  group_reference,
  newt_res_data,
}) {
  const trade_data = useMemo(() => {
    return newt_res_data?.result_data?.[group_reference] ?? {}
  }, [newt_res_data, group_reference])

  const { price_change_value = 0, price_change_percent = 0 } = trade_data

  const getPriceChangeDetails = () => {
    if (price_change_value > 0) {
      return {
        color: 'green',
        triangle: '\u25B2 ', // ▲
        label: `Price increased by ${price_change_percent}%`,
      }
    } else if (price_change_value < 0) {
      return {
        color: 'red',
        triangle: '\u25BC ', // ▼
        label: `Price decreased by ${Math.abs(price_change_percent)}%`,
      }
    } else {
      return {
        color: 'inherit',
        triangle: '',
        label: 'No price change',
      }
    }
  }

  const { color, triangle, label } = getPriceChangeDetails()

  return (
    <div className='trade-preview-redirect-container'>
      <NavLink
        exact={true}
        className={'redirect-link-container'}
        to={{
          pathname: `/Dashboard/Trade/${trade_data.symbol}`,
          state: {
            order_type: trade_data.order_type,
            total: trade_data.dollar_amount
              ? trade_data.dollar_amount
              : trade_data.quantity,

            invest: trade_data.dollar_amount ? 'dollars' : 'shares',
            order_price: trade_data.price ? trade_data.price : null,
            tab: trade_data?.trade_action === 'BUY' ? 'Buy' : 'Sell',
          },
        }}
      >
        <div className='top-container'>
          <div className='symbol-icon-text'>
            {trade_data?.image_url ? (
              trade_data?.image_url === 'not-found' ? (
                <div className='no-symbol-icon'>
                  {trade_data?.symbol?.[0] ?? '-'}
                </div>
              ) : (
                <img alt='' src={trade_data?.image_url} />
              )
            ) : (
              <div className='no-symbol-icon'>
                {trade_data?.symbol?.[0] ?? '-'}
              </div>
            )}
            <div>
              <p className='large-text'>{trade_data?.symbol}</p>
              <p className='company-name'>{trade_data?.company_name}</p>
            </div>
          </div>
          <p
            className='large-text'
            style={{
              color: trade_data?.trade_action === 'BUY' ? '#3f46f6' : 'red',
            }}
          >
            {trade_data?.trade_action}
          </p>
        </div>
        {trade_data?.current_price && (
          <p className='current-price'>
            ${trade_data?.current_price}{' '}
            {trade_data?.price_change_value && (
              <span
                style={{ color, display: 'flex', alignItems: 'center' }}
                aria-label={label}
              >
                <span style={{ fontSize: 12 }}>{triangle}</span>
                {price_change_value}({price_change_percent}%)
              </span>
            )}
          </p>
        )}
        <div className='trade-details'>
          {trade_data?.order_type && (
            <div className='trade-detail'>
              <p>Order Type</p>
              <p>{trade_data?.order_type}</p>
            </div>
          )}
          {trade_data?.dollar_amount && (
            <div className='trade-detail'>
              <p>Dollar Amount</p>
              <p>{trade_data?.dollar_amount}</p>
            </div>
          )}
          {trade_data?.quantity && (
            <div className='trade-detail'>
              <p>Quantity</p>
              <p>{trade_data?.quantity}</p>
            </div>
          )}
          {trade_data?.price && (
            <div className='trade-detail'>
              <p>Price</p>
              <p>${trade_data?.price}</p>
            </div>
          )}
        </div>
        <p className='execute-text'>
          Click here to continue <RiArrowRightDoubleLine size={18} />
        </p>
      </NavLink>
    </div>
  )
}
