export const TRANSLATIONS_EN = {
  addnew: 'Add New',
  assets: 'Assets',
  company: 'Company',
  name: 'Name',
  current: 'Current',
  price: 'Price',
  trend: 'Trend',
  editlist: 'Edit List',
  mywatchlist: 'My Watchlist',
  pleaselogintoseeyourwatchlist: 'Please Log in to see your watchlist',
  priceforecast: 'Price Forecast*',
  setupyourwatchlistbyaddingstocks: 'Set up your Watchlist by adding stocks!',
  stopedit: 'Stop Edit',
  stocks: 'Stocks',
  options: 'Options',
  symbol: 'Symbol',
  unusualactivity: 'Unusual Activity',
  welcome: 'Welcome',
  watchlistTab: 'WatchList',
  forecast: 'Forecast',
  months: 'Months',
  month: 'Month',
  changeinprice: 'Change in Price',
  volumechange: 'Volume Change',
  volume: 'Volume',
  removestock: 'Remove',
  fromwatchlist: 'From Watchlist',
  remove: 'Remove',
  stocksearch: 'Stock Search',
  searchforanystock: 'Search for any stock by company name or symbol',
  clickonanyresult: 'Click on any result to add it to your watchlist',
  enterstocksymbol: 'Enter stock by symbol or company name',
  searchabove: 'Search above to see results',
  noresultsfound: 'No results found try a different stock',
  dashboard: 'Dashboard',
  watchlist: 'Watchlist',
  accounts: 'Accounts',
  trade: 'Trade',
  portfolioamount: 'PORTFOLIO AMOUNT',
  investments: 'Investments',
  netchange: 'Net Change',
  allocations: 'ALLOCATIONS',
  portfolioallocations: 'PORTFOLIO ALLOCATIONS',
  cash: 'Cash',
  crypto: 'Crypto',
  equity: 'Equity',
  fixedincome: 'Fixed Income',
  other: 'Other',
  myinvestments: 'MY INVESTMENTS',
  shares: 'Shares',
  costbasis: 'Cost Basis',
  marketvalue: 'Market Value',
  pl: 'P/L',
  showless: 'Show Less',
  showmore: 'Show More',
  investingthemes: 'Investing Themes',
  top30: 'TOP 30',
  etfs: 'ETFs',
  ev: 'EV',
  energy: 'ENERGY',
  finance: 'FINANCE',
  semis: 'SEMIS',
  tech: 'TECH',
  language: 'Language',
  allocation: 'Allocation',
  search: 'Search',
  unusual: 'Unusual',
  stock: 'Stocks',
  robo: 'Robo',
  goal: 'Goal',
  aistock: 'AI Stock',
  aicrypto: 'AI Crypto',
  oneday: '1D',
  onemonth: '1M',
  ytd: 'YTD',
  oneyear: '1Y',
  year: 'Year',
  years: 'Years',
  all: 'ALL',
  transferfunds: 'Transfer Funds',
  accountactivity: 'Account Activity',
  documents: 'Documents',
  equityvalue: 'Equity Value',
  netpl: 'Net P/L',
  pendingtransfers: 'Pending Transfers',
  pendingorders: 'Pending Orders',
  recurringbuys: 'Recurring Buy',
  nextbuy: 'Next Buy',
  amount: 'Amount',
  stop: 'Stop',
  edit: 'Edit',
  summary: 'Summary',
  gainloss: 'Gain/Loss',
  availableshares: 'Availabe Shares',
  buyingpower: 'Buying Power',
  marketorder: 'Market Order',
  limitorder: 'Limit Order',
  ordertype: 'Order Type',
  approxamount: 'Approx. Amount',
  youcannotsellmorethanyouown: 'You cannot sell more than you own',
  buy: 'Buy',
  estimatedquantity: 'Estimated Quantity',
  estimatedcommission: 'Estimated Commission',
  estimatedtotal: 'Estimated Total',
  submittrade: 'Submit Trade',
  from: 'From',
  to: 'To',
  depositamount: 'Deposit Amount',
  withdrawalamount: 'Withdrawal Amount',
  wiredeposit: 'Wire Deposit',
  wirewithdraw: 'Wire Withdraw',
  youcanonlydeposit5000perday:
    'You can only deposit $5,000 per day and make a total of 5 deposits per day across all of your Trading Accounts.',
  submit: 'Submit',
  tradeconfirmation: 'Trade Confirmation',
  accountstatements: 'Account Statements',
  taxdocuments: 'Tax Documents',
  pleaseenteramountgreaterthan0: 'Please enter amount greater than 0',
  notenoughbuyingpower: 'Not enough buying power',
  pleaseentersharesgreaterthan0: 'Please enter shares greater than 0',
  onlymarketordersaresupportedwithfractionalshares:
    'Only market orders are supported with fractional shares',
  selectanaccount: 'Select an account',
  quantity: 'Quantity',
  filledprice: 'Filled Price',
  amount: 'Amount',
  cannotenterfractionalsharesforlimitorder:
    'Cannot enter fractional shares for limit order',
  limitordersaregooduntilnextmarketclose:
    'Limit orders are good until next market close',
  somethingwentwrong: 'Something went wrong. Please try again later',
  areyousureyouwanttocancelthisorder:
    'Are you sure you want to cancel this order',
  confirmation: 'Confirmation',
  notsupportedfortrading: 'Not supported for trading',
  approxnumberofshares: 'Approx. Number of shares',
  approxtotalamount: 'Approx. Total amount',
  amountcannotbegreaterthan5000: 'Amount cannot be greater than 5000',
  transfersarenotavailableatthistime:
    'Transfers are not available at this time',
  transfersareavailabefrom430to130:
    'Transfers are available from 4:30 AM to 1:30 PM EST business days.',
  cannottransfermorethanyouown: 'Cannot transfer more than you own',
  cannotwithdrawmorethanyouown: 'Cannot withdraw more than you own',
  allfieldsarerequired: 'All fields are required',
  transactionhasbeensuccessfullycompleted:
    'Transaction has been successfully completed',
  wiredepositinstructions: 'Wire Deposit Instructions',
  wiredepositinstructions1:
    'Please include this account number in memo description while making wire transfer.',
  wiredepositinstructions2:
    "Note: Some international wires require an IBAN number,but bank accounts in the US do not have an IBAN. You still need to provide DriveWealth's Beneficiary Account Number along withthe SWIFT Code to initiate an international wire transfer",
  wiredepositinstructions3:
    'Please note all funds held in this account are insured by the SIPC (US-government) up to $500,000',
  wiredepositinstructions4:
    'The bank account must be in the same name as your Investall account. All third-party wires will be rejected.',
  wiredepositinstructions5:
    'The beneficiary name must be in the same as your Investall account. All third-party wires will be rejected.',
  remittanceinformation: 'Remittance Information',
  usdollaramount: 'U.S. Dollar amount',
  recipientbankname: 'Recipient Bank Name',
  bankaddress: 'Bank Address',
  abanumber: 'ABA Number',
  swiftcode: 'SWIFT Code',
  bankaccountnumber: 'Bank Account Number',
  forfinalcreditto: 'For Final Credit to (FFC)',
  selectaccounttoview: 'Select Account to View',

  wirewithdrawal: 'Wire Withdrawal',
  fromaccount: 'From Account',
  beneficiaryname: 'Beneficiary Name',
  fullname: 'Full Name',
  beneficiaryaccountnumber: 'Beneficiary Account Number',
  accountnumber: 'Account Number',
  beneficiaryaccounttype: 'Beneficiary Account Type',
  accounttype: 'Account Type',
  beneficiaryswiftaba: 'Beneficiary Swift/ABA',
  swiftcodeoraba: 'Swift Code or ABA',
  beneficiarybankname: 'Beneficiary Bank Name',
  beneficiarybankaddress: 'Beneficiary Bank Address',
  beneficiarybankcity: 'Beneficiary Bank City',
  bankcity: 'Bank City',
  beneficiarybankprovince: 'Beneficiary Bank Province',
  bankprovincestate: 'Bank Province/State',
  beneficiarybankzipcode: 'Beneficiary Zip Code',
  bankzipcode: 'Bank Zip Code',
  beneficiarybankcountry: 'Beneficiary Bank Country',
  bankcountry: 'Bank Country',
  bankname: 'Bank Name',
  amounttowithdraw: 'Amount to Withdraw',
  notes: 'Notes',
  enternoteshere: 'Enter notes here',
  withdraw: 'Withdraw',

  sell: 'Sell',
  stockaccount: 'STOCKS',
  cryptoaccount: 'CRYPTO',
  'ai stock traderaccount': 'AI STOCK TRADER',
  'ai crypto traderaccount': 'AI CRYPTO TRADER',
  roboaccount: 'ROBO',
  goalsaccount: 'GOAL',
  goalaccount: 'GOAL',
  dollars: 'Dollars',
  estimatedcost: 'Estimated Cost',
  revieworder: 'Review Order',
  ordersuccessful: 'order successful',
  numberofshares: 'Number of shares',
  sellall: 'Sell All',
  limitprice: 'Limit Price',
  chart: 'Chart',
  analysis: 'Analysis',
  stats: 'Stats',
  oneweek: '1W',
  open: 'OPEN',
  high: 'HIGH',
  low: 'LOW',
  portfoliodetails: 'PORTFOLIO DETAILS',
  accountvalue: 'Account Value',
  products: 'PRODUCTS',
  products2: 'Products',
  market: 'Market',
  limit: 'Limit',
  investin: 'Invest In',
  pleaseenterlimitpricegreaterthan0: 'Please enter limit price greater than 0',
  deposit: 'Deposit',
  orderhistory: 'Order History',
  transferhistory: 'Transfer History',
  disclaimer: 'Disclaimer',
  disclaimerdescription:
    'All forecasts and trends are generated by our AI forecasting model. They are not intended to be advice or recomendation to buy or sell any stocks. The results do not represent actual trading and actual results may significantly differ from the theoretical results presented. Past performance is not indicative of future performance.',
  currenttrend: 'Current Trend',
  selectyouraccounttodeposit: 'Select your account to deposit',
  amountmustbeatleast5dollars: 'Amount must be at least 5 dollars',
  amountmustbeatleast1dollar: 'Amount must be at least 1 dollar',
  pleaseopenanaccounttotrade: 'Please open an account to trade',
  gfv1: 'This may cause a Good Faith Violation if you sell before funds get settled. After three Good Faith Violations within a 12 month period you will not be able to trade stocks with unsettled cash for 90 days',
  gfv2: 'This may be your third Good Faith Violation within a 12 month period if you sell before funds get settled. This means you will not be able to trade stocks with unsettled cash for 90 days',
  thismarketorderwillexecuteaftermarkethours:
    'This market order will execute after 9:30 AM EST',
  goodfaithviolationalert: 'Good Faith Violation Alert',
  youareallcaughtup: 'You are all caught up',
  transfer: 'Transfer',
  bearish: 'Bearish',
  bullish: 'Bullish',
  percentchange: '%Change',
  riskforecast: 'Risk Forecast',
  historicaldrawdown: 'Historical Drawdown',
  risk: 'Risk',
  maxdrawdown: 'Max Drawdown',
  historicalstresstest: 'Historical Stress Test',
  neutral: 'Neutral',
  marketcap: 'Market Cap',
  peratio: 'P/E Ratio',
  dailyvolume: 'Daily Volume',
  volume10davg: 'Volume 10D Avg',
  dividendamount: 'Dividend Amount',
  dividendyield: 'Dividend Yield',
  sharesoutstanding: 'Shares Outstanding',
  shortinterest: 'Short Interest',

  personalinformation: 'Personal Information',
  linkedaccounts: 'Linked Accounts',
  manage: 'Manage',
  faqs: 'FAQs',
  support: 'Support',
  email: 'Email',
  phone: 'Phone',
  accountinformation: 'Account Information',
  signout: 'Sign Out',
  banking: 'Banking',
  investment: 'Investment',
  linkabankaccount: 'Link a Bank Account',
  linkainvestmentaccount: 'Link a Investment Account',
  subscriptionmanage: 'Subscription-Manage',
  investmentquestionnaireupdate: 'Investment Questionnaire-UPDATE',
  changepassword: 'Change Password',
  oldpassword: 'Old Password',
  password: 'Password',
  newpassword: 'New Password',
  confirmnewpassword: 'Confirm New Password',

  activaterobo: 'Activate ROBO',
  'activateai crypto trader': 'Activate AI Crypto Trader',
  'activateai stock trader': 'Activate AI Stock Trader',
  continueautomatedtradingby:
    'Continue automated trading by activating your account',
  activate: 'Activate',
  investallrobo: 'Investall Robo',
  investmentamount: 'Investment Amount',
  periodiccontribution: 'Periodic Contribution',
  strategyusedbyrobo: 'Strategy used by Robo',
  roboisnotactiveyet: 'Robo is not active yet',
  startrobonowtoinvest:
    "Start Robo now to invest based on Investall's automated strategy tailored to your profile",
  monthtodate: 'Month to Date',
  yeartodate: 'Year to Date',
  expectedpotentialrisk: 'Expected Potential Risk(30 Days Forecast)',
  viewallocations: 'View Allocations',
  gipscompliant: 'GIPS compliant',

  investmentcalculator: 'Investment Calculator',
  value: 'Value',
  values: 'Values',
  in10years: 'in 10 years',
  growth: 'Growth',
  aminimumof500isrequired:
    'A minimum of $500 is required to start the Investall Robo.',
  initialinvestment: 'Initial Investment',
  monthlycontribution: 'Monthly Contribution',
  weeklyroundup: 'Weekly Roundup',
  addcash: 'Add Cash',
  additional: 'Additional',
  donthaveanaccount: 'Dont have an account?',
  yoursubscription: 'Your Subscription',
  status: 'Status',
  autorenewson: 'Auto-renews-on',
  downgradetobasic: 'Downgrade to Basic',
  boosttopro: 'Boost to Pro',
  includesallfeaturesofinvestallbasicandmore:
    'Includes all features of Investall and More!',
  freetrial: '30 Days Free Trial',
  afterward: '/$3.99 afterward',
  start1monthsfreetrial: 'Start 1-Month Free Trial',
  cancelanytime: 'Cancel Anytime',
  investingmaderight: 'Investing Made Right',
  investinoursignaturerobo: 'Invest in our Signature Robo-Strategies',
  convenience: 'Convenience',
  letouraidaytradeforyou:
    'Let our AI Day Trade for you with our one of a kind Trade Groups',
  improveyourspending: 'Improve your spending',
  letourbudgetingsoftware:
    'Let our budgeting software help you understand your spending',
  peaceofmind: 'Peace of Mind',
  prepareforyourfuture:
    'Prepare for your future using our goals planning technologies',
  financialsecurity: 'Financial Security',
  getthestatstobackup:
    'Get the stats to back up your decisions using risk analytics tools',
  trialmessage:
    'Trial begins immediately after you subscribe. You will be charged only after 1 month',
  trialmessage2: 'Some products require a minimum of $500 to activate',

  whatisthemainreasonforyourinvestment:
    'What is the main reason for your investment?',
  shortterm: 'Short Term',
  mediumterm: 'Medium Term',
  longterm: 'Long Term',
  howdoyouwishtoinvest: 'How long do you wish to invest?',
  whatisyourrisktolerance: 'What is your risk tolerance?',
  minimizelosses: 'Minimize losses',
  gainswhilelimitinglosses: 'Gains while limiting losses',
  maximizegains: 'Maximize gains',
  whatisyourinvestmentexperience: 'What is your investment experience?',
  noexperience: 'No Experience',
  someexperience: 'Some Experience',
  considerableexperience: 'Considerable Experience',
  whatisyourinvestmentobjective: 'What is your investment objective?',
  longtermbuyhold: 'Long term buy & hold',
  tradinginfrequently: 'Trading infrequently',
  frequenttrader: 'Frequent Trader',
  activedailytrader: 'Active daily trader',
  newtoinvesting: 'New to investing',
  investmentperiod: 'Investment Period',
  pleasecompleteallfields: 'Please complete all fields',
  questionnaireupdatedsuccessfully: 'Questionnaire updated successfully',
  investmentquestionnaire: 'Investment Questionnaire',
  cannotactivateaccountduringnontradinghours:
    'Cannot activate account during non-trading hours',
  pleaseselectoneofthevaluesabove: 'Please select one of the values above',

  pleasedonotleavethispage: 'Please do not leave this page',
  pleasechooseavaluegreaterthan0orroundupoption:
    'Please choose a value greater than 0 or round up option',
  roundup: 'Round up',
  areyousureyouwanttodelete: 'Are you sure you want to delete',
  recurringbuy: 'Recurring buy',
  daily: 'Daily',
  weekly: 'Weekly',
  biweekly: 'Bi-weekly',
  monthly: 'Monthly',

  youhavebeensuccessfullyunsubscribedtoinvetallpro:
    'You have been successfully unsubscribed from Investall Pro',
  youhavebeensuccessfullysubscribedtoinvestallpro:
    'You have been successfully subscribed to Investall Pro',
  successfullysubscribed: 'Successfully subscribed',
  thisaccountneedstoberelinked: 'This account needs to be relinked',
  areyousureyouwanttoremovethisaccount:
    'Are you sure you want to remove this account',
  areyousureyouwanttoproceed: 'Are you sure you want to proceed',
  youhavebeenloggedout: 'You have been logged out. Click here to log in',

  pleasechooseabankaccount: 'Please choose a bank account',
  selectfrequency: 'Select Frequency',
  changesmadesuccessfully: 'Changes made successfully',
  successfullyunsubscribed: 'Successfully unsubscribed',
  availabletowithdraw: 'Available to withdraw',

  //LANDING PAGE
  business: 'Business',
  signup: 'Sign up',
  login: 'Log In',
  landingpagetitle: 'Smarter Investing Powered by AI',
  landingpageinfo:
    'Invest in fractional shares of stocks and crypto with as little as $1 and Zero Commission.',
  investallforecast: 'INVESTALL FORECAST',
  threemopriceforecast: '3mo. Price Forecast*',
  currentprice: 'Current Price',
  findouttheshortterm:
    'Find out the short term and long term forecasts for your favorite coins.',
  gettheapp: 'Get the App',
  features: 'Features',
  automatedinvestingmadeeasy: `Automated Investing Made Easy`,
  'Invest Robo': 'Investall Robo',
  'AI Trader': 'AI Trader',
  Goals: 'Goals',
  Budgeting: 'Budgeting',
  learnmore: 'Learn More',
  roboinfo:
    'Our AI provides proactive risk monitoring and disciplined execution of investment decisions. Keeping you informed about your portfolio every step of the way.',
  aitraderinfo:
    'Optimize your performance and save time by activating AI Trader. This automated system relies on price trend forecasting and AI signals to execute trades at the best time.',
  goalsinfo:
    'Saving for the future is exciting, and we can make it simple too. Whether it’s for higher education, a fun getaway or buying a home, Goals can help you get there with periodic contributions and expert strategies.',
  budgetinginfo:
    'Aggregate all of your financial accounts into one place. The more accounts you link, the more our AI can help you assess your risk, spending habits, and more.',

  home: 'Home',
  about: 'About',
  contact: 'Contact',
  careers: 'Careers',
  legal: 'Legal',
  privacypolicy: 'Privacy Policy',
  termsofuse: 'Terms of Use',
  eula: 'EULA',
  generaldisclaimer: 'General Disclaimer',
  disclosures: 'Disclosures',

  createanaccount: 'Create an Account',
  phonenumber: 'Phone Number',
  forgotyourpassword: 'Forgot your password',
  pleaseagreetoprivacypolicyandeula:
    'Please agree to the Privacy Policy and EULA',
  pleaseenterpassword: 'Please Enter Password',
  pleaseentervalidemail: 'Please enter a valid email',
  chooseaverificationmethod: 'Choose a Verification Method',
  alreadyreceivedcode: 'Already received code?',
  entercode: 'Enter Code',
  verificationcodesentmessage:
    'Verification Code sent . Please enter the code to get started',
  didntgetthecode: "Didn't get the code?",
  resendcode: 'Resend Code',
  failedtoresentcode: 'Failed to re-send code',
  invalidcode: 'Invalid code',
  pleaseenteryouremaildownbelow: 'Please enter your email down below',
  confirm: 'Confirm',
  creataccount: 'Create Account',
  iagreetothe: 'I agree to the',

  invalidphonenumber: 'Invalid Phone Number',
  invalidpassword: 'Invalid Password',
  codehasbeensent: 'Code has been sent',
  getstarted: 'Get Started',
  seeproducts: 'See Products',

  cryptotabtitle: 'Automate Your Crypto with AI Trader.',
  cryptotabinfo:
    'Explore the world’s top cryptocurrencies from Bitcoin to Ethereum and beyond. Use our pro AI Trading tool to protect your investment. Buy, Sell and trade now by downloading our app.',
  cryptocurrencyforecast: 'CRYPTOCURRENCY FORECAST',

  investing: 'INVESTING',
  institutionallevelroboinvesting: 'Institutional level ROBO Investing',
  producttabinfo:
    'Get our most advanced trading strategies, with GIPS audited track record of outperforming other top robo-advisors.',
  dynamicassetmanagement: 'Dynamic asset management',
  startrobo: 'START ROBO',

  investrobo: 'INVEST ROBO',
  whypickstockswhenyoucanletouraiinvestforyou:
    'Why pick stocks when you can let our AI invest for you',
  trustedbythebigbanks: 'Trusted by the big banks',
  bigbanksinfo:
    'Proven track record outperforming other top robo advisors, now available to the masses',
  tailoredtoyourchoices: 'Tailored to your choices',
  tailoredinfo:
    'Complete an investment questionaire and our AI picks the best strategy for your account',
  highlyliquid: 'Highly Liquid',
  highlyinfo:
    'Change your mind with ease, get your money when you need it. Montly rebalancing based on the current market',

  aitrader: 'AI Trader',
  aitraderheader:
    'Select up to four stocks or four cryptos for our AI Trader to trade',
  aitraderinfo1:
    'All day trades occur based on price momentum shifts detected by our AI.',
  aitraderinfo2:
    'Leverage AI-powered automated trading strategies, just like the hedge funds use',
  aitraderinfo3:
    'Trades are automatically placed based on momentum shifts - a combination of multiple factors',
  aitraderinfo4:
    'Our AI optimizes the size of trades to best lower risk and maximize returns',

  goals: 'Goals',
  goalsheader:
    'Define your goals. Visualize, reach your target. Aim for the stars land on the moon',
  goalsinfo1: 'Save for your next mortgage',
  goalsinfo2: 'Save a target of 25k Vacation',
  goalsinfo3: 'Emergency Fund for healthcare',
  goalsinfo4: 'Or Long Term goal for Retirement',

  budgetingheader: 'Financial planning in one central dashboard',
  budgetinginfo1:
    'Suitable for any long term savings goal - whether you’re preparing for retirement, a college fund, or finally saving up for that big purchase',
  budgetinginfo2:
    'Simply set a savings goal and investment strategy for your portfolio and let ForeSCITE guide your investing and spending habits.',
  budgetinginfo3:
    'Set up automated monthly contributions to help keep you on track',
  freeversion: 'Free Version',
  freeversioninfo:
    'Get started right away with our Self-Directed Investing, Robo Investor, and Price Trend Forecaster.',
  upgradetools: 'Upgrade tools',
  upgradetoolsinfo:
    'Upgrade your trading experience with Budgeting, Goals, our AI-Powered Group Trader.',
  enteryourpasswordbelow: 'Enter your password below',
  yourpasswordhasbeenreset: 'Your password has been reset',
  incorrectpassword: 'Incorrect Password',
  advisoryagreement: 'Advisory Agreement',
  enteryournewpasswordbelow: 'Enter your new password below',
  pleaseenterthecodetoverifythisisyouremail:
    'Please enter the code to verify this is your email',
  usernamenotfound: 'Username not found',
  userisnotconfirmed:
    'User is not confirmed. Please enter the confirmation code sent previously or request a new one',
  wesentaverificationcodeto: 'We sent a verification code to',

  back: 'Back',
  technicalanalysis: 'Technical Analysis',
  realtiveindexstrength: 'Relative Strength Index',
  nineday: '9-day',
  expmovingaverage: 'Exponential Moving Average',
  threeday: '3-day',
  tenday: '10-day',
  chaikinmoneyflow: 'Chaikin Money Flow',
  twentyoneday: '21-day',
  technicallevels: 'Technical Levels',
  resistance: 'Resistance',

  moderate: 'Moderate',
  change: 'Change',
  roboinvestinginfo1:
    'Our AI will choose the right strategy for you that fits your profile',
  roboinvestinginfo2:
    'Our investment strategies are rebalanced periodically based on the current market conditions',
  roboinvestinginfo3:
    'Automatically fund your robo trader with periodic contributions or round up with the spare change from your investments',
  investingmadeeasy:
    'Investing made easy, buy fractional shares with as little as $1',
  investingmadeeasyinfo1:
    'Invest commission-free in individual stocks or bundles of investments (ETFs)',
  investingmadeeasyinfo2:
    'Use our price trends and 30-day price forecasts to guide your investments',
  investingmadeeasyinfo3:
    'Buy fractional shares of high-cost stocks or any stock you would like to invest in',

  setanalert: 'Set an Alert',
  set: 'SET',
  selectfromindicators: 'Select from indicators',
  oneweeksupport: '1 Week Support',
  oneweekresistance: '1 Week Resistance',
  onemonthsupport: '1 Month Support',
  onemonthresistance: '1 Month Resistance',
  bollingersupport: 'Bollinger Support',
  bollingerresistance: 'Bollinger Resistance',
  selectfromcrossovers: 'Select from crossovers',
  bullishcrossover: 'Bullish Crossover',
  bearishcrossover: 'Bearish Crossover',
  alerts: 'Alerts',
  cancelalert: 'Cancel Alert',
  areyousureyouwanttocancelthisalert:
    'Are you sure you want to cancel ths alert?',
  createaccount: 'Create Account',
  logintoyourinvestallaccount: 'Log in to your Investall account',
  enteramount: 'Enter Amount',

  temporarypassword: 'Temporary Password',
  clickheretologin: 'Click here to log in',
  passwordupdatedsuccessfully: 'Password updated successfully',

  inprogress: 'In progress...',
  producthasbeensuccessfullyactivated:
    ' Product has been successfully activated',

  overview: 'Overview',
  active: 'Active',
  startdate: 'Start Date',
  contribution: 'Contribution',
  currentvalue: 'Current Value',
  rebalanceinformation: 'Rebalance Information',
  nextrebalance: 'Next Rebalance',
  lastrebalancedate: 'Next Rebalance Date',
  daysuntilnextrebalance: 'Days until next Rebalance',
  intramonth: 'Intramonth',

  clickheretodownload: 'Click here to download',
  noinvestments: 'No Investments',
  noorderhistory: 'No Order History',
  notransferhistory: 'No Transfer History',
  notaxdocuments: 'No Tax Documents',
  noaccountstatements: 'No Account Statements',
  notradeconfirmations: 'No Trade Confirmations',

  deactivaterobo: 'Deactivate Robo',
  robohasbeensuccessfullydeactivated: 'Robo has been successfully de-activated',
  cannotdeactivateroboduringnontradinghours:
    'Cannot de-activate Robo during non-trading hours',
  periodiccontributionupdatedsuccessfully:
    'Periodic Contribution updated successfully',
  thesparechangemessage1:
    'The spare change from your daily purchases will be aggregated and invested into this account every day.',
  thesparechangemessage2:
    'This feature will round up the spare change from all your linked bankng and credit accounts',
  clickhereforfrequentlyaskedquestions:
    'Click here for frequently asked questions',

  pleaseenteryouroldpassword: 'Please enter your old password',
  pleaseconfirmyournewpassword: 'Please confirm your new password',
  yournewpasswordmustbeeightormorecharacters:
    'Your new password must be eight or more characters',
  newpasswordandconfirmdoesnotmatch:
    'New password and confirmation does not match',
  incorrectusernameorpassword: 'Incorrect username of password',
  youroldpasswordisincorrect: 'Your old password is incorrect',
  pleaseenteryournewpassword: 'Please enter your new password',
  passwordmustbegreaterthan8characters:
    'Password must be at least 8 characters',
  userverification: 'User Verification / KYC',
  pleaseclickheretoverifykyc:
    'Your account has not been approved. Please click here to verify KYC',
  kycverification: 'KYC Verification',
  pleaseremovepreviousimagetouploadanotherone:
    'Please remove previous image to upload another one',
  documenthasbeenuploadedforapproval: 'Document has been uploaded for approval',
  failedtouploaddocument: 'Failed to upload document',
  pleasewaituntilverificationiscomplete:
    'Your account is pending. Please wait until verification is completed',
  kycapproved: 'KYC Approved',
  selectdocumenttype: 'Select Document Type',
  documenttype: 'Document Type',
  uploadfrontside: 'Upload Front Side',
  uploadbackside: 'Upload Back Side',

  thiswillcreatea: 'This will create a',
  accountforyou: 'for you',
  wouldyouliketoproceed: 'Would you like to proceed?',
  getrobo: 'Get Robo',
  createroboaccount: 'Create Robo Account',
  createastockaccount: 'Create Stocks Account',

  setuprecurringbuy: 'Set up Recurring Buy',
  editrecurringbuy: 'Edit Recurring Buy',
  recurringbuyupdatedsuccessfully: 'Recurring Buy updated successfully',
  achlinking: 'ACH Linking',
  realtimeverification: 'Real Time Verification',
  transferamount: 'Transfer Amount',
  highrisk: 'High Risk',
  lowrisk: 'Low Risk',
  moderaterisk: 'Moderate Risk',
  logintoyour: 'Log into your',
  beneficiaryaddress: 'Beneficiary Address',
  account: 'account',
  usbankaccountsonly: 'US bank accounts only',
  recurringbuysetupsuccessfully: 'Recurring buy set up successfully',
  orderplacedsuccessfully: 'order placed sucessfully',
  'ai crypto trader': 'AI Crypto Trader',
  'ai stock trader': 'AI Stock Trader',
  sessiontimeout: 'Session Timeout. Please log back in',
  return: 'Return',
  successfullydeposited: 'Successfully Deposited',

  accountbuyingpower: 'Account Buying Power',
  addstocks: 'Add Stocks',
  startaitrader: 'Start AI Trader',
  pleaseselectatleast2stocks: 'Please select at least 2 stocks',
  addastocktoaitrader: 'Add a stock to AI Trader',
  searchbysymbol: 'Search by symbol',
  isrequiredtomeetyourinitialinvestment:
    'is required to meet your initial investment',
  deactivateaistocktrader: 'Deactivate AI Stock Trader',
  aistocktraderhasbeensuccessfullydeactivated:
    'AI Stock Trader has been successfully deactivated',
  cannotdeactivateaistocktraderduringnontradinghours:
    'Cannot deactivate AI Stock Trader during non-during hours',
  removedsuccessfully: 'Removed Successfully',
  accountremovedsuccessfully: 'Account Removed successfully',
  addcrypto: 'Add Crypto',
  cannotselectmorethan8cryptos: 'Cannot select more than 8 cryptos',
  marketsareonlyopenfrom:
    'Markets are only open from 9:30 AM EST to 4:30 PM EST',
  pleaseselectatleast2cryptos: 'Please select at least 2 cryptos',
  editexistingachlinking: 'Edit existing ACH linking',
  deleteexistingachlinking: 'Delete existing ACH linking',
  startaicryptotrader: 'Start AI Crypto Trader',
  aicryptotraderhasbeensuccessfullyactivated:
    'AI Crypto Trader has been successfully activated',
  aistocktraderhasbeensuccessfullyactivated:
    'AI Stock Trader has been successfully activated',
  chooseanoption: 'Choose an Option',
  productdetails: 'Product details',
  signupsuccessful: 'Sign up successful',
  emailaddress: 'Email Address',
  buystocks: 'Buy Stocks',
  signin: 'Log in',
  cryptoisnotsupportedinyourarea:
    'Crypto trading is not available for non US residents',
  thiscryptoisnotsupporedfornewyorkresidents:
    'This crypto is not supported for New York residents',

  getstock: 'Get Stock',
  getcrypto: 'Get Crypto',
  createcryptoaccount: 'Create Crypto Account',
  unopenedrobodetails:
    'Answer a few questions and we will pick one of our strategies that best suits your investment goals and risk level. All of our strategies are highly liquid and rebalanced monthly to keep you positioned to minimize losses and maximize gains.',
  unopenedaistockdetails:
    'Select up to four stocks for our AI to trade on your behalf. All trades occur at based on price momentum shifts detected by our AI.',
  getaistocktrader: 'Get AI Stock Trader',
  createaistocktraderaccount: 'Create AI Stock Trader Account',
  unopenedaicryptodetails:
    'Select up to four cryptos for our AI to trade on your behalf. All trades occur at based on price momentum shifts detected by our AI.',
  getaicryptotrader: 'Get AI Crypto Trader',
  unopenedgoalsdetails:
    'Investment Goals set up traceable plans to put in and aggregate your savings which are invested in our leading strategies to give the best returns for future goals.',
  getgoals: 'Get Goals',
  creategoalsaccount: 'Create Goals Account',
  thiswillcreatecrypto:
    'This will create a Crypto account for you. Would you like to proceed?',
  thiswillcreateaistock:
    'This will create a AI Stock trader account for you. Would you like to proceed?',
  thiswillcreateaicrypto:
    'This will create a AI Crypto trader account for you. Would you like to proceed?',
  thiswillcreategoals:
    'This will create a Goals account for you. Would you like to proceed?',
  noallocations: 'No Allocations',
  oldpasswordcannotbethesameasnewpassword:
    'Old password cannot be the same as new password',

  thisaccountispendingapproval: 'This account is pending approval',
  available: 'Available',
  linkaccount: 'Link Account',
  accountsuccessfullylinked:
    'Account Successfully Linked. It may take up to 2 minutes to be activated',
  doyouwanttolinkthisaccount: 'Do you want to link this account?',
  depositcompletedsuccessfully: 'Deposit completed successfully',
  withdrawcompletedsuccessfully: 'Withdraw completed successfully',
  transfercompletedsuccessfully: 'Transfer completed successfully',
  deactivateaicryptotrader: 'Deactivate AI Crypto Trader',
  aicryptotraderhasbeensuccessfullydeactivated:
    'AI Crypto Trader has been successfully deactivated',
  totalaccountvalue: 'Total Account Value',
  currently: 'Currently',
  ontrack: 'On Track',
  offtrack: 'Off Track',
  target: 'Target',
  deactivategoals: 'Deactivate Goals',
  goalshasbeensuccessfullydeactivated:
    'Goals has been successfully deactivated',
  documentupdatesuccessfully:
    'Document uploaded successfully. You will be updated shortly',
  failedtosenddocument: 'Failed to send document',
  relinkbankaccount: 'Relink Bank Account',
  relinkbrokerageaccount: 'Relink Brokerage Account',
  addbankaccount: 'Add Bank Account',
  addbrokerageaccount: 'Add Brokerage Account',
  forgotpassword: 'Forgot password',
  pleaseenterphonenumber: 'Please enter phone number',
  toyourinvestallaccount: 'to your Investall account',
  failedtoresendcode: 'Failed to resend code',
  bidaskprice: 'Bid/Ask Price',
  bidaskexchange: 'Bid/Ask Exchange',
  daysrange: 'Days Range',
  time: 'Time',
  lasttrade: 'Last Trade',
  lasttradesize: 'Last Trade Size',
  lasttradeexchange: 'Last Trade Exchange',
  showdetails: 'Show Details',
  hidedetails: 'Hide Details',
  youraccountisinpendingstatus: 'Your account is in pending status',
  liquidatestatusmodemessage:
    'Your account is currently in liquidate-only mode. You are only allowed to sell your positions and withdraw funds to your bank account.',
  youraccountisonliquidateonlymode:
    'Your account is in liquidate only mode. Please contact support to rectify this issue',
  fullydilutedvaluation: 'Fully Diluted Valuation',
  circulatingsupply: 'Circulating Supply',
  totalsupply: 'Total Supply',
  nextearningsdate: 'Next Earnings Date',
  nextdividenddate: 'Next Dividend Date',
  earningsexpectedon: 'Earnings: Expected on',
  signintoinvestall: 'Log in to Investall',
  asasignuptoinvestall: 'Please enter an email to connect your ASA account',
  thefutureofinvesting: 'The Future of Investing',
  automatedprecision: 'Automated Precision',
  aggregateallyouraccountsseeinsifhtsandtrendsandtrackyourexpenses:
    'Aggregate all your accounts, see insights and trends and track your expenses',
  cryptotrader: 'Crypto Trader',
  exploretheworlds:
    "Explore the world's top cryptocurrencies from Bitcoin to Ethereum and beyond",
  seeintothefuture: 'See into the future',
  seeintothefutureinfo:
    'Use Investall to forecast any stocks or crypto with multiple timeframes. Powered by our sophisticated AI/ML algo, each forecast has risks and accuracy statistics to aid you in your trading.',
  pricetrendforecaster: 'Price Trend Forecaster',
  tradewithzeroemotion: 'Trade with zero emotion',
  tradewithzeroemotioninfo:
    'Harness the power of AI by using our automated tools to help take care of your trading and investing needs. Potentially avoid market disasters and use hedge fund strategies. Select your own stocks or crypto with AI Trader or choose Investall Robo to apply an expert strategy. Additionally set goals and use Investall to reach financial freedom.',
  automatedaiinvestor: 'Automated AI Investor',
  aggregateallyouraccounts: 'Aggregate all your accounts',
  aggregateallyouraccountsinfo:
    'Start by linking all your accounts and get an integrated glance into your finances. Import from other brokerages, bank accounts, and credit cards with a simple click. Use our forecasting to estimate your property value and track all your expenses in one place.',
  seeinvestallshistoricalmarketforecast:
    "See Investall's Historical market forecast",
  investallshistoricalmarketforecast: "Investall's Historical market forecast",
  signupsuccessfulyouwillberedirectshortly:
    'Sign up successful. You will be redirected shortly',
  'SHORT TERM TARGET': 'SHORT TERM TARGET',
  'MEDIUM TERM TARGET': 'MEDIUM TERM TARGET',
  'LONG TERM TARGET': 'LONG TERM TARGET',
  addacryptotoaitrader: 'Add a crypto to AI trader',
  pleaseselectabankaccount: 'Please select a bank account',
  pleaseselectavalidstock: 'Please select a valid stock',
  cannotselectmorethan4stocks: 'Cannot select more than 4 stocks',
  yes: 'Yes',
  no: 'No',
  goalplanningwilluseyourentireaccountvalue:
    'Goal Planning will use your entire account value',
  investmentgoal: 'Investment Goal',
  yearstoaccumulate: 'Years to accumulate',
  investmentgoalamountmustbegreaterthaninitialinvestment:
    'Investment goal must be greater than initial investment',
  investmentforyourgoal: 'Investment for your Goal',

  availablebalance: 'Available Balance',
  rateofreturnofinvestment: 'Rate of Return of Investment (Based on Your Plan)',
  chooseabankaccountforperiodiccontribution:
    'Choose a bank account for periodic contribution',
  goalpreview: 'Goal Preview',
  startgoal: 'Start Goal',
  activategoals: 'Activate Goals',
  goalshasbeensuccessfullyactivated: 'Goals has been successfully activated',
  shorttermgoallabel: 'Short-Term Goals 1 to 3 Years',
  shorttermgoalexample: 'Example: Build up emergency savings',
  shorttermgoaldescription:
    'SMART Goal: Save $200 per month for next 12 months',
  mediumtermgoallabel: 'Medium-Term Goals 3 to 5 Years',
  mediumtermgoalexample: 'Example: Buy a house',
  mediumtermgoaldescription:
    'SMART Goal: Save $10,000 over the next three years for a house down payment',
  longtermgoallabel: 'Long-Term Goals 5 to 10 Years',
  longtermgoalexample: 'Example: Save for retirement',
  longtermgoaldescription:
    'SMART Goal: Invest at least $5,000 per year in my retirement funds over the next five years',
  areyousureyouwanttocancelrecurringbuyof:
    'Are you sure you want to cancel recurring buy of',
  goodfaithviolation: 'Good Faith Violation',
  pleaseupgradetoinvestallprotoaccessthisproduct:
    'Please upgrade to Investall Pro to access this product',
  upgradetoinvestallpro: 'Upgrade to Investall Pro',
  frequentlyaskedquestions: 'Frequently asked questions',
  internationalwiredepositinstructions:
    'International Wire Deposit Instructions',
  passwordmessage:
    'Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character.',
  passwordmust: 'Password must',
  beatleast8characters: 'be at least 8 characters',
  haveatleastoneuppercase: 'have at least one uppercase',
  haveatleastonelowercase: 'have at least one lowercase',
  haveatleastonenumber: 'have at least one number',
  haveatleastonespecialcharacter:
    'have at least one special character (-!$%^&*()_+|~=`{}[]:/;<>?,.@#\'")',
  pleaseentervalidpassword: 'Please enter a valid password',
  notavailableinchina: 'not available in China',
  addtowatchlist: 'Add to watchlist',
  removefromwatchlist: 'Remove from watchlist',
  tradingandinvesting: 'Trading/Investing',
  investallprosubscription: 'Investall Pro Subscription',

  keystats: 'Key Stats',
  ratiosprofitability: 'Ratios/Profitability',
  peratiottm: 'P/E (TTM)',
  netmarginttm: 'Net Margin (TTM)',
  pricesalesttm: 'Price/Sales (TTM)',
  enterprisevaluettm: 'Enterprise Value/EBITDA',
  operatingmarginttm: 'Operating Margin (TTM)',
  returnonequityttm: 'Return on Equity (TTM)',
  events: 'Events',
  description: 'description',
  news: 'News',
  daysrange: 'Day Range',
  lowhigh: 'Low-High',
  yearrange: '52 Week Range',
  pleaseselectatleastonegroup: 'Please select at least one group',
  addedsuccessfully: 'Added successfully',
  createwatchlist: 'Create Watchlist',
  nameofwatchlist: 'Name of watchlist',
  searchbysymbol: 'Search by symbol',
  entername: 'Enter name',
  entersymbol: 'Enter Symbol',
  watchlistcreatesuccessfully: 'Watchlist created successfully',
  watchlistwiththisnamealreadyexists: 'Watchlist with this name already exists',
  pleaseselectatleastonesymbol: 'Please select at least one symbol',
  pleaseenternameofwatchlist: 'Please enter name of this watchlist',
  settledcash: 'Settled Cash',
  cashavailableforwithdraw: 'Cash available for withdraw',
  createnewwatchlist: 'Create new watchlist',
  add: 'Add',
  towatchlist: 'to watchlist',
  your: 'Your',
  accounthasbeenunlinked: 'account has been unlinked',
  relinktocontinue: 'Relink to continue',
  alreadyhaveanaccount: 'Already have an account? ',
  continuewithmyasaaccount: 'Continue with my ASA account',
  or: 'or',
  someofyouraccountshavebeenunlinked:
    'Some of your accounts have been unlinked.',
  oneofyouraccounthasbeenunlinked: 'One of your accounts have been unlinked.',
  pleaseenteramountgreaterthan1: 'Please enter amount greater than 1',
  pleaseentergreatersharesamount: 'Please enter greater shares amount',
  cryptomaintenance:
    'You cannot trade cryptos between 5.30 PM to 6.05 PM EST due to schedule maintenance',
  unsettledcashavailableby: 'Unsettled cash available by',
  downloadapkmessage: '',
  rsi: 'RSI',
  technicalanalysis: 'Technical Analysis',
  technicallevels: 'Technical Levels',
  standard: 'Standard',
  fibonacci: 'Fibonacci',

  getexpertstrategies: 'Get Expert Strategies',
  meetallgoals: 'Meet all Goals',
  automateyourtrades: 'Automate your Trades',
  substitute: 'Substitute',
  suggestedcomparables: 'Suggested Comparables',
  stockreplacedsuccessfully: 'Stock replaced successfully',

  //BUDGETING
  activity: 'Activity',
  view_trends: 'View Trends',
  insights: 'Insights',
  monthly_average_expenditure: 'Monthly Average Expenditure',
  income: 'Income',
  expenses: 'Expenses',
  ne_expense: 'Non-Essential Expenses',
  emergency_funds: 'Emergency Funds',
  available_funds: 'Available Funds',
  based_on_an_emergency_fund_of: 'Based on an emergency fund of',
  one_week: '1W',
  one_month: '1M',
  three_month: '3M',
  six_month: '6M',
  total_income: 'Total Income',
  total_expense: 'Total Expense',
  total: 'Total',
  income_n_expense: 'Income and Expenses',
  bank_fees: 'Bank Fees',
  entertainment: 'Entertainment',
  food_and_drink: 'Food and Drink',
  general_merchandise: 'General Merchandise',
  general_services: 'General Services',
  government_and_non_profit: 'Government and Non-profit',
  home_improvement: 'Home Improvement',
  loan_payments: 'Loan payments',
  medical: 'Medical',
  personal_care: 'Personal Care',
  rent_and_utilities: 'Rent and Utilities',
  transportation: 'Transportation',
  travel: 'Travel',
  transfer_in: 'Transfer In',
  transfer_out: 'Transfer Out',
  you_are_spending_more_this_month_than_you_do_on_average:
    'You are spending more this month than you do on average',
  you_are_spending_less_this_month_than_you_do_on_average:
    'You are spending less this month than you do on average',
  essential: 'Essential',
  non_essential: 'Non-Essential',
  get_your_investall_credit_score: 'Get your Investall credit score',
  add_credit_score: 'Add credit score',
  credit_score_disclaimer: 'Investall Credit Score',
  info_credit_score:
    'The Credit Score is calculated from information about all your linked accounts, on a scale of 300 to 850. The reason why your Investall Credit Score is different from your other credit scores is that this score is aggregated using an advanced AI algorithm that takes all of your personal information into account comprehensively',
  add_an_account: 'Add an account',
  link_bank_account: 'Link bank account',
  link_brokerage_account: 'Link brokerage account',
  link_asset_or_property: 'Link asset or property',
  add_an_asset: 'Add an asset',
  add_a_property: 'Add a property',
  add_a_vehicle: 'Add a vehicle',
  add_other_valuable: 'Add other valuable',
  find_your_homes_estimated_value: "Find your home's estimated value",
  enter_full_address: 'Enter full address',
  enter_home_estimated_value: "Enter home's estimated value",
  enter_home_estimate: "Enter home's estimate",
  enter_your_mortgage_balance: 'Enter your mortgage balance',
  enter_mortgage_balance: 'Enter mortgage balance',
  give_your_property_a_nickname: 'Give your property a nickname',
  enter_nickname: 'Enter nickname',
  add_property: 'Add property',
  enter_your_vehicle_details: 'Enter your vehicle details',
  vehicle_model: 'Vehicle model',
  enter_vehicle_model: 'Enter vehicle model',
  vehicle_make: 'Vehicle make',
  enter_vehicle_make: 'Enter vehicle make',
  vehicle_year: 'Vehicle year',
  enter_vehicle_year: 'Enter vehicle year',
  estimated_value: 'Estimated value',
  enter_vehicle_estimate: 'Enter vehicle estimate',
  loan_balance: 'Loan balance',
  enter_vehicle_loan: 'Enter vehicle loan',
  vehicle_nickname: 'Vehicle nickname',
  enter_vehicle_nickname: 'Enter vehicle nickname',
  add_vehicle: 'Add vehicle',
  enter_your_valuable_details: 'Enter your valuable details',
  name: 'Name',
  ex_art_jewelery: 'Ex: Art, Jewelry, etc',
  add_valuable: 'Add valuable',
  net_worth: 'Net worth',
  physical_assets: 'Physical assets',
  liabilities: 'Liabilities',
  credit_cards: 'Credit cards',
  loans: 'Loans',
  all_budgeting_notifications: 'All budgeting notifications',
  credt: 'Credt',
  based_on_investall_credit_rating: 'Based on Investall credit rating',
  credit_analysis: 'Credit analysis',
  credit_details_message:
    'Your credit score depends on your financial health. Check the stats below to see your performance',
  credit_sore_added_successfully: 'Credit score added successfully',
  select_the_highest_education_degree_you_have:
    'Select the highest education degree you have',
  select_education: 'Select education',
  high_school: 'High School',
  bachelor: 'Bachelor',
  master: 'Master',
  phd: 'PhD',
  others: 'Others',
  when_was_your_first_line_of_credit_opened:
    'When was your first line of credit opened?',
  when_did_you_start_your_current_job: 'When did you start your current job?',
  start_year: 'Start Year',
  what_is_the_first_year_of_your_first_employment:
    'What is the first year of your first employment?',
  employment_year: 'Employment year',
  select_the_option_that_applies_to_you:
    'Select the option that applies to you',
  option: 'Option',
  renting_a_home: 'Renting a home',
  own_a_mortgaged: 'Own a mortgaged',
  own_a_home: 'Own a home',
  next: 'Next',
  account_number: 'Account number',
  balance: 'Balance',
  address: 'Address',
  mortgage_balance: 'Mortgage balance',
  external_accounts: 'External accounts',
  depository: 'Depository',
  brokerages: 'Brokerages',
  properties: 'Properties',
  vehicles: 'Vehicles',
  valuables: 'Valuables',
  transactions: 'Transactions',
  credit: 'Credit',
  add_account: 'Add account',

  //NEW BUDGETING
  no_transactions: 'No transactions',
  please_subscribe_to_access_this_feature:
    'Please subscribe to access this feature',
  link_digital_wallet: 'Link digital wallet',
  enter_wallet_address: 'Enter wallet address',
  add_wallet: 'Add wallet',
  successfully_added_account: 'Successfully added account',
  edit_asset: 'Edit Asset',
  six_mo_expenses: '(6 mo. expenses).',
  you_have_recommended_cash_reserve_for_emergency:
    'You have recommended cash reserve for emergency',
  you_are_below_recommended_cash_reserved_for_emergencies:
    'You are below recommended cash reserved for emergencies. Use our budgeting to help you save.',
  remove_account: 'Remove Account',
  account_removed_successfully: 'Account removed successfully',
  remove_account_msg:
    'This will remove all other sub accounts associated with this account. Do you want to remove this account?',
  remove_asset_msg:
    'This will remove all other information associated with this asset. Do you want to remove this asset?',
  last_month_expenditure: 'Last Month Expenditure',
  fund_account: 'Fund account',

  //SUB MODAL
  investall_pro: 'Investall Pro',
  sub_message:
    'Upgrade to get access to premium tools and features. Start a free trial for 30 days, and pay $3.99 per month thereafter.',
  budgeting_subs_info:
    'Aggreagate all your accounts in one place to get your net worth. Estimate your property value and more.',
  ai_trader_stock_n_crypto: 'AI Trader Stock & Crypto',
  ai_trader_stock_n_crypto_sub_info:
    'Select up to four stocks or cryptocurrencies to automatically trade using advanced algos with ML forecasting.',
  goals_sub_info:
    'Track your spending, and see where you can save more. Our trends and insights provide intutive charts and alerts to keep you aware of your cash flow.',
  free_trial_msg:
    'One month free trial will begin immediately after you subscribe. You will be charged $3.99 after 30 days for the following month.',
  congratulations: 'Congratulations',
  activity_trends: 'Activity & Trends',
  activity_trends_info:
    'Track your spending, and see where you can save more. Our trends and insights provide intutive charts and alerts to keep you aware of your cash flow.',
  manage_subscription: 'Manage Subscription',
  you_have_successfully_unsubscribed: 'You have successfully unsubscribed',
  successfully_unsubscribed: 'Successfully unsubscribed',

  //NEW ACCOUNT CARD
  self_trading_accounts: 'Self Trading Accounts',
  automated_products: 'Automated Products',
  explore: 'Explore',

  successfully_added_wallet: 'Successfully added wallet',
  remove_asset: 'Remove Asset',
  digital_wallets: 'Digital Wallets',
  this_month: 'This month',
  average: 'Average',
  below_averge: 'Below Average',
  good: 'Good',
  excellent: 'Excellent',
  download_app_text:
    'Get Investall app for your mobile device from below. Also available as APK download for international users.',

  you_have_spent_less_last_month:
    'You have spent less last month than you usually spend on average in 6 months',
  you_have_spent_more_last_month:
    'You have spent more last month than you usually spend on average in 6 months',
  you_have_spent_the_same_amount:
    'You have spent the same amount last month as your 6 month average',
  'Net Worth': 'Net Worth',
  'Liquidity Ratio': 'Liquidity Ratio',
  'Solvency Ratio': 'Solvency Ratio',
  'Debt/Income Ratio': 'Debt/Income Ratio',
  'Debt/Asset Ratio': 'Debt/Asset Ratio',
  'Non-Essential Expense Ratio': 'Non-Essential Expense Ratio',
  'Essential Expense Ratio': 'Essential Expense Ratio',
  'Emergency Reserve Required': 'Emergency Reserve Required',
  'Saving Ratio': 'Saving Ratio',
  'Mortgage Ratio': 'Mortgage Ratio',
  'Life Insurance Ratio': 'Life Insurance Ratio',
  'Retirement Saving Ratio': 'Retirement Saving Ratio',
  'Investing Ratio': 'Investing Ratio',
  this_wallet_does_not_own_any_nfts: 'This wallet does not own any NFTs',
  powered_by_honely: 'Powered by Honely',
  get_personalized_dashboard_with_your_full_financial_picture:
    'Get a personalized dashboard with your full financial picture.',
  connect_your_accounts_to_investall_msg:
    'Connecting your accounts to Investall allows you to see a complete view of your portfolio in an intuitive interface so you can make better decisions.  See notifications about important events and access automated trading tools.',
  get_insights_on_all_your_accounts: 'Get insights on all your accounts',
  our_ai_aggregates_message:
    'Our AI aggregates the current value of your net worth across all your assets and liabilities. Easily connect a home property and use our AI to determine current value.',
  my_assets_and_income_managed_regularly:
    'My assets and income managed regularly',
  check_the_current_msg:
    'Check the current price estimates of your home and other assets using our embedded forecasting AI. Check your income activity with trends and insights.',
  know_your_net_worth: 'Know your net worth',
  our_ai_aggregates_the_current_value_msg:
    'Our AI aggregates the current value of your net worth across all your assets and liabilities. Keeping tabs has never been easier with smart alerts and the activity panel. Easily connect a home property and use our AI to determine current value.',
  buy_fractional_shares_msg:
    'Buy fractional shares with as little as $1 and zero commission',
  automate_trading_with: 'Automate trading with',
  upgraded_tools: 'Upgrade Tools',
  trades_are_automatically_msg:
    'Trades are automatically placed based on momentum shifts - with a combination of multiple factors. Select your own choice of stocks or cryptocurrencies.',
  automate_your_trading_msg:
    'Automate your trading with up to four stocks or cryptocurrencies.',
  its_important_to_set_goals_msg:
    'It’s important to set goals for your financial future. Whether it’s for your next mortgage or a family vacation. Define your goals, visualize and reach for your target in short, medium or long term time frames.',
  free: 'Free',
  get_robo_for_automated_trading_msg:
    'Get Robo for automated trading, see all our Forecast data, Forecast Screener and use Self Trading of Stocks and Crypto with as little as $1 and zero commission.',
  download: 'Download',
  forecasting: 'Forecasting',
  forecast_screener: 'Forecast Screener',
  fractional_trading: 'Fractional trading',
  all_free_features: 'All free features',
  upgrade: 'Upgrade',
  per_month: 'per month',
  everything_from_free_version_msg:
    'Everything from free version with upgraded tools including AI Trader Stocks & Crypto, Goals and the comprehensive Budgeting dashboard.',
  initial_amount: 'Initial Amount',
  contribution_amount: 'Monthly Contribution Amount',
  investment_period_30_years: 'Investment period: 5 years',
  trusted_by_institutional_traders: 'Used by Institutions',
  audited_track_record_msg:
    'Democratizing RIA strategies for consumers. Simply fill out a questionaire and Investall will determine a strategy that is right for you. ',
  other_top_robo_advisors_msg:
    'Other top robo advisors, now available to the masses. ',
  tailored_to_your_choices: 'Tailored to your choices',
  complete_an_investment_msg:
    'Complete an investment questionnaire and our AI picks the best strategy for your account. Monthly rebalancing based on the current market.',
  highly_liquid: 'Highly Liquid',
  keep_full_custody_msg:
    'Keep full custody of your investment account. Change your mind with ease, get your money when you need it. ',
  investall_robo: 'Investall Robo',
  financial: 'Financial',
  revenue: 'Revenue',
  eps: 'Eps',
  ebitda: 'Ebitda',
  quarter: 'Quarter',
  financial_forecast: 'Financial forecast',
  price_forecast: 'Price forecast',
  promo_applied_successfully: 'Promo applied successfully',
  user_is_an_already_existing_user:
    'User is an already existing user or has already applied this promotion',
  invalid_promo_code: 'Invalid promo code',
  enter_promo_code: 'Enter promo code',
  please_enter_promo_code: 'Please enter promo code',
  my_code: 'My code',
  promo_code: 'Promo code',
  rewards: 'Rewards',
  promotions: 'Promotions',
  no_rewards_available: 'No rewards available',
  you_have_been_subscribed_to_a_new_reward:
    'You have been subscribed to a new reward',
  eligibility: 'Eligibility',
  eligibility_not_met: 'Eligibility not met',
  success: 'Success',
  requirements_not_met: 'Requirements not met',
  reward_amount: 'Reward amount',
  must_be_a_new_user: 'Must be a new user',
  deposit_minimum_100_dollars: 'Deposit a minimum of $100 dollars',
  welcome_to_investall: 'Welcome to Investall',
  get_20_free_with_and_initial_deposit_of_100:
    'Get $20 free with and initial deposit of $100',
  download_the_app: 'Download App',
  open_an_account: 'Open an account',
  make_an_minimum_initial_deposit_of_100:
    'Make a minimum initial deposit of $100',
  use_faares_code: 'The following code is automatically applied: FAARES',
  faares_has_invited_you_to: 'FAARES has invited you to',
  why_investall: 'Why Investall',
  trade_stocks_etfs_and_crypto_msg:
    'Trade stocks, ETFs and Crypto currencies with as little as $1 and 0 commission.',
  use_expert_strategyies_msg:
    'Use expert strategies for automated investing. Get Investasll Robo when you sign up, and upgrade to use AI Trader and Goals.',
  accessible_on_multi_platform_msg:
    'Accessible on multi-platforms including iOS, Android, APK and Web APP.',
  complimentary_upgrade_to: 'Complimentary upgrade to',
  receive_one_month_subscription_msg:
    'Receive 1-month subsctiption to Investall Pro from the day you sign up with us!',
  forecast_achieved: 'Forecast Achieved',
  forecasted_date: 'Forecasted date',
  forecasted_price: 'Forecasted price',
  quarterly: 'Quarterly',
  yearly: 'Yearly',
  extended_hours_trading: 'Extended Hours Trading',
  extended_trading_description:
    'Extended Hours Trading offering has two components: the Pre Market Session (04:00AM - 9.30AM ET) and After Market Session (04:00PM - 8.00PM ET)',
  enable_extended_trading_description:
    'Would you like to enable Extended Hours of Trading? Extended Hours Trading offering has two components: the Pre Market Session (04:00AM - 9.30AM ET) and After Market Session (04:00PM - 8.00PM ET)',
  disable_extended_trading_description:
    'Would you like to disable Extended Hours of Trading? Extended Hours Trading offering has two components: the Pre Market Session (04:00AM - 9.30AM ET) and After Market Session (04:00PM - 8.00PM ET)',
  i_agree_to_the: 'I Agree to the',
  drivewealth_extended_hours_trading_disclosures:
    'DriveWealth Extended Hours Trading Disclosures',
  enable: 'Enable',
  disable_for_now: 'Disable for now',
  extended_hours_trading_for_stock: 'Extended Hours Trading for Stock Account',
  extended_hours_popup_prompt:
    'Pre and Post Market trading is now available! Click below to enable in one one simple step',
  use_extended_hours: 'Use Extended Hours',
  limit_orders_are_good_until:
    'Limit orders are good until the end of the extended hours session',
  subscription_disclaimer:
    'PLEASE READ THIS DISCLOSURE CAREFULLY. BY DOWNLOADING OR USING OUR APP, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH BELOW. The free version of our app is intended for use by US residents only. If you are an international user with an account balance of more than $50, you will be charged a platform fee of $3.99. This fee will be automatically charged to your account balance. We reserve the right to modify this disclosure at any time, and without prior notice. Such modifications shall be effective immediately upon posting in the app. You agree to review this disclosure periodically to be aware of any such modifications. Your continued use of our app shall be deemed your acceptance of the modified disclosure. If you have any questions about this disclosure, please contact us.',
  allaccounts: 'All Accounts',
  forecastmargin: 'Forecast Margin',
  prediction: 'Prediction',
  actual: 'Actual',

  // My Finances
  my_finances: 'My Finances',
  financial_yoga: 'Financial Yoga',
  financial_yoga_summary:
    'Strengthen your financial mindset and train our A.I. to serve your better.',
  you_saved: 'You saved',
  dynamic_spending: 'Dynamic Spending',
  dynamic_spending_explanation:
    'Framing spending decisions as trade-offs between two categories that you want, instead of focusing on “sticking to a budget.”',
  view_insights: 'View Insights',
  available_to_spend: 'Available to spend',
  income_reserves: 'Income Reserves',
  available_reserves: 'Available reserves',
  income_reserves_explanation:
    'Based on an emergency fund of {{fund}} ({{months}} mo. expenses). You are below the recommended cash reserved for emergencies. Use our budgeting to help you save.',
  wealth_fund: 'Wealth Fund',
  available_to_invest: 'Available to invest',
  charitable_giving: 'Charitable giving',
  available_to_give: 'Available to give',
  wealth_fund_subtext:
    'Use available funds to start building wealth and pay off high interest debt.',
  contribution_goal: 'Contribution Goal',
  last_month: 'Last Month',
  six_month_avg: '6 month avg',
  charitable_giving_subtext:
    'This is your allocated funds for charitable giving. You can set how much you want to allocate from each month’s income.',
  income_reserves_subtext:
    'This includes all available cash from your linked accounts minus any amount currently in your dynamic spending categories.',
  allocation_goal: 'Allocation goal',
  monthly_highlights: 'Monthly Highlights',
  cash_flow_for_s: 'Cash flow for {{date}}',
  previous_highlights: 'Previous Highlights',
  spend_goal: 'Spend goal',
  hi_s_lets_begin:
    'Hi {{name}}. Let’s begin by reviewing your income and expenses.',
  monthly_average: 'Monthly Average',
  mtd_monthly_average: 'MTD / Monthly Average',
  this_looks_correct: 'This looks correct',
  something_is_not_right: 'Something is not right',
  oppurtunity_cost_subtext:
    'Training your brain to think about “opportunity cost” helps you spend smarter naturally and it leads to better life outcomes.',
  s_recap: '{{date}} recap',
  connected_accounts: 'Connected Accounts',
  opportunity_cost: 'Opportunity Cost',
  ok_let_s_figure_out_why_things_are_off:
    'Ok, let’s figure out why things are off.',
  you_may_be_missing_income_and_expense_transactions:
    'You may be missing income and expense transactions.',
  link_additional_accounts: 'Link additional accounts',
  some_transactions_may_be_going_to_the_wrong_category:
    'Some transactions may be going to the wrong category.',
  review_transactions: 'Review transactions',
  transaction_review: 'Transaction Review',
  review: 'Review',
  were_these_ten_transactions_categorized_correctly:
    'Were these ten transactions categorized correctly?',
  all_good: 'All good',
  linked_accounts_subtext: 'We’ve successfully linked the following accounts.',
  link_more_accounts: 'Link more accounts',
  select_category: 'Select Category',
  skip_all_transactions: 'Skip all transactions',
  previous: 'Previous',
  finish: 'Finish',
  steal_from: 'Steal From',
  oppurtunity_cost_complete_subtext1:
    'You’re strenghtening your financial mind and helping our A.I. better understand your preferences to serve you better moving forward.',
  review_opportunity_cost_1:
    "Let's review opportunity costs you've had recently. You can edit the category if it is wrong.",
  review_opportunity_cost_2:
    'Your opportunity cost is the categories you stole money from to complete the transaction.',
  complete: 'Complete',
  change_category: 'Change Category',
  current_category: 'Current Category',
  new_category: 'New Category',
  transaction_correction_subtext:
    'Ok, not to worry. Sometimes a transfer can be hard to identify without your help.',
  transaction_correction_subtext_2:
    'You can select the correct category by clicking on the drop down menu.',
  category_change_suggestions_subtext: 'Great job!',
  category_change_suggestions_subtext_2:
    'We’ve identified a few more lookalike transactions from your past.',
  change_selected_to_s: 'Change selected to {{category}}',
  search_in_transaction: 'Search in transaction',
  mtd_spend: 'MTD spend',
  last_month_spend: 'Last month spend',
  six_month_avg_spend: '6 month avg spend',
  take_from: 'Take from',
  taken_by_other_categories: 'Taken by other categories',
  categories: 'Categories',
  amounts: 'Amounts',
  dates: 'Dates',
  update: 'Update',
  additionalinformationrequired: 'Additional Information Required',
  uploadadditionaldocumentsmsg:
    'Please upload additional documents to verify your identity or proof of address.',
  uploaddocument: 'Upload Document',
  documentsuploaded: 'Documents Uploaded',
  documentsuploadedmsg:
    'Your documents have been successfully uploaded. Your account status will be updated shortly',
  gotodashboard: 'Go to Dashboard',
}
