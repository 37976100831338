import React, { Component } from 'react'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import ENDPOINT from '../Endpoint'
import '../../scss/components/BuyOrSellForm.scss'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import SearchEndpoint from '../SearchEndpoint'
import { ConfirmDialog } from 'primereact/confirmdialog'
import RecurringBuyDialog from './RecurringBuyDialog'
import { connect } from 'react-redux'
import Endpoint from '../Endpoint'
import Axios from 'axios'
import moment from 'moment'
import { fetchAccountInfoLimited } from '../../services/userServices'
import { Checkbox } from 'primereact/checkbox'
import Loader from './accountInfo/Loader'
import { Dialog } from 'primereact/dialog'

class BuyOrSellForm extends Component {
  state = {
    activeTab: this.props?.activeTab ? this.props?.activeTab : 'Buy',
    orderType: this.props?.orderType
      ? this.props?.orderType.toLowerCase()
      : this.props?.isDwSupported && !this.props.canTrade?.status
      ? 'limit'
      : 'market',
    investmentType: this.props?.investMode
      ? this.props?.investMode.toLowerCase()
      : this.props?.isDwSupported && !this.props.canTrade?.status
      ? 'shares'
      : 'dollars',
    limitPrice: this.props?.limitedPrice ? this.props?.limitedPrice : null,
    amount:
      this.props?.investMode === 'dollars' ? this.props?.AmountTotal : null,
    shares: this.props?.investMode === 'shares' ? this.props?.AmountTotal : 0,
    isLoading: true,
    errorMessage: '',
    selected: this.props.selected,
    sharesOwned: 0,
    showSummary: false,
    pendingAmount: 0,
    showConfirmationDialog: false,
    gfvMessage: '',
    showRecurringBuyDialog: false,
    accountTransactionsData: [],
    recurBuyStatus: null,
    showDetails: false,
    detailsInfo: {},
    safeCash: 0,
    useExtendedHours: this.props.dwExtendedHours,
    usingAlpaca: false,
    formLoading: false,
    showPDTWarning: false,
    showPDTError: false,
  }

  componentDidMount() {
    //TRY TO GET ACCOUNTS FROM PREVIOUS PAGES INSTEAD OF LOADING IT HERE
    if (this.props.allAccounts) this.filterAccounts()
    this.getLivePrice()
    // this.getDetailsInfo()
    // if (this.state.orderType === 'limit' && this.props.AmountTotal % 1 !== 0) {
    //   this.setState({ orderType: 'market' })
    //   this.handleInvestmentTypeChange('shares')
    // }
    if (this.props.usingPrefill) {
      let newState = { shares: this.props.AmountTotal }
      if (
        this.state.orderType === 'limit' &&
        this.props.AmountTotal % 1 !== 0
      ) {
        console.log('HAVE TO CHANGE TO MARKET')
        // newState.orderType = 'market'

        // newState.amount = null

        this.setState({ orderType: 'market' })
        // this.handleInvestmentTypeChange('shares')
        this.toggleErrorMessage(
          'Only market orders are supported with fractional shares'
        )
      }
      this.props.disablePrefillData()
      console.log('newState', newState)
      this.setState({
        // ...this.state,
        newState,
      })
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps !== this.props) {
      // console.log('breakpoint 2 setting')

      if (this.props.usingPrefill) {
        // this.setState({ formLoading: true })

        // setTimeout(() => {
        //   this.setState({ formLoading: false })
        // }, 5000)
        this.props?.refreshPrices()
        if (
          this.state.orderType === 'limit' &&
          // this.state.AmountTotal % 1 !== 0
          this.props.AmountTotal % 1 !== 0
        ) {
          // this.setState({ formLoading: true })

          // setTimeout(() => {
          //   this.setState({ formLoading: false })
          // }, 2000)

          this.setState({
            orderType: 'market',
            shares: this.props.AmountTotal,
          })

          console.log('NEED TO CHANGE')

          // this.handleInvestmentTypeChange('shares')
          this.props.disablePrefillData()
          this.toggleErrorMessage(
            'Only market orders are supported with fractional shares'
          )
        } else {
          this.setState(
            {
              activeTab: this.props?.activeTab ? this.props?.activeTab : 'Buy',
              orderType: this.props?.orderType
                ? this.props?.orderType.toLowerCase()
                : this.props?.isDwSupported && !this.props.canTrade?.status
                ? 'limit'
                : 'market',
              investmentType: this.props?.investMode
                ? this.props?.investMode.toLowerCase()
                : this.props?.isDwSupported && !this.props.canTrade?.status
                ? 'shares'
                : 'dollars',
              limitPrice: this.props?.limitedPrice
                ? this.props?.limitedPrice
                : null,
              amount:
                this.props?.investMode === 'dollars'
                  ? this.props?.AmountTotal
                  : null,
              shares:
                this.props?.investMode === 'shares'
                  ? this.props?.AmountTotal
                  : 0,
            }
            // console.log('breakpoint 3', this.state.limitPrice)
          )
        }
      }
    }

    // console.log(this.state.investmentType)
    if (prevProps.selected?.company !== this.props.selected?.company) {
      this.setState(
        {
          activeTab: 'Buy',
          orderType:
            this.props?.isDwSupported && !this.props.canTrade?.status
              ? 'limit'
              : 'market',
          investmentType:
            this.props?.isDwSupported && !this.props.canTrade?.status
              ? 'shares'
              : 'dollars',
          limitPrice: 0,
          amount: null,
          shares: 0,
          isLoading: true,
          errorMessage: '',
          selected: this.props.selected,
          sharesOwned: 0,
          showSummary: false,
          pendingAmount: null,
          showConfirmationDialog: false,
          gfvMessage: '',
          safeCash: 0,
        },
        () => {
          if (this.props.user) this.filterAccounts()
          this.getLivePrice()
          // this.getDetailsInfo()
        }
      )
    }
    if (prevProps.allAccounts !== this.props.allAccounts) {
      if (this.props.user) this.filterAccounts()
      this.getLivePrice()
      // this.getDetailsInfo()
    }
  }

  componentWillUnmount() {
    clearInterval(this.priceInterval)
    clearInterval(this.orderStatusInterval)
  }

  // getSafeCash = async () => {
  //   const res = await fetchAccountInfoLimited(
  //     this.props.user.idToken.jwtToken,
  //     this.state.account?.accountId
  //   )
  //   console.log('only info 1', res)
  //   this.setState({
  //     safeCash: res?.payload?.accountInfo?.balances[0]?.safeCash,
  //   })
  // }

  getAccountTransactionsData = () => {
    fetch(
      `${ENDPOINT}/mobile/user/account-info?account_id=${this.state.account?.accountId}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
      }
    )
      .then((response) => response.json())
      .then((resp) => {
        let recurBuyStatus = null
        resp.payload.recurringBuys.forEach((item) => {
          if (item.ticker === this.props.symbol) {
            recurBuyStatus = item
          }
        })
        this.setState({
          accountTransactionsData: resp.payload,
          recurBuyStatus: recurBuyStatus,
          safeCash: resp?.payload?.accountInfo?.balances[0]?.safeCash,
        })
      })
      .catch((err) => {
        console.log('get instance data error message: ', err)
      })
  }

  completeResetForm = () => {
    this.setState({
      activeTab: 'Buy',
      orderType:
        this.props?.isDwSupported && !this.props.canTrade?.status
          ? 'limit'
          : 'market',
      investmentType:
        this.props?.isDwSupported && !this.props.canTrade?.status
          ? 'shares'
          : 'dollars',
      limitPrice: 0,
      amount: null,
      shares: 0,
      errorMessage: '',
      showSummary: false,
      isLoading: false,
      showConfirmationDialog: false,
      gfvMessage: '',
    })
  }

  getOrders = () => {
    fetch(
      `${ENDPOINT}/mobile/order-history?account_id=${this.state.account?.accountId}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
      }
    )
      .catch((err) => {
        console.log(err)
      })
      .then((response) => response.json())
      .then((resp) => {
        let pendingAmount = 0
        if (resp && resp.pendingOrders) {
          resp.pendingOrders.forEach((order) => {
            if (
              order.ticker === this.props.symbol &&
              (order.type === 'LIMIT SELL' || order.type === 'MARKET SELL')
            ) {
              pendingAmount += order.qty
            }
          })
        }
        this.setState({
          sharesOwned: this.state.sharesOwned - pendingAmount,
          pendingAmount: pendingAmount,
        })
      })
  }

  closeAndTradeStockDW = () => {
    this.tradeStockDW()
    return true
  }

  tradeStockDW = () => {
    /// HERE
    this.setState({
      isLoading: true,
      errorMessage: '',
    })
    let body = {
      dwAccountID: this.state.account?.accountId,
      orderType: this.state.orderType?.toUpperCase(),
      symbol: this.state.selected?.live?.symbol,
      side: this.state.activeTab?.toUpperCase(),
    }
    if (this.state.investmentType === 'shares') {
      body['quantity'] = this.state.shares
    } else {
      body['amount'] = this.state.amount
    }
    if (this.state.orderType === 'limit') {
      body['orderPrice'] = parseFloat(this.state.limitPrice)
      // body['quantity'] = this.state.shares
    }
    // if (this.state.lastChanged === "quantity") {
    //   body["quantity"] = this.state.quantity;
    // } else {
    //   body["amount"] = this.state.price;
    // }
    // if (this.state.orderType === "LIMIT") {
    //   body["orderPrice"] = parseFloat(this.state.limitPrice);
    // }

    let url =
      this.state.useExtendedHours && this.state.orderType === 'limit'
        ? `${ENDPOINT}/mobile/dw/trades/submit?extend=1`
        : `${ENDPOINT}/mobile/dw/trades/submit`

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
      },
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .catch((err) => {
        this.resetAndShowErrorMessage()
      })
      .then((responseJson) => {
        console.log('WE MADE IT ', responseJson)
        console.log('success' in responseJson)
        console.log(responseJson.success)
        if (responseJson && 'success' in responseJson && responseJson.success) {
          console.log('passed')
          this.setState(
            {
              orderPlaced: responseJson?.payload?.message,
              orderId: responseJson?.payload?.orderID,
              orderPending: false,
            },
            () => {
              console.log('state set')
              this.checkOrderStatus()
            }
          )
          this.startTradeStatusTimer()
        } else {
          this.resetAndShowErrorMessage()
        }
      })
  }

  tradeStockAlpaca = () => {
    /// HERE
    this.setState({
      isLoading: true,
      errorMessage: '',
    })
    let body = {
      acctId: this.state.account?.accountId,
      type: this.state.orderType?.toLowerCase(),
      symbol: this.state.selected?.live?.symbol,
      // conid: this.state.selected?.live?.conid,
      side: this.state.activeTab?.toLowerCase(),
      time_in_force: 'day',
      // type: 'STK',
      // price: this.state.selected?.live?.priceUSD,
    }
    if (this.state.investmentType === 'shares') {
      body['qty'] = this.state.shares
    } else {
      body['amount'] = this.state.amount
    }
    if (this.state.orderType === 'limit') {
      body['price'] = parseFloat(this.state.limitPrice)
      // body['quantity'] = this.state.shares
    }
    // if (this.state.lastChanged === "quantity") {
    //   body["quantity"] = this.state.quantity;
    // } else {
    //   body["amount"] = this.state.price;
    // }
    // if (this.state.orderType === "LIMIT") {
    //   body["orderPrice"] = parseFloat(this.state.limitPrice);
    // }
    console.log('IB trade body', body)
    let url =
      ENDPOINT +
      '/mobile/alpaca/trades/submit?account_id=' +
      this.state.account.accountId
    // this.state.useExtendedHours && this.state.orderType === 'limit'
    //   ? `${ENDPOINT}/mobile/dw/trades/submit?extend=1`
    //   : `${ENDPOINT}/mobile/dw/trades/submit`

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
      },
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .catch((err) => {
        this.resetAndShowErrorMessage()
      })
      .then((responseJson) => {
        console.log('responseJson for IB', responseJson?.output)
        if (responseJson?.output) {
          this.setState(
            {
              orderPlaced: responseJson?.output?.order_description,
              orderId: responseJson?.output?.order_id,
              orderPending: false,
            },
            () => {
              console.log('Set Order Status')
              if (responseJson?.output?.order_type === 'limit') {
                this.resetAndShowMessage(
                  responseJson?.output.limit_price,
                  responseJson?.output.qty
                )
              } else if (responseJson?.output?.order_type === 'market') {
                if (responseJson?.output.notional) {
                  this.resetAndShowMessage(
                    responseJson?.output.notional,
                    responseJson?.output.qty,
                    responseJson?.output.filled_avg_price
                  )
                } else {
                  this.resetAndShowMessage(
                    responseJson?.output.notional,
                    responseJson?.output.qty
                  )
                }
              } else {
                this.resetAndShowErrorMessage()
              }
              // this.checkOrderStatus()
            }
          )
          // this.startTradeStatusTimer()
        } else {
          this.resetAndShowErrorMessage()
        }
      })
  }

  tradeCryptoApex = () => {
    /// HERE
    this.setState({
      isLoading: true,
    })
    let body = {
      type: this.state.orderType?.toUpperCase(),
      ticker: this.state.selected?.live?.symbol,
      side: this.state.activeTab?.toUpperCase(),
    }
    // if (this.state.lastChanged === "quantity") {
    //   body["quantity"] = this.state.quantity;
    // } else {
    //   body["amount"] = this.state.price;
    // }
    if (this.state.activeTab === 'Buy') {
      body['amount'] = this.state.amount
    } else {
      body['quantity'] = this.state.shares
    }
    fetch(
      `${ENDPOINT}/apex-cp/order?account_id=${this.state.account.accountId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
        body: JSON.stringify(body),
      }
    )
      .catch((err) => {
        this.resetAndShowErrorMessage()
      })
      .then((resp) => resp.json())
      .catch((err) => {
        this.resetAndShowErrorMessage()
      })
      .then((responseJson) => {
        if (
          responseJson &&
          responseJson.payload &&
          responseJson.payload.status &&
          responseJson.payload.status === 'COMPLETE'
        ) {
          // this.setState(
          //   {
          //     orderPlaced: responseJson.payload.status,
          //     orderId: responseJson.payload.id,
          //     orderPending: false,
          //   },
          //   () => {
          //     this.checkOrderStatus();
          //   }
          // );
          // this.startTradeStatusTimer();
          this.resetAndShowCryptoMessage(
            responseJson?.payload?.averagePrice,
            responseJson?.payload?.cumulativeQuantity,
            responseJson?.payload?.averagePrice *
              responseJson?.payload?.cumulativeQuantity
          )
        } else {
          this.resetAndShowErrorMessage()
        }
      })
  }

  toggleShowConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: !this.state.showConfirmationDialog,
    })
  }

  startTradeStatusTimer = () => {
    let counter = 0
    this.orderStatusInterval = setInterval(() => {
      if (++counter === 10) {
        clearInterval(this.orderStatusInterval)
        this.resetAndShowErrorMessage()
      }
      this.checkOrderStatus(counter)
    }, 1000)
  }

  checkOrderStatus = (counter) => {
    fetch(`${ENDPOINT}/mobile/order-status?orderID=${this.state.orderId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .catch((err) => {
        console.log('[ERROR]', err)
        this.resetAndShowErrorMessage()
      })
      .then((res) => res.json())
      .then((responseJson) => {
        console.log('check order 1', responseJson)
        if (responseJson.status === 'NEW') {
          if (this.state.orderType === 'limit') {
            clearInterval(this.orderStatusInterval)
            if (this.state.activeTab === 'Buy') {
              this.resetAndShowMessage(
                responseJson.price,
                responseJson.quantity
              )
            } else {
              this.resetAndShowMessage(
                responseJson.price,
                responseJson.quantity
              )
            }
          } else if (this.state.orderType === 'market' && counter === 9) {
            clearInterval(this.orderStatusInterval)
            this.resetAndShowMessage(
              responseJson.amountCash,
              responseJson.quantity
            )
          } else {
            if (!this.props.selected.isCrypto && !this.props.canTrade?.status) {
              clearInterval(this.orderStatusInterval)
              if (this.state.activeTab === 'Buy') {
                this.resetAndShowMessage(
                  responseJson.amountCash,
                  responseJson.totalOrderAmount,
                  responseJson.averagePrice
                )
              } else {
                this.resetAndShowMessage(
                  responseJson.totalOrderAmount,
                  responseJson.quantity,
                  responseJson.averagePrice
                )
              }
            }
          }
        } else if (responseJson.status === 'FILLED') {
          clearInterval(this.orderStatusInterval)
          if (this.state.activeTab === 'Buy') {
            if (this.state.orderType === 'dollars') {
              this.resetAndShowMessage(
                responseJson.amountCash,
                responseJson.quantity,
                responseJson.averagePrice
              )
            } else {
              this.resetAndShowMessage(
                responseJson.totalOrderAmount,
                responseJson.quantity,
                responseJson.averagePrice
              )
            }
          } else {
            this.resetAndShowMessage(
              responseJson.totalOrderAmount,
              responseJson.quantity,
              responseJson.averagePrice
            )
          }
        } else {
          clearInterval(this.orderStatusInterval)
          this.resetAndShowErrorMessage()
        }
      })
      .catch((err) => {
        console.log(`check order status error: `, err)
        clearInterval(this.orderStatusInterval)
        this.resetAndShowErrorMessage()
      })
  }

  resetAndShowMessage = (amount, shares, filledPrice) => {
    if (filledPrice) {
      this.props.toggleSuccessMessage(
        `${this.state.selected?.symbol} ${this.props.t(
          this.state.orderType.toLowerCase()
        )}  ${this.props.t(this.state.activeTab.toLowerCase())} ${this.props.t(
          'ordersuccessful'
        )}`,
        `${this.props.t('quantity')}: ${shares}`,
        `${this.props.t('filledprice')}: ${filledPrice}`,
        `${this.props.t('amount')}: ${this.props.formatCurrency(amount)}`
      )
    } else {
      this.props.toggleSuccessMessage(
        `${this.state.selected?.symbol} ${this.props.t(
          this.state.orderType.toLowerCase()
        )} ${this.props.t(this.state.activeTab.toLowerCase())} ${this.props.t(
          'orderplacedsuccessfully'
        )}`,
        `${this.props.t('quantity')}: ${shares}`,
        `${this.props.t(
          this.state.orderType === 'limit' ? 'limitprice' : 'price'
        )}:  ${this.props.formatCurrency(amount)}`
      )
    }
    this.props.getAllAccountInfo()
    this.completeResetForm()
  }

  resetAndShowCryptoMessage = (filledPrice, cumulativeQuantity, amount) => {
    this.props.toggleSuccessMessage(
      `${this.state.selected?.symbol} ${
        this.state.orderType
      } ${this.state.activeTab.toUpperCase()} ${this.props.t(
        'ordersuccessful'
      )}`,
      `${this.props.t('quantity')}: ${cumulativeQuantity}`,
      `${this.props.t('filledprice')}: ${this.props.formatCurrency(
        filledPrice
      )}`,
      `${this.props.t('amount')}: ${this.props.formatCurrency(amount)}`
    )
    this.props.getAllAccountInfo()
    this.completeResetForm()
  }

  resetAndShowErrorMessage = async () => {
    this.props.toggleErrorMessage(this.props.t('somethingwentwrong'))
    this.completeResetForm()
  }

  filterAccounts = () => {
    // Look through all accounts
    let using_alp = false
    this.props.allAccounts.forEach((acc) => {
      // if accounts have interactive broker
      if (acc.inst === 'Alpaca') {
        console.log('----- TRADING WITH Alpaca -----')
        this.setState({ usingAlpaca: true })
        using_alp = true
      }
      if (acc.product_assigned) {
        if (
          (!this.props.selected.isCrypto &&
            acc.product_assigned === 'equity') ||
          acc.product_assigned === 'trade'
        ) {
          let matchingStock = null
          acc.holdings.forEach((holding) => {
            if (holding.symbol === this.props.symbol) {
              matchingStock = holding
            }
          })
          let sharesOwned = 0
          if (matchingStock) sharesOwned = matchingStock.quantity
          this.setState(
            {
              account: acc,
              holding: matchingStock,
              sharesOwned: sharesOwned,
              isLoading: false,
              showSummary: false,
              errorMessage: '',
            },
            () => {
              this.getOrders()
              this.getAccountTransactionsData()
              // this.getSafeCash()
            }
          )
        } else if (
          this.props.selected.isCrypto &&
          acc.product_assigned === 'crypto'
        ) {
          let matchingStock = null
          acc.holdings.forEach((holding) => {
            if (holding.symbol === this.props.symbol) {
              matchingStock = holding
            }
          })
          let sharesOwned = 0
          if (matchingStock) sharesOwned = matchingStock.quantity
          this.setState(
            {
              account: acc,
              holding: matchingStock,
              sharesOwned: sharesOwned,
              isLoading: false,
              showSummary: false,
              errorMessage: '',
            },
            () => {
              this.getOrders()
              this.getAccountTransactionsData()
              // this.getSafeCash()
            }
          )
        }
      }
    })
    this.setState({ isLoading: false })
  }

  setRecBuy = (
    dw_account_id,
    plaid_account_id,
    periodic,
    stock,
    roundup,
    frequency
  ) => {
    this.setState({ editRecBuyStatus: 'loading' })
    let body = {
      dw_account_id: dw_account_id,
      plaid_account_id: plaid_account_id,
      periodic: periodic,
      stock: stock,
      roundup: roundup,
      frequency: frequency,
    }
    return fetch(`${ENDPOINT}/mobile/recurring/buy-new`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((responseJson) => {
        if (responseJson.Status === 'Success') {
          this.setState({ editRecBuyStatus: 'success' })
          this.getAccountTransactionsData()
          return true
        } else {
          this.setState({ editRecBuyStatus: 'error' })
          this.setState({ errors: responseJson.status })
          return false
        }
      })
      .catch((err) => {
        console.log('trade submission errors: ', err)
        this.setState({
          errors: 'Something went wrong, please try again later',
        })
        return false
      })
  }

  getAllAccountInfo = () => {
    fetch(`${ENDPOINT}/mobile/user/holdings`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err)
      })
      .then((responseJson) => {
        let allAccounts = []
        responseJson.items.forEach((inst) =>
          inst.accounts.forEach((acc) => {
            if (acc.product_assigned) {
              if (
                this.props.selected.isCrypto &&
                acc.product_assigned === 'equity'
              ) {
                let matchingStock = null
                acc.holdings.forEach((holding) => {
                  if (holding.symbol === this.props.symbol) {
                    matchingStock = holding
                  }
                })
                let sharesOwned = 0
                if (matchingStock) sharesOwned = matchingStock.quantity
                this.setState(
                  {
                    account: acc,
                    holding: matchingStock,
                    sharesOwned: sharesOwned,
                    isLoading: false,
                    showSummary: false,
                    errorMessage: '',
                  },
                  () => {
                    this.getOrders()
                  }
                )
              } else if (
                this.props.isApexSupported &&
                acc.product_assigned === 'crypto'
              ) {
                let matchingStock = null
                acc.holdings.forEach((holding) => {
                  if (holding.symbol === this.props.symbol) {
                    matchingStock = holding
                  }
                })
                let sharesOwned = 0
                if (matchingStock) sharesOwned = matchingStock.quantity
                this.setState({
                  account: acc,
                  holding: matchingStock,
                  sharesOwned: sharesOwned,
                  isLoading: false,
                  showSummary: false,
                  errorMessage: '',
                })
              }
            }
          })
        )
        this.setState({ isLoading: false })
      })
      .catch((err) => {
        console.log(`catching errors`, err)
        this.setState({ isLoading: false })
      })
  }
  //risk-monkey/ticker/{symbol}/live
  getLivePrice = async (sym) => {
    let url = `${SearchEndpoint}/ticker-bulk-live?search=`
    if (this.state.selected) {
      url = url + `${this.state.selected.symbol.toUpperCase()}`
    } else {
      url = url + `${sym?.toUpperCase()}`
    }
    fetch(url)
      .catch((err) => console.log(`catching errors`, err))
      .then((res) => res.json())
      .catch((err) => {
        console.log(err)
      })
      .then((responseJson) => {
        if (this.state.selected) {
          this.setState({
            selected: {
              ...this.state.selected,
              live: responseJson?.content[0][
                this.state.selected?.symbol?.toUpperCase()
              ],
            },
          })
        } else {
          this.setState({
            selected: {
              ...this.state.selected,
              live: responseJson?.content[0][sym.toUpperCase()],
            },
          })
        }
      })
  }

  // getDetailsInfo = async () => {
  //   let url = `${Endpoint}/mobile/risk-monkey/ticker/${this.state.selected.symbol?.toUpperCase()}/live?nbbo=true`
  //   try {
  //     const res = await Axios.get(url, {
  //       headers: {
  //         Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
  //       },
  //     })
  //     if (res?.data) {
  //       this.setState({ detailsInfo: res.data })
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  toggleErrorMessage = (msg) => {
    this.setState({ errorMessage: msg })
    // setTimeout(() => {
    //   this.setState({ errorMessage: "" });
    // }, 2500);
  }

  resetForm = () => {
    return {
      orderType:
        this.props?.isDwSupported && !this.props.canTrade?.status
          ? 'limit'
          : 'market',
      investmentType:
        this.props?.isDwSupported && !this.props.canTrade?.status
          ? 'shares'
          : 'dollars',
      limitPrice: 0,
      amount: null,
      shares: 0,
      errorMessage: '',
    }
  }

  handleTabChange = (value) => {
    this.props.disablePrefillData()
    let newState = this.resetForm()
    newState.activeTab = value
    // let newState = { activeTab: value, errorMessage: "" };
    if (value === 'Sell') newState.investmentType = 'shares'
    else newState.investmentType = 'dollars'
    this.setState(newState)
  }

  handleOrderTypeChange = (value) => {
    this.props.disablePrefillData()
    if (value === 'limit') {
      if (this.state.shares % 1 !== 0)
        this.toggleErrorMessage(
          'Cannot enter fractional shares for limit order'
        )
      else {
        this.setState({ orderType: value })
        this.handleInvestmentTypeChange('shares')
      }
    } else {
      this.setState({ orderType: value })
      if (this.state.orderType === 'Buy')
        this.handleInvestmentTypeChange('dollars')
    }
  }

  handleLimitPriceChange = (value) => {
    this.props.disablePrefillData()
    this.setState({ limitPrice: value })
  }

  handleInvestmentTypeChange = (value) => {
    this.props.disablePrefillData()
    if (value === 'shares') {
      if (!this.props.canTrade?.status)
        this.toggleErrorMessage(
          'Cannot trade in shares during non-trading hours'
        )
      else {
        this.setState({ investmentType: value, amount: 0 })
      }
    } else {
      this.setState({ investmentType: value, shares: 0 })
    }
  }

  handleAmountChange = (value) => {
    console.log(value)
    this.props.disablePrefillData()
    this.setState({ amount: value })
  }

  handleSharesChange = (value) => {
    let newState = { shares: value }
    if (this.state.orderType === 'limit' && value % 1 !== 0) {
      newState.orderType = 'market'
      this.toggleErrorMessage(
        'Only market orders are supported with fractional shares'
      )
    }
    this.props.disablePrefillData()
    this.setState(newState)
  }

  header() {
    return (
      <div className='header-container'>
        {this.state.showSummary ? (
          <>{!this.state.isLoading ? this.backArrow() : null}</>
        ) : (
          <>
            {' '}
            <p
              className={`header ${
                this.state.activeTab === 'Buy' ? 'selected' : 'not-selected'
              }`}
              onClick={() => {
                this.handleTabChange('Buy')
              }}
            >
              {this.props.t('buy')}
            </p>
            {this.state.sharesOwned > 0 && this.state.pendingAmount !== null ? (
              <p
                className={`header ${
                  this.state.activeTab === 'Sell' ? 'selected' : 'not-selected'
                }`}
                onClick={() => {
                  this.handleTabChange('Sell')
                }}
              >
                {this.props.t('sell')}
              </p>
            ) : null}
          </>
        )}
      </div>
    )
  }

  orderTypeForm() {
    let options = [{ label: this.props.t('marketorder'), value: 'market' }]

    if (this.props.type === 'Equity')
      options.push({ label: this.props.t('limitorder'), value: 'limit' })
    if (!this.props.canTrade.status && this.props.isDwSupported) {
      options = [{ label: this.props.t('limitorder'), value: 'limit' }]
    }
    return (
      <div className='order-type-form-container'>
        <p className='label'>{this.props.t('ordertype')}</p>
        <div className='order-type-form'>
          <Dropdown
            value={this.state.orderType}
            options={options}
            onChange={(e) => this.handleOrderTypeChange(e.value)}
          />
        </div>
      </div>
    )
  }

  investmentTypeForm() {
    return (
      <div className='investment-type-form-container'>
        <p className='label'>{this.props.t('investin')}</p>
        <div className='investment-form'>
          <Dropdown
            value={this.state.investmentType}
            options={
              // this.props.isApexSupported && !this.props.isDwSupported
              this.props.selected.isCrypto
                ? this.state.activeTab === 'Buy'
                  ? [{ label: this.props.t('dollars'), value: 'dollars' }]
                  : [{ label: this.props.t('shares'), value: 'shares' }]
                : this.state.activeTab === 'Buy'
                ? [
                    { label: this.props.t('dollars'), value: 'dollars' },
                    { label: this.props.t('shares'), value: 'shares' },
                  ]
                : [{ label: this.props.t('shares'), value: 'shares' }]
            }
            onChange={(e) => this.handleInvestmentTypeChange(e.value)}
          />
        </div>
      </div>
    )
  }

  limitPriceForm() {
    return (
      <div className='limit-price-form-container'>
        <p className='label'>{this.props.t('limitprice')}</p>
        <div className='limit-price-form'>
          <InputNumber
            value={this.state.limitPrice}
            onValueChange={(e) => this.handleLimitPriceChange(e.value)}
            mode='currency'
            currency='USD'
            locale='en-US'
            minFractionDigits={0}
            maxFractionDigits={2}
          />
        </div>
      </div>
    )
  }

  amountForm() {
    return (
      <div className='amount-form-container'>
        <p className='label'>{this.props.t('amount')}</p>
        <div className='amount-form'>
          {this.props.type === 'Equity' ? (
            <InputNumber
              value={this.state.amount}
              onValueChange={(e) => this.handleAmountChange(e.value)}
              placeholder={'$0.00'}
              mode='currency'
              currency='USD'
              locale='en-US'
              minFractionDigits={0}
              maxFractionDigits={5}
            />
          ) : (
            <InputNumber
              value={this.state.amount}
              onValueChange={(e) => this.handleAmountChange(e.value)}
              mode='currency'
              currency='USD'
              locale='en-US'
              minFractionDigits={0}
              maxFractionDigits={5}
            />
          )}
        </div>
      </div>
    )
  }

  sharesForm() {
    return (
      <div className='shares-form-container'>
        <p className='label'>{this.props.t('shares')}</p>
        <div className='shares-form'>
          <InputNumber
            value={this.state.shares}
            onValueChange={(e) => this.handleSharesChange(e.value)}
            mode='decimal'
            locale='en-US'
            minFractionDigits={0}
            maxFractionDigits={8}
          />
        </div>
      </div>
    )
  }

  estimatedCost() {
    return (
      <div className='estimated-cost-container'>
        <p className='label'>{this.props.t('estimatedcost')}</p>
        <p className='value'>
          {this.state.orderType === 'limit'
            ? this.props.formatCurrency(this.calculateApproxAmount())
            : this.props.formatCurrency(this.calculateCost())}
        </p>
      </div>
    )
  }

  calculateCost() {
    return this.state.selected?.priceUSD * this.state.shares
  }

  estimatedQuantity() {
    return (
      <div className='estimated-quantity-container'>
        <p className='label'>{this.props.t('estimatedquantity')}</p>
        <p className='value'>{this.calculateQuantity()}</p>
      </div>
    )
  }

  calculateQuantity() {
    return (this.state.amount / this.state.selected?.priceUSD).toFixed(8)
  }

  approxAmount() {
    return (
      <div className='estimated-cost-container'>
        <p className='label'>{this.props.t('approxamount')}</p>
        {this.state.orderType === 'limit' ? (
          <p className='value'>
            {this.props.formatCurrency(this.calculateApproxAmount())}
          </p>
        ) : (
          <p className='value'>
            {this.props.formatCurrency(this.calculateCost())}
          </p>
        )}
      </div>
    )
  }

  calculateApproxAmount() {
    return this.state.limitPrice * this.state.shares
  }

  submitButton() {
    return (
      <div className='submit-btn-container'>
        {this.state.isLoading ? (
          <Button
            className='review-btn'
            label={this.props.t('revieworder')}
            loading
          />
        ) : this.props.canTrade?.status ? (
          <Button
            label={this.props.t('revieworder')}
            className='review-btn'
            onClick={() => {
              if (this.state.activeTab === 'Sell') this.validateSellForm()
              else this.validateBuyForm()
            }}
            disabled={
              this.state.usingAlpaca
                ? !this.props.isDwSupported &&
                  !this.props.isApexSupported &&
                  this.state.activeTab === 'Buy'
                : this.state.activeTab === 'Sell'
                ? false
                : true
            }
          />
        ) : this.props.canTrade?.extendedHours ? (
          this.state.useExtendedHours === 1 ? (
            <Button
              label={this.props.t('revieworder')}
              className='review-btn'
              onClick={() => {
                if (this.state.activeTab === 'Sell') this.validateSellForm()
                else this.validateBuyForm()
              }}
              disabled={
                this.state.usingAlpaca
                  ? !this.props.isDwSupported &&
                    !this.props.isApexSupported &&
                    this.state.activeTab === 'Buy'
                  : this.state.activeTab === 'Sell'
                  ? false
                  : true
              }
            />
          ) : (
            <Button
              label={this.props.t('revieworder')}
              className='review-btn'
              onClick={() => {
                if (this.state.activeTab === 'Sell') this.validateSellForm()
                else this.validateBuyForm()
              }}
              disabled={true}
            />
          )
        ) : (
          <Button
            label={this.props.t('revieworder')}
            className='review-btn'
            onClick={() => {
              if (this.state.activeTab === 'Sell') this.validateSellForm()
              else this.validateBuyForm()
            }}
            disabled={true}
          />
        )}
      </div>
    )
  }

  validateBuyForm = () => {
    // Validate if account is present in state
    if (this.state.account) {
      // if the account is IB
      if (this.state.usingAlpaca) {
        if (this.props.isIbSupported) {
          if (this.state.orderType === 'market') {
            if (this.state.investmentType === 'shares') {
              if (this.calculateCost() > 1) {
                if (this.state.shares > 0) {
                  if (
                    this.state.account?.balances[0]?.buyingPower >
                    this.calculateCost()
                  ) {
                    this.setState({ showSummary: true })
                  } else {
                    this.toggleErrorMessage(
                      this.props.t('notenoughbuyingpower')
                    )
                  }
                } else {
                  this.toggleErrorMessage(
                    this.props.t('pleaseentersharesgreaterthan0')
                  )
                }
              } else {
                this.toggleErrorMessage(
                  this.props.t('pleaseentergreatersharesamount')
                )
              }
            } else {
              //dollars
              if (this.state.amount >= 1) {
                if (
                  this.state.account?.balances[0]?.buyingPower >
                  this.state.amount
                ) {
                  this.setState({ showSummary: true })
                } else {
                  this.toggleErrorMessage(this.props.t('notenoughbuyingpower'))
                }
              } else {
                this.toggleErrorMessage(
                  this.props.t('amountmustbeatleast1dollar')
                )
              }
            }
          } else {
            //limit
            if (this.state.limitPrice > 0) {
              if (this.state.shares > 0) {
                if (
                  this.state.account?.balances[0]?.buyingPower >
                  this.calculateApproxAmount()
                ) {
                  this.setState({ showSummary: true })
                } else {
                  this.toggleErrorMessage(this.props.t('notenoughbuyingpower'))
                }
              } else {
                this.toggleErrorMessage(
                  this.props.t('pleaseentersharesgreaterthan0')
                )
              }
            } else {
              this.toggleErrorMessage(
                this.props.t('pleaseenterlimitpricegreaterthan0')
              )
            }
          }
        } else {
          this.toggleErrorMessage(this.props.t('notsupportedfortrading'))
        }
      } else {
        if (this.props.isDwSupported) {
          if (this.state.orderType === 'market') {
            if (this.state.investmentType === 'shares') {
              if (this.calculateCost() > 1) {
                if (this.state.shares > 0) {
                  if (
                    this.state.account?.balances[0]?.buyingPower >
                    this.calculateCost()
                  ) {
                    this.setState({ showSummary: true })
                  } else {
                    this.toggleErrorMessage(
                      this.props.t('notenoughbuyingpower')
                    )
                  }
                } else {
                  this.toggleErrorMessage(
                    this.props.t('pleaseentersharesgreaterthan0')
                  )
                }
              } else {
                this.toggleErrorMessage(
                  this.props.t('pleaseentergreatersharesamount')
                )
              }
            } else {
              //dollars
              if (this.state.amount >= 1) {
                if (
                  this.state.account?.balances[0]?.buyingPower >
                  this.state.amount
                ) {
                  this.setState({ showSummary: true })
                } else {
                  this.toggleErrorMessage(this.props.t('notenoughbuyingpower'))
                }
              } else {
                this.toggleErrorMessage(
                  this.props.t('amountmustbeatleast1dollar')
                )
              }
            }
          } else {
            //limit
            if (this.state.limitPrice > 0) {
              if (this.state.shares > 0) {
                if (
                  this.state.account?.balances[0]?.buyingPower >
                  this.calculateApproxAmount()
                ) {
                  this.setState({ showSummary: true })
                } else {
                  this.toggleErrorMessage(this.props.t('notenoughbuyingpower'))
                }
              } else {
                this.toggleErrorMessage(
                  this.props.t('pleaseentersharesgreaterthan0')
                )
              }
            } else {
              this.toggleErrorMessage(
                this.props.t('pleaseenterlimitpricegreaterthan0')
              )
            }
          }
        } else if (this.props.isApexSupported) {
          if (this.props.canTrade?.isCryptoTrading) {
            if (this.state.activeTab === 'Buy') {
              if (
                this.state.selected?.live?.nySupported ||
                (!this.state.selected?.live?.nySupported &&
                  this.props.userData.province !== 'NY')
              ) {
                if (this.state.amount >= 5) {
                  if (
                    this.state.account?.balances[0]?.buyingPower >
                    this.state.amount
                  ) {
                    this.setState({ showSummary: true })
                  } else {
                    this.toggleErrorMessage(
                      this.props.t('notenoughbuyingpower')
                    )
                  }
                } else {
                  this.toggleErrorMessage(
                    this.props.t('amountmustbeatleast5dollars')
                  )
                }
              } else {
                this.toggleErrorMessage(
                  this.props.t('thiscryptoisnotsupporedfornewyorkresidents')
                )
              }
            }
          } else {
            this.toggleErrorMessage(this.props.t('cryptomaintenance'))
          }
        } else {
          this.toggleErrorMessage(this.props.t('notsupportedfortrading'))
        }
      }
    } else {
      this.toggleErrorMessage(this.props.t('pleaseopenanaccounttotrade'))
    }
  }

  validateSellForm = async () => {
    console.log('check if trading violation')
    let res = await this.checkPDTViolation()
    // check if pdt is false first
    res.pdt = true
    if (res.warning_pdt) {
      console.log('res', res)
      if (res.pdt === true) {
        console.log('Pattern Day Tradding Error')
        this.setState({
          showPDTError: true,
        })
        return false
      } else if (res.pdt === false) {
        console.log('1')
        this.setState({
          showPDTWarning: true,
        })
        return false
      }
    }

    //if stock is drive wealth supported or stock is not crypto and the user owns this stock.
    if (
      this.props.isDwSupported ||
      (!this.props.selected.isCrypto && this.state.sharesOwned > 0)
    ) {
      if (this.state.orderType === 'market') {
        if (this.state.shares > 0) {
          if (this.state.shares <= this.state.sharesOwned) {
            this.setState({ showSummary: true })
          } else {
            this.toggleErrorMessage(this.props.t('youcannotsellmorethanyouown'))
          }
        } else {
          this.toggleErrorMessage(this.props.t('pleaseentersharesgreaterthan0'))
        }
      } else {
        //limit
        if (this.state.limitPrice > 0) {
          if (this.state.shares > 0) {
            if (this.state.shares <= this.state.sharesOwned) {
              this.setState({ showSummary: true })
            } else {
              this.toggleErrorMessage(
                this.props.t('youcannotsellmorethanyouown')
              )
            }
          } else {
            this.toggleErrorMessage(
              this.props.t('pleaseentersharesgreaterthan0')
            )
          }
        } else {
          this.toggleErrorMessage(
            this.props.t('pleaseenterlimitpricegreaterthan0')
          )
        }
      }
    } else if (this.props.isApexSupported) {
      if (
        this.state.selected?.live?.nySupported ||
        (!this.state.selected?.live?.nySupported &&
          this.props.userData.province !== 'NY')
      ) {
        if (this.state.shares > 0) {
          if (this.state.shares <= this.state.sharesOwned) {
            this.setState({ showSummary: true })
          } else {
            this.toggleErrorMessage(this.props.t('youcannotsellmorethanyouown'))
          }
        } else {
          this.toggleErrorMessage(this.props.t('pleaseentersharesgreaterthan0'))
        }
      } else {
        this.toggleErrorMessage(
          this.props.t('thiscryptoisnotsupporedfornewyorkresidents')
        )
      }
    } else {
      this.toggleErrorMessage(this.props.t('notsupportedfortrading'))
    }
  }

  buyingPower() {
    return (
      <div className='buying-power-container'>
        <p className='buying-power'>
          <span className='buying-power-label'>
            {this.props.t('buyingpower')}:
          </span>{' '}
          {this.props.formatCurrency(
            this.state.account?.balances[0]?.buyingPower
          )}
        </p>
      </div>
    )
  }

  numberOfSharesOwned() {
    return (
      <div className='buying-power-container'>
        <p className='buying-power'>
          <span className='buying-power-label'>
            {this.props.t('availableshares')}:
          </span>{' '}
          {this.state.sharesOwned < 1
            ? this.state.sharesOwned.toFixed(8)
            : this.state.sharesOwned.toFixed(2)}
          {/* {this.state.sharesOwned.toFixed(5)} <span className="dash">-</span> */}
          <span className='dash'>-</span>
        </p>
        <Button
          label={this.props.t('sellall')}
          className='sell-all-btn'
          onClick={() => {
            this.handleSharesChange(this.state.sharesOwned)
          }}
        />
      </div>
    )
  }

  limitOrderMessage() {
    if (this.state.orderType === 'limit') {
      return (
        <div className='limit-order-message-container'>
          {this.state.useExtendedHours === 1 ? (
            <p className='limit-order-message'>
              {this.props.t('limit_orders_are_good_until')}
            </p>
          ) : this.props.canTrade?.extendedHours ? null : (
            <p className='limit-order-message'>
              {this.props.t('limitordersaregooduntilnextmarketclose')}
            </p>
          )}
        </div>
      )
    } else return null
  }

  buyForm() {
    return (
      <div className='buy-form'>
        <div className='form-container'>
          {this.orderTypeForm()}
          {this.state.orderType === 'market'
            ? this.investmentTypeForm()
            : this.limitPriceForm()}
          {this.state.investmentType === 'dollars' &&
          this.state.orderType === 'market'
            ? this.amountForm()
            : this.sharesForm()}
          {this.state.orderType === 'limit' ? (
            <>{this.approxAmount()}</>
          ) : (
            <>
              {this.state.investmentType === 'shares'
                ? this.estimatedCost()
                : this.estimatedQuantity()}
            </>
          )}
          <p className='error'>{this.state.errorMessage}</p>
          {this.submitButton()}
          {this.limitOrderMessage()}
          {this.props.isDwSupported && this.state.orderType === 'limit' && (
            <div className='extended-hours-checkbox-container'>
              <Checkbox
                onChange={() => {
                  // If the user already has extended hours activated then they can just toggle it one and off
                  if (this.props.dwExtendedHours === 1) {
                    this.setState({
                      useExtendedHours:
                        this.state.useExtendedHours === 1 ? 0 : 1,
                    })
                  }
                  // If the user doesn't have extended hours activated then they have to agree to the terms
                  else {
                    this.props.showExtendedHoursModal()
                  }
                  // console.log(this.props.dwExtendedHours)
                }}
                checked={this.state.useExtendedHours === 1 ? true : false}
              ></Checkbox>
              <p>{this.props.t('use_extended_hours')}</p>
            </div>
          )}
        </div>
        {this.buyingPower()}
      </div>
    )
  }

  sellForm() {
    return (
      <div className='sell-form'>
        <div className='form-container'>
          {this.orderTypeForm()}
          {this.state.orderType === 'market'
            ? this.investmentTypeForm()
            : this.limitPriceForm()}
          {this.state.investmentType === 'dollars' &&
          this.state.orderType === 'market'
            ? this.amountForm()
            : this.sharesForm()}
          {this.approxAmount()}
          <p className='error'>{this.state.errorMessage}</p>
          {this.submitButton()}
          {this.limitOrderMessage()}
          {this.props.isDwSupported && (
            <div className='extended-hours-checkbox-container'>
              <Checkbox
                onChange={() => {
                  // If the user already has extended hours activated then they can just toggle it one and off
                  if (this.props.dwExtendedHours === 1) {
                    this.setState({
                      useExtendedHours:
                        this.state.useExtendedHours === 1 ? 0 : 1,
                    })
                  }
                  // If the user doesn't have extended hours activated then they have to agree to the terms
                  else {
                    this.props.showExtendedHoursModal()
                  }
                  // console.log(this.props.dwExtendedHours)
                }}
                checked={this.state.useExtendedHours === 1 ? true : false}
              ></Checkbox>
              <p>{this.props.t('use_extended_hours')}</p>
            </div>
          )}
        </div>
        {this.numberOfSharesOwned()}
      </div>
    )
  }

  finalActionButtons() {
    return (
      <div className='final-action-btn-container'>
        {/* <Button
          label="Edit"
          className="edit-btn"
          onClick={() => {
            this.setState({ showSummary: false });
          }}
          loading={this.state.isLoading}
        /> */}
        <Button
          label={this.props.t('submit')}
          className='final-submit-btn'
          onClick={() => {
            if (!this.props.selected.isCrypto) this.checkGFV()
            else this.tradeCryptoApex()
          }}
          loading={this.state.isLoading}
        />
      </div>
    )
  }

  checkGFV = () => {
    //BUY
    if (this.state.activeTab === 'Buy') {
      if (this.state.orderType === 'market') {
        //BUY MARKET DOLLARS
        if (this.state.investmentType === 'dollars') {
          this.checkAmount(this.state.amount)
        }
        //BUY MARKET SHARES
        else {
          this.checkAmount(this.calculateCost())
        }
      } else {
        //BUY LIMIT
        this.checkAmount(this.calculateApproxAmount())
      }
    }
    //SELL
    else {
      if (this.state.orderType === 'market') {
        //SELL MARKET
        this.checkAmountSell(this.calculateCost())
      } else {
        //SELL LIMIT
        this.checkAmountSell(this.calculateApproxAmount())
      }
    }
  }

  checkAmount = (amount) => {
    let settledAmount =
      this.state.account?.balances[0]?.buyingPower -
      this.state.account?.balances[0]?.totalUnsettledCash
    if (amount > settledAmount) {
      this.setState({ gfvMessage: this.getGfvMessage() }, () => {
        this.toggleShowConfirmationDialog()
      })
    } else {
      if (this.state.usingAlpaca) {
        console.log('TIME TO CALL IB TRADE API')
        this.tradeStockAlpaca()
      } else {
        this.tradeStockDW()
      }
    }
  }

  checkAmountSell = async (amount) => {
    let settledAmount =
      this.state.safeCash - this.state.account?.balances[0]?.totalUnsettledCash
    if (amount > settledAmount) {
      this.setState({ gfvMessage: this.getGfvMessage() }, () => {
        this.toggleShowConfirmationDialog()
      })
    } else {
      if (this.state.usingAlpaca) {
        console.log('TIME TO CALL IB TRADE API')
        this.tradeStockAlpaca()
      } else {
        this.tradeStockDW()
      }
    }
  }

  checkPDTViolation = async () => {
    try {
      const body = {
        accountID: this.state.account?.accountId,
        symbol: this.state.selected?.live?.symbol,
        qty: this.state.shares,
      }
      let data = await Axios.post(ENDPOINT + '/mobile/tax-optimization', body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        },
      })
      console.log(data.data.pdt_info)
      return data.data.pdt_info
    } catch (error) {
      console.log('pdt error', error)
      return {}
    }
  }

  getGfvMessage = () => {
    let gfv = this.state.account?.gfv_count
    switch (gfv) {
      case 0:
        return this.props.t('gfv1')
      case 1:
        return this.props.t('gfv1')
      case 2:
        return this.props.t('gfv2')
      default:
        return ''
    }
  }

  afterHoursMessage() {
    if (!this.props.canTrade?.status && !this.props.selected.isCrypto) {
      return (
        <div className='after-hours-container'>
          <p className='after-hours-msg'>
            {this.props.t('thismarketorderwillexecuteaftermarkethours')}
          </p>
        </div>
      )
    } else return null
  }

  // displayDetails = () => {
  //   if (this.state.showDetails) {
  //     return (
  //       <>
  //         <div className='summary-row'>
  //           <p className='summary-label'>{this.props.t('bidaskprice')}</p>
  //           <p className='summary-value'>
  //             {this.props.formatCurrency(this.state.detailsInfo?.bid)} /{' '}
  //             {this.props.formatCurrency(this.state.detailsInfo?.ask)}
  //           </p>
  //         </div>
  //         <div className='summary-row'>
  //           <p className='summary-label'>{this.props.t('bidaskexchange')}</p>
  //           <p className='summary-value'>IX/Q</p>
  //         </div>
  //         <div className='summary-row'>
  //           <p className='summary-label'>{this.props.t('daysrange')}</p>
  //           <p className='summary-value'>
  //             {this.props.formatCurrency(this.state.detailsInfo?.low)} /{' '}
  //             {this.props.formatCurrency(this.state.detailsInfo?.high)}
  //           </p>
  //         </div>
  //         <div className='summary-row'>
  //           <p className='summary-label'>{this.props.t('volume')}</p>
  //           <p className='summary-value'>{this.state.detailsInfo?.volume}</p>
  //         </div>
  //         <div className='summary-row'>
  //           <p className='summary-label'>{this.props.t('time')}</p>
  //           <p className='summary-value'>
  //             {moment(new Date()).format('HH:mm:ss')}
  //           </p>
  //         </div>
  //         <div className='summary-row'>
  //           <p className='summary-label'>{this.props.t('lasttrade')}</p>
  //           <p className='summary-value'>{this.state.detailsInfo?.lastTrade}</p>
  //         </div>
  //         <div className='summary-row'>
  //           <p className='summary-label'>{this.props.t('lasttradesize')}</p>
  //           <p className='summary-value'>
  //             {this.state.detailsInfo?.lastTradeSize}
  //           </p>
  //         </div>
  //         <div className='summary-row'>
  //           <p className='summary-label'>{this.props.t('lasttradeexchange')}</p>
  //           <p className='summary-value'>
  //             {this.state.detailsInfo?.lastTradeExchange}
  //           </p>
  //         </div>
  //         <p
  //           className='show-details'
  //           onClick={() => {
  //             this.setState({ showDetails: false })
  //           }}
  //         >
  //           {this.props.t('hidedetails')}
  //         </p>
  //       </>
  //     )
  //   } else {
  //     return (
  //       <p
  //         className='show-details'
  //         onClick={() => {
  //           this.setState({ showDetails: true })
  //         }}
  //       >
  //         {this.props.t('showdetails')}
  //       </p>
  //     )
  //   }
  // }

  displaySummary() {
    if (this.state.activeTab === 'Buy') {
      if (this.state.orderType === 'market') {
        if (this.state.investmentType === 'shares') {
          return (
            <div className='summary-container'>
              <div className='summary-row'>
                <p className='summary-label'>{this.props.t('buy')}</p>
                <p className='summary-value'>{this.state.selected?.symbol}</p>
              </div>
              <div className='summary-row'>
                <p className='summary-label'>
                  {this.props.t('numberofshares')}
                </p>
                <p className='summary-value'>{this.state.shares}</p>
              </div>
              <div className='summary-row'>
                <p className='summary-label'>{this.props.t('ordertype')}</p>
                <p className='summary-value blue-text'>
                  {this.props.t('market')}
                </p>
              </div>
              <div className='summary-row'>
                <p className='summary-label'>
                  {this.props.t('estimatedcommission')}
                </p>
                <p className='summary-value'>$0</p>
              </div>
              <div className='summary-row'>
                <p className='summary-label'>
                  {this.props.t('approxtotalamount')}
                </p>
                <p className='summary-value'>
                  {this.props.formatCurrency(this.calculateCost())}
                </p>
              </div>
              {/* {this.displayDetails()} */}
              {this.afterHoursMessage()}
              {this.finalActionButtons()}
            </div>
          )
        } else {
          //dollars
          return (
            <div className='summary-container'>
              <div className='summary-row'>
                <p className='summary-label'>{this.props.t('buy')}</p>
                <p className='summary-value'>{this.state.selected?.symbol}</p>
              </div>
              <div className='summary-row'>
                <p className='summary-label'>
                  {this.props.t('approxnumberofshares')}
                </p>
                <p className='summary-value'>{this.calculateQuantity()}</p>
              </div>
              <div className='summary-row'>
                <p className='summary-label'>{this.props.t('ordertype')}</p>
                <p className='summary-value blue-text'>
                  {this.props.t('market')}
                </p>
              </div>
              <div className='summary-row'>
                <p className='summary-label'>
                  {this.props.t('estimatedcommission')}
                </p>
                <p className='summary-value'>$0</p>
              </div>
              <div className='summary-row'>
                <p className='summary-label'>
                  {this.props.t('approxtotalamount')}
                </p>
                <p className='summary-value'>
                  {this.props.formatCurrency(this.state.amount)}
                </p>
              </div>
              {/* {this.displayDetails()} */}
              {this.finalActionButtons()}
              {this.afterHoursMessage()}
            </div>
          )
        }
      } else {
        //limit
        return (
          <div className='summary-container'>
            <div className='summary-row'>
              <p className='summary-label'>{this.props.t('buy')}</p>
              <p className='summary-value'>{this.state.selected?.symbol}</p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>
                {this.props.t('approxnumberofshares')}
              </p>
              <p className='summary-value'>{this.state.shares}</p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>{this.props.t('ordertype')}</p>
              <p className='summary-value blue-text'>{this.props.t('limit')}</p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>{this.props.t('limitprice')}</p>
              <p className='summary-value'>
                {this.props.formatCurrency(this.state.limitPrice)}
              </p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>
                {this.props.t('estimatedcommission')}
              </p>
              <p className='summary-value'>$0</p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>
                {this.props.t('approxtotalamount')}
              </p>
              <p className='summary-value'>
                {this.props.formatCurrency(this.calculateApproxAmount())}
              </p>
            </div>
            {/* {this.displayDetails()} */}
            {this.finalActionButtons()}
          </div>
        )
      }
    } else {
      //sell
      if (this.state.orderType === 'market') {
        return (
          <div className='summary-container'>
            <div className='summary-row'>
              <p className='summary-label'>{this.props.t('sell')}</p>
              <p className='summary-value'>{this.state.selected?.symbol}</p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>{this.props.t('numberofshares')}</p>
              <p className='summary-value'>{this.state.shares}</p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>{this.props.t('ordertype')}</p>
              <p className='summary-value blue-text'>
                {this.props.t('market')}
              </p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>
                {this.props.t('estimatedcommission')}
              </p>
              <p className='summary-value'>
                {/* {this.props.formatCurrency(
                  this.state.shares * 0.000119 +
                    this.calculateCost() * 0.0000051
                )} */}
                $
                {/* {(
                  this.state.shares * 0.000119 +
                  this.calculateCost() * 0.0000051
                ).toFixed(8)} */}
                {(
                  Math.min(this.state.shares * 0.00013, 6.49) +
                  this.calculateCost() * 0.0000229
                ).toFixed(8)}
              </p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>
                {this.props.t('approxtotalamount')}
              </p>
              <p className='summary-value'>
                {this.props.formatCurrency(this.calculateCost())}
              </p>
            </div>
            {/* {this.displayDetails()} */}
            {this.finalActionButtons()}
            {this.afterHoursMessage()}
          </div>
        )
      } else {
        //limit
        return (
          <div className='summary-container'>
            <div className='summary-row'>
              <p className='summary-label'>{this.props.t('sell')}</p>
              <p className='summary-value'>{this.state.selected?.symbol}</p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>{this.props.t('numberofshares')}</p>
              <p className='summary-value'>{this.state.shares}</p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>{this.props.t('ordertype')}</p>
              <p className='summary-value blue-text'>{this.props.t('limit')}</p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>{this.props.t('limitprice')}</p>
              <p className='summary-value'>
                {this.props.formatCurrency(this.state.limitPrice)}
              </p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>
                {this.props.t('estimatedcommission')}
              </p>
              <p className='summary-value'>
                {/* {this.props.formatCurrency(
                  this.state.shares * 0.000119 +
                    this.calculateApproxAmount() * 0.0000051
                )} */}
                $
                {/* {(
                  this.state.shares * 0.000119 +
                  this.calculateApproxAmount() * 0.0000051
                ).toFixed(8)} */}
                {(
                  Math.min(this.state.shares * 0.00013, 6.49) +
                  this.calculateApproxAmount() * 0.0000229
                ).toFixed(8)}
              </p>
            </div>
            <div className='summary-row'>
              <p className='summary-label'>
                {this.props.t('approxtotalamount')}
              </p>
              <p className='summary-value'>
                {this.props.formatCurrency(this.calculateApproxAmount())}
              </p>
            </div>
            {/* {this.displayDetails()} */}
            {this.finalActionButtons()}
          </div>
        )
      }
    }
  }

  toggleEditModal = () => {
    this.setState({
      showRecurringBuyDialog: !this.state.showRecurringBuyDialog,
    })
  }

  backArrow() {
    return (
      <div
        className='back-arrow-btn'
        onClick={() => {
          this.setState({ showSummary: false, errorMessage: '' })
        }}
      >
        <FaLongArrowAltLeft size={25} color='#888888' />
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return (
        <div className='buy-or-sell-form-container'>
          <>
            {this.header()}
            {this.state.showSummary ? (
              this.displaySummary()
            ) : this.state.formLoading ? (
              <div>
                <Loader logoLoader={true} iconHeight={'50vh'} />
              </div>
            ) : (
              <>
                {this.state.activeTab === 'Buy'
                  ? this.buyForm()
                  : this.sellForm()}
                {!this.props.isDwSupported &&
                !this.props.isApexSupported &&
                this.state.activeTab === 'Buy' ? (
                  <p className='error'>
                    {this.props.t('notsupportedfortrading')}
                  </p>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingBottom: 20,
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {this.state.recurBuyStatus ? (
                      <Button
                        style={{
                          width: '50%',
                          borderRadius: 5,
                          background: '#3627e4',
                          color: 'white',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        onClick={() => {
                          this.setState({ showRecurringBuyDialog: true })
                        }}
                      >
                        {this.props.t('editrecurringbuy')}
                      </Button>
                    ) : (
                      <Button
                        style={{
                          width: '50%',
                          borderRadius: 5,
                          background: '#3627e4',
                          color: 'white',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        onClick={() => {
                          this.setState({ showRecurringBuyDialog: true })
                        }}
                        disabled={!this.state.usingAlpaca}
                      >
                        {this.props.t('setuprecurringbuy')}
                      </Button>
                    )}
                  </div>
                )}
              </>
            )}
          </>
          <Dialog
            visible={this.state.showPDTWarning}
            style={{
              width: this.props.isSmallScreen ? '100vw' : '50vw',
            }}
            onHide={() =>
              this.setState({
                showPDTWarning: false,
              })
            }
            header={'Pattern Day Trading (PDT)'}
            draggable={false}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
                paddingBottom: 20,
                width: '100%',
              }}
            >
              <p>
                This sell order might trigger a day trade. Review day trading
                rules to prevent being flagged.
              </p>
              <div
                style={{ display: 'flex', justifyContent: 'center', gap: 10 }}
              >
                <Button
                  onClick={() =>
                    this.setState({
                      showSummary: true,
                      showPDTWarning: false,
                    })
                  }
                >
                  Proceed to Sell
                </Button>
                <Button
                  onClick={() =>
                    this.setState({
                      showPDTWarning: false,
                    })
                  }
                >
                  Cancel Trade
                </Button>
              </div>
            </div>
          </Dialog>
          <Dialog
            visible={this.state.showPDTError}
            style={{
              width: this.props.isSmallScreen ? '100vw' : '50vw',
            }}
            onHide={() =>
              this.setState({
                showPDTError: false,
              })
            }
            header={'Pattern Day Trading Violation (PDT)'}
            draggable={false}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
                paddingBottom: 20,
                width: '100%',
              }}
            >
              <p>
                You are flagged as a Pattern Day Trader (PDT) and cannot execute
                this day trade.
              </p>
              <Button
                onClick={() =>
                  this.setState({
                    showPDTError: false,
                  })
                }
              >
                Okay
              </Button>
            </div>
          </Dialog>
          <ConfirmDialog
            visible={this.state.showConfirmationDialog}
            onHide={() => this.toggleShowConfirmationDialog()}
            message={<div className='gfv-message'>{this.state.gfvMessage}</div>}
            header={this.props.t('goodfaithviolationalert')}
            accept={this.tradeStockDW}
            style={{ width: this.props.isSmallScreen ? '90vw' : '30vw' }}
          />
          <RecurringBuyDialog
            accounts={this.props.allAccounts}
            selectedAccount={this.state.account}
            selectedEditRecurBuy={this.props.symbol}
            toggleEditModal={this.toggleEditModal}
            isSmallScreen={this.props.isSmallScreen}
            user={this.props.user}
            setRecBuy={this.setRecBuy}
            showEditModal={this.state.showRecurringBuyDialog}
            accountTransactionsData={this.state.accountTransactionsData}
            t={this.props.t}
            recurBuyStatus={this.state.recurBuyStatus}
          />
        </div>
      )
    } else return null
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  }
}

export default connect(mapStateToProps, null)(BuyOrSellForm)
