import React, { useEffect, useRef, useState } from 'react'
import { InputNumber } from 'primereact/inputnumber'

import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { AiOutlineBank, AiOutlineStock } from 'react-icons/ai'
import { MdAttachMoney } from 'react-icons/md'
import BackIcon from '../../../assets/product-activation-back-icon.svg'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import BaseURL from '../../Endpoint'
import axios from 'axios'
import '../../../scss/components/IBTransferFunds.scss'
import VerificationInput from 'react-verification-input'
import { RiBankCardLine } from 'react-icons/ri'
import { label } from 'aws-amplify'

// const WireWithdrawModal = ({ wireWithdrawModel, setWireWithdrawModel }) => {
//   return (
//     <Dialog
//       header='XXXX'
//       visible={wireWithdrawModel}
//       style={{ width: '50vw' }}
//       breakpoints={{ '1000px': '75vw' }}
//       onHide={() => setWireWithdrawModel(false)}
//     >
//       <p className='m-0' style={{ textAlign: 'center' }}>
//         wsadasdasd
//       </p>
//     </Dialog>
//   )
// }

// phone vertification and submit the withdraw request
const TransferVertificationModel = ({
  userInfo,
  transferModel,
  setTransferModel,
  ...props
}) => {
  const [transferStep, setTransferStep] = useState(0)
  const [isProcessed, setIsProcessed] = useState(false)
  const toastErrorMsg = useRef()

  // sending the code to the user registered phone number
  const sendOTPCode = async () => {
    setIsProcessed(true)
    try {
      let res = await axios.get(
        `${BaseURL}/search/send-otp?phone=${userInfo?.phone}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )

      if (res?.data?.status) {
        setIsProcessed(false)

        return true
      } else {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Send Code Failed',
          detail: 'Please double check your phone number',
          life: 3000,
        })

        setIsProcessed(false)

        return false
      }
    } catch (error) {
      console.log(error)
      setIsProcessed(false)
      return false
    }
  }

  // success model when finished phone vertify or deposit done depend on the is phone is vertified or not
  const SuccessModel = () => {
    return (
      <>
        <p className='success-text'>
          {userInfo?.phone_number_verified
            ? `${
                props?.type === 'deposit' ? 'Deposit' : 'Withdraw'
              } initiated successfully`
            : 'Phone number verified successfully'}
        </p>
        <Button
          label='OK'
          style={{ width: '100%', marginBottom: '1rem' }}
          onClick={() => {
            setTransferModel(false)
            props?.fetchUserInfo()
            setTimeout(() => {
              setTransferStep(0)
            }, 500)
          }}
        />
      </>
    )
  }

  // transfer step one component where send the code to phone number
  const Step1Comp = () => {
    return (
      <div className='step-one-container'>
        <p>We will send a 6 digit code to your phone number</p>

        <p className='phone-text'>{userInfo?.phone}</p>

        <Button
          style={{ width: '100%', marginBottom: '1rem' }}
          onClick={async () => {
            if (await sendOTPCode()) {
              setTransferStep((prev) => prev + 1)
            }
          }}
          label={isProcessed ? 'Processing' : 'Send Code'}
          icon={`${isProcessed ? 'pi pi-spin pi-spinner' : 'no-icon'}`}
          className='submit-button'
          disabled={isProcessed && true}
        />
      </div>
    )
  }

  // step two to vertify the code with phone number
  const Step2Comp = () => {
    const [code, setCode] = useState(null)

    // deposit api amount
    const depositAmt = async () => {
      let body = {
        account_id: props?.selectedAccount?.accountId,
        instruction_name: props?.selectedBankAcct,
        amount: props?.achAmount,
      }
      // call the deposit api
      try {
        let res = await axios.post(
          `${BaseURL}/ibkr/ach/deposit`,
          JSON.stringify(body),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
            },
          }
        )

        console.log('deposite', res)

        return true
      } catch (error) {
        console.log(error)
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Deposit Failed',
          detail: 'Deposit process failed. Please try again later',
          life: 3000,
        })
        return false
      }
    }

    //withdraw api amount
    const withdrawAmt = async () => {
      let body = {
        account_id: props?.selectedAccount?.accountId,
        instruction_name: props?.selectedBankAcct,
        amount: props?.achAmount,
      }
      // call the deposit api
      try {
        let res = await axios.post(
          `${BaseURL}/ibkr/ach/withdraw`,
          JSON.stringify(body),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
            },
          }
        )

        console.log('withdraw', res)

        return true
      } catch (error) {
        console.log(error)
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Withdrawal Failed',
          detail: 'Withdraw process failed. Please try again later',
          life: 3000,
        })
        return false
      }
    }

    // confirm with code
    const vertifyCode = async () => {
      setIsProcessed(true)

      // check if phone number is verified or not, if yes, no need for cognito verify, if no vertfied (which mean it is vertified screen)
      try {
        let res = await axios.get(
          `${BaseURL}/search/confirm-otp?phone=${
            userInfo?.phone
          }&code=${code}&verify=${userInfo?.phone_number_verified ? 0 : 1}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
            },
          }
        )

        console.log('vertify code', res)

        // if status code is 200 and vertified successfully
        if (res?.data?.status) {
          // the phone number is vertify and it is despoit action then in here you going to call deposite api
          if (userInfo.phone_number_verified) {
            if (props?.type === 'deposit') {
              if (await depositAmt()) {
                setIsProcessed(false)
                return true
              } else {
                setIsProcessed(false)
                return false
              }
            } else if (props?.type === 'withdraw') {
              if (await withdrawAmt()) {
                setIsProcessed(false)
                return true
              } else {
                setIsProcessed(false)
                return false
              }
            }
          } else {
            // it means it is vertified phone number, not do actual trade
            setIsProcessed(false)
            return true
          }
        } else {
          // code vertified failed
          setIsProcessed(false)
          toastErrorMsg.current.show({
            severity: 'error',
            summary: 'Phone Verification Failed',
            detail: 'Please make sure your verification code is valid',
            life: 3000,
          })
          return false
        }
      } catch (e) {
        console.log(e)
        setIsProcessed(false)
        return false
      }
    }

    return (
      <div className='step-two-container'>
        <p>A 6 digit code has been sent to:</p>
        <p className='phone-text'>{userInfo?.phone}</p>

        <div className='validation-code-container'>
          <VerificationInput
            onChange={(e) => {
              setCode(e)
            }}
            autoFocus
            placeholder=''
            validChars='0-9'
            removeDefaultStyles
            classNames={{
              container: 'container',
              character: 'character',
              characterInactive: 'character inactive',
              characterSelected: 'character selected',
            }}
          />
        </div>

        <Button
          style={{ width: '100%', marginBottom: '1rem' }}
          onClick={async () => {
            if (await vertifyCode()) {
              setTransferStep((prev) => prev + 1)
            }
          }}
          disabled={code?.length !== 6 || isProcessed}
          className='submit-button'
          label={isProcessed ? 'Processing' : 'Confirm OTP'}
          icon={`${isProcessed ? 'pi pi-spin pi-spinner' : 'no-icon'}`}
        />
      </div>
    )
  }

  return (
    <Dialog
      header={
        transferStep === 0
          ? 'Phone Verification'
          : transferStep === 1
          ? 'Enter Code'
          : 'Success'
      }
      visible={transferModel}
      style={{ width: '40vw' }}
      onHide={() => {
        if (userInfo?.phone_number_verified) {
          setTransferStep(0)
          setTransferModel(false)
        }
      }}
      className='transfer-model'
    >
      {/* {transferStep === 0 && <Step1Comp />} */}
      {transferStep === 1 && <Step2Comp />}
      {transferStep === 2 && <SuccessModel />}
      <Toast ref={toastErrorMsg} position='top-center' />
    </Dialog>
  )
}

// wire deposit instruction popup
const WireDepositInstructionModel = ({
  wireDepositModel,
  setWireDepositModel,
}) => {
  return (
    <Dialog
      header='Steps for wire deposit'
      visible={wireDepositModel}
      style={{ width: '50vw' }}
      breakpoints={{ '1000px': '75vw' }}
      onHide={() => setWireDepositModel(false)}
    >
      <p className='m-0'>1. Notify Investall of each deposit</p>
      <p className='m-0'>
        2. Contact your bank to send wire. No limit on amounts
      </p>
    </Dialog>
  )
}

const IBTransferFunds = (props) => {
  // console.log(props?.selectedAccount)
  // console.log(props?.user)

  const [addBankModel, setAddBankModel] = useState(false)
  const [acctPendingModel, setAcctPendingModel] = useState(false)
  const [acctRejectedModel, setAcctRejectedModel] = useState(false)
  const [confirmationModel, setConfirmationModel] = useState(false)
  const [transferModel, setTransferModel] = useState(false)
  const [wireDepositModel, setWireDepositModel] = useState(false)

  const [linkedAccounts, setLinkedAccounts] = useState([])
  const [bankAccounts, setBankAccounts] = useState([])
  const [bankDict, setBankDict] = useState({})
  const [linkedAccountInfo, setLinkedAccountInfo] = useState(null)
  const [userInfo, setUserInfo] = useState(null)

  //despoite variables
  const [achAmount, setAchAmount] = useState('')
  const [selectedBankAcct, setSelectedBankAcct] = useState(null)
  const [selectedBankInfo, setSelectedBankInfo] = useState(null)
  const [selectedExternalAcct, setSelectedExternalAcct] = useState('')

  const [showChangeACHAccountModal, setShowChangeACHAccountModal] =
    useState(false)

  const [callingExternalLinkAPI, setCallingExternalLinkAPI] = useState(false)

  const toastErrorMsg = useRef()

  useEffect(() => {
    fetchAllBankAccts()
    fetchUserInfo()
  }, [])

  useEffect(() => {
    if (linkedAccounts.length > 0) {
      setSelectedBankAcct(linkedAccounts?.[0]?.items[0]?.value ?? null)
    }
  }, [linkedAccounts])

  useEffect(() => {
    // phone number vertify
    console.log(
      'userInfo?.phone_number_verified',
      userInfo?.phone_number_verified
    )

    // if (userInfo) {
    //   if (!userInfo?.phone_number_verified) {
    //     setTransferModel(true)
    //   }
    // }
  }, [userInfo])

  const createACHWithPlaidAccount = async () => {
    try {
      console.log(selectedExternalAcct)
      setCallingExternalLinkAPI(true)
      const linkPlaid = await linkExternalAccount(selectedExternalAcct)
      if (linkPlaid === false) {
        toastErrorMsg?.current?.show({
          severity: 'error',
          summary: 'External Account not linked',
          detail:
            'There was an issue when trying to link this account. Please try again.',
          life: 3000,
        })
        return false
      }
      await fetchAllBankAccts()
      toastErrorMsg?.current?.show({
        severity: 'success',
        summary: 'External Account Linked',
        detail:
          'Your account has been linked and will be avilable for transfer shortly',
        life: 3000,
      })
    } catch (error) {
      console.log('error', error)
      toastErrorMsg?.current?.show({
        severity: 'error',
        summary: 'External Account not linked',
        detail:
          'There was an issue when trying to link this account. Please try again.',
        life: 3000,
      })
      return false
    } finally {
      setShowChangeACHAccountModal(false)
      setCallingExternalLinkAPI(false)
    }
  }

  const linkExternalAccount = async (plaid_account) => {
    try {
      if (!plaid_account) {
        return false
      }
      const body = {
        account_id: props.selectedAccount?.accountId,
        plaid_account_id: plaid_account,
      }
      let { data } = await axios.post(
        BaseURL + '/mobile/alpaca/ach-relationship/plaid/create',
        body,
        {
          headers: {
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )
      console.log('data', data)
      return true
    } catch (error) {
      console.log('plaid ach link error', error)
      return false
    }
  }

  const getAlpacaACHAccounts = async () => {
    try {
      let res = await axios.get(
        BaseURL +
          '/mobile/alpaca/ach-relationship/list?account_id=' +
          props.selectedAccount?.accountId,
        {
          headers: {
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )
      console.log('ach list res', res?.data?.output)
      return res?.data?.output
    } catch (error) {
      console.error(error)
      return []
    }
  }

  // get all bank information
  const fetchAllBankAccts = async () => {
    try {
      // let res = await axios.get(
      //   `${BaseURL}/mobile/ib/fetch-fund-accounts?id=${props?.selectedAccount?.accountId}`,
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
      //     },
      //   }
      // )
      let alpaca_bank_accounts = await getAlpacaACHAccounts()

      console.log('linked account', alpaca_bank_accounts)
      const plaid_accounts = props?.accounts?.filter((acc) => {
        if (acc.type === 'depository')
          return {
            ...acc,
            nickname: acc?.name + '-' + acc?.mask,
          }
        return null
      })
      console.log('plaid_accounts', plaid_accounts)
      // let template = [
      //   {
      //     label: 'Approved',
      //     code: 'APPROVED',
      //     items: [],
      //   },
      //   {
      //     label: 'Rejected',
      //     code: 'REJECTED',
      //     items: [],
      //   },
      //   {
      //     label: 'Pending',
      //     code: 'PENDING',
      //     items: [],
      //   },
      // ]

      let template = [
        {
          label: 'Linked',
          code: 'LINKED',
          items: [],
        },
        {
          label: 'External',
          code: 'EXTERNAL',
          items: [],
        },
      ]

      // if the account id has no account, then just make it empty
      let bank_accounts = []
      let bank_dict = {}
      if (alpaca_bank_accounts?.length > 0) {
        alpaca_bank_accounts?.forEach((acc) => {
          template[0].items.push({
            label: acc.nickname,
            value: acc.id,
            status: acc.status,
            groupLabel: 'Linked',
          })
        })
        // alpaca_bank_accounts?.forEach((item) => {
        //   const { status } = item
        //   const indexNumber = template?.findIndex(
        //     (group) => group.code === status
        //   )

        //   template[indexNumber]?.items?.push({
        //     label: item.nickname,
        //     value: item.bank_account_number,
        //     acctStatus: item.status,
        //   })
        //   if (item.status === 'QUEUED') item.status = 'PENDING'
        //   bank_accounts.push({
        //     label: item.nickname,
        //     value: item.id,
        //     status: item.status,
        //   })
        //   bank_dict[item.id] = item
        // })

        // setGroupedBankAccts(template)
        setBankAccounts(bank_accounts)
        setBankDict(bank_dict)
        setLinkedAccountInfo(template[0].items[0])
      }
      if (plaid_accounts?.length > 0) {
        plaid_accounts.forEach((acc) => {
          template[1].items.push({
            label: acc.name + '-' + acc?.mask,
            value: acc.accountId,
            status: '',
            groupLabel: 'External',
          })
        })
      }
      console.log('template', template)
      if (alpaca_bank_accounts.length === 0 && plaid_accounts.length === 0) {
        setLinkedAccounts([])
      } else {
        setLinkedAccounts(template)
      }
      return
    } catch (error) {
      console.log(error)
      return
    }
  }

  // fetch user infomation (eg. email, phone...)
  const fetchUserInfo = async () => {
    try {
      let res = await axios.get(`${BaseURL}/mobile/get-email`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      console.log(res?.data)

      setUserInfo(res?.data)

      return
    } catch (error) {
      console.log(error)
      return
    }
  }

  // add bank infomation model
  const AddBankModel = ({ mainToastErrorMsg }) => {
    const [bankInfo, setBankInfo] = useState({
      nickname: '',
    })
    const [accountType, setAccountType] = useState('CHECKING')
    const [isProcessed, setIsProcessed] = useState(false)

    const bankToastErrorMsg = useRef()

    const addNewBankAcct = async () => {
      setIsProcessed(true)
      let body = {
        nickname: bankInfo?.nickname,
        account_owner_name: bankInfo?.bank_name,
        bank_routing_number: bankInfo?.bank_routing,
        bank_account_number: bankInfo?.bank_acct?.toString(),
        bank_account_type: accountType,
      }
      console.log('body', body)

      try {
        let res = await axios.post(
          // `${BaseURL}/ibkr/ach-instructions`,
          `${BaseURL}/mobile/alpaca/ach-relationship/create?account_id=` +
            props?.selectedAccount?.accountId,
          JSON.stringify(body),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
            },
          }
        )
        console.log('res', res)
        if (res?.data?.code) {
          throw new Error(res?.data?.message ?? 'Error occurred')
        }
        await fetchAllBankAccts()
        // to manually clear, since using {} not all will be cleared
        setBankInfo({
          nickname: '',
          bank_name: '',
        })
        console.log('mainToastErrorMsg', mainToastErrorMsg.current)
        mainToastErrorMsg.current.show({
          severity: 'success',
          summary: 'Completed',
          detail: 'New bank account added successfully',
          life: 3000,
        })
        setIsProcessed(false)
        setAddBankModel(false)
      } catch (error) {
        console.log(error)
        bankToastErrorMsg.current.show({
          severity: 'error',
          summary: 'Error',
          detail: error?.message,
          life: 3000,
        })
        setIsProcessed(false)
      }
    }

    const validateForm = () => {
      console.log(bankInfo.bank_routing?.toString()?.length)

      if (!bankInfo.nickname?.trim()) {
        bankToastErrorMsg.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Nickname is empty',
          life: 3000,
        })
        return false
      }
      if (!bankInfo.bank_acct) {
        bankToastErrorMsg.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Bank account number is empty',
          life: 3000,
        })

        return false
      } else if (
        bankInfo.bank_acct?.toString()?.length > 17 ||
        bankInfo.bank_acct?.toString()?.length < 5
      ) {
        bankToastErrorMsg.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Bank account number is invalid',
          life: 3000,
        })
        return false
      }

      if (!bankInfo.bank_routing) {
        bankToastErrorMsg.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Bank routing number is empty',
          life: 3000,
        })
        return false
      } else if (bankInfo.bank_routing?.toString()?.length !== 9) {
        bankToastErrorMsg.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Bank routing number is invalid',
          life: 3000,
        })
        return false
      } else {
        let num_regex = /^[0-9]+$/
        if (!num_regex.test(bankInfo.bank_routing)) {
          bankToastErrorMsg.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Bank routing number format is not correct',
            life: 3000,
          })
          return false
        }
      }

      if (!bankInfo.bank_name?.trim()) {
        bankToastErrorMsg.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Bank name is empty',
          life: 3000,
        })
        return false
      }

      return true
    }

    const handleSubmit = async () => {
      if (validateForm()) {
        // call store api
        if (bankAccounts?.length > 0) {
          let unlink_status = await unlinkBankAccount()
          if (unlink_status) {
            console.log('Can add new bank account now 1')
            await addNewBankAcct()
            return true
          } else {
            return false
          }
        }
        console.log('Can add new bank account now 2')
        await addNewBankAcct()
        return true
      } else {
        return false
      }
    }

    const handleFormOnChange = (event) => {
      const { name, value } = event.target

      setBankInfo((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }))
    }

    const footerContent = (
      <div>
        {/* <Button
          label='No'
          icon='pi pi-times'
          onClick={() => setVisible(false)}
          className='p-button-text'
        /> */}
        <Button
          label={isProcessed ? 'Processing' : 'Confirm'}
          icon={`pi ${isProcessed ? 'pi-spin pi-spinner' : 'pi-check'}`}
          // onClick={() => setAddBankModel(false)}
          autoFocus
          onClick={() => handleSubmit()}
          disabled={isProcessed && true}
          className='add-bank-submit-button'
        />
      </div>
    )

    return (
      <Dialog
        header={'Add Bank Account'}
        visible={addBankModel}
        style={{ width: '50vw' }}
        breakpoints={{ '1000px': '75vw' }}
        onHide={() => (isProcessed ? {} : setAddBankModel(false))}
        footer={footerContent}
        className='add-bank-account-model'
      >
        <p>
          Please fill all the information below to add bank account for deposit
          or withdrawal.
        </p>

        <div className='radios-container'>
          <div className='single-radio-button'>
            <RadioButton
              inputId='checkingType'
              name='checking'
              value='CHECKING'
              onChange={(e) => setAccountType(e.value)}
              checked={accountType === 'CHECKING'}
              disabled={isProcessed && true}
            />
            <label htmlFor='checkingType' className='ml-2'>
              Checking
            </label>
          </div>
          <div className='single-radio-button'>
            <RadioButton
              inputId='savingType'
              name='savings'
              value='SAVINGS'
              onChange={(e) => setAccountType(e.value)}
              checked={accountType === 'SAVINGS'}
              disabled={isProcessed && true}
            />
            <label htmlFor='savingType' className='ml-2'>
              Saving
            </label>
          </div>
        </div>

        <div className='input-container'>
          <label>Nickname</label>
          <InputText
            value={bankInfo?.nickname}
            name='nickname'
            onChange={handleFormOnChange}
            placeholder='Nickname'
            disabled={isProcessed && true}
          />
        </div>

        <div className='input-container'>
          <label>Bank Account Number</label>
          <InputNumber
            value={bankInfo?.bank_acct}
            name='bank_acct'
            onValueChange={handleFormOnChange}
            // onChange={(e) => checkingBankNumberLength(e)}
            placeholder='Account number'
            useGrouping={false}
            disabled={isProcessed && true}
          />
        </div>

        <div className='input-container'>
          <label>Account Owner</label>
          <InputText
            value={bankInfo?.bank_name}
            name='bank_name'
            onChange={handleFormOnChange}
            placeholder='Name'
            disabled={isProcessed && true}
          />
        </div>

        <div className='input-container'>
          <label>Bank Routing Number</label>
          <InputText
            value={bankInfo?.bank_routing}
            name='bank_routing'
            onChange={handleFormOnChange}
            // onChange={(e) => checkingBankNumberLength(e)}
            placeholder='Routing number'
            useGrouping={false}
            disabled={isProcessed && true}
          />
        </div>
        <Toast ref={bankToastErrorMsg} position='top-center' />
      </Dialog>
    )
  }

  const handleACHTranscation = async (
    transaction_type,
    amount,
    relationship_id,
    toastErrorMsg
  ) => {
    console.log(toastErrorMsg.current)
    try {
      if (transaction_type !== 'deposit' && transaction_type !== 'withdraw') {
        throw new Error('Transaction type not valid')
      }
      let body = {
        relationship_id,
        amount,
      }
      console.log('-=-=- DEBUG', props?.selectedAccount?.accountId, body)
      let url = ''
      if (transaction_type === 'deposit') {
        url =
          BaseURL +
          `/mobile/alpaca/ach-relationship/deposit?account_id=` +
          props?.selectedAccount?.accountId
      } else {
        url =
          BaseURL +
          `/mobile/alpaca/ach-relationship/withdraw?account_id=` +
          props?.selectedAccount?.accountId
      }
      let res = await axios.post(url, body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })
      console.log('-=-=- DEBUG 2', res)
      if (res?.data?.code) {
        toastErrorMsg?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: res?.data?.message ?? 'Error occurred',
          life: 3000,
        })
      } else {
        toastErrorMsg?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail:
            transaction_type.charAt(0).toUpperCase() +
            transaction_type.slice(1) +
            ' successful.',
          life: 3000,
        })
      }
      setConfirmationModel(false)
    } catch (error) {
      console.error(error)
      toastErrorMsg?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail:
          'Error when trying to submit ' + transaction_type + '. Try again.',
        life: 3000,
      })
      setConfirmationModel(false)
    }
  }

  // confirmation model
  const FundsConfirmationModel = ({ toastErrorMsg }) => {
    return (
      <Dialog
        header={`${
          props?.type === 'deposit' ? 'Deposit Details' : 'Withdrawal Details'
        }`}
        visible={confirmationModel}
        style={{ width: '50vw' }}
        breakpoints={{ '1000px': '75vw' }}
        onHide={() => setConfirmationModel(false)}
        className='confirm-model'
      >
        <div className='confirm-container'>
          <p>
            {props?.type === 'deposit' ? 'Deposit' : 'Withdrawal'} amount:{' '}
            <span>${achAmount}</span>
          </p>
          <p>
            From:{' '}
            <span>
              {props?.type === 'deposit'
                ? linkedAccountInfo?.label
                : props?.selectedAccount?.product_assigned}
            </span>
          </p>
          <p>
            To:{' '}
            <span>
              {' '}
              {props?.type === 'deposit'
                ? props?.selectedAccount?.product_assigned
                : linkedAccountInfo?.label}{' '}
              Account
            </span>
          </p>
        </div>

        <div className='text-container'>
          You will {props?.type === 'deposit' ? 'deposit' : 'withdraw'} $
          {achAmount} from{' '}
          {props?.type === 'deposit'
            ? linkedAccountInfo?.label
            : props?.selectedAccount?.product_assigned}{' '}
          account to{' '}
          {props?.type === 'deposit'
            ? props?.selectedAccount?.product_assigned
            : linkedAccountInfo?.label}{' '}
          account. It may take up to 5 business days for the transfer to get
          settled.
        </div>

        <div>
          <Button
            label='Confirm'
            onClick={() => {
              console.log(
                'call methods for deposit and withdraws',
                selectedBankAcct,
                achAmount,
                props?.type
              )
              handleACHTranscation(
                props?.type,
                achAmount,
                selectedBankAcct,
                toastErrorMsg
              )
            }}
            style={{ width: '100%', marginBottom: '1rem' }}
          />
        </div>
      </Dialog>
    )
  }

  // reject error model
  const AcctRejectedModel = () => {
    return (
      <Dialog
        header='Bank Account Rejected'
        visible={acctRejectedModel}
        style={{ width: '50vw' }}
        breakpoints={{ '1000px': '75vw' }}
        onHide={() => setAcctRejectedModel(false)}
      >
        <p className='m-0' style={{ textAlign: 'center' }}>
          The bank account you added has been rejected due to incorrect
          information. Please add bank account valid information
        </p>
      </Dialog>
    )
  }

  // pending error model
  const AcctPendingModel = () => {
    return (
      <Dialog
        header='Bank Account Pending'
        visible={acctPendingModel}
        style={{ width: '50vw' }}
        breakpoints={{ '1000px': '75vw' }}
        onHide={() => setAcctPendingModel(false)}
      >
        <p className='m-0' style={{ textAlign: 'center' }}>
          The bank account you added is pending approval. Please try again
          later.
        </p>
      </Dialog>
    )
  }

  // the group header for the accounts user has by 3 category (here is, approve, rejected, pending)
  // const groupedItemTemplate = (option) => {
  //   return (
  //     <div
  //       className={`group-header-container ${
  //         option.code === 'APPROVED' && 'approved'
  //       } ${option.code === 'REJECTED' && 'rejected'} ${
  //         option.code === 'PENDING' && 'pending'
  //       }`}
  //     >
  //       <i
  //         className={`pi ${option.code === 'APPROVED' && 'pi-check-circle'} ${
  //           option.code === 'REJECTED' && 'pi-times-circle'
  //         } ${option.code === 'PENDING' && 'pi-clock'}`}
  //       ></i>
  //       <div>{option.label}</div>
  //     </div>
  //   )
  // }

  const AccountItemTemplate = (option) => {
    return (
      <div className='bank-account-template-row'>
        <p>{option.label}</p>
        <p>{option.status}</p>
      </div>
    )
  }

  // valdiate the form for ach depsoit and withdraw
  const validatePrimaryForm = () => {
    if (!achAmount || !selectedBankAcct) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please make sure all fields are filled and valid',
        life: 3000,
      })

      return false
    }

    if (achAmount === 0) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter a valid amount',
        life: 3000,
      })
      return false
    }

    console.log('linkedAccountInfo', linkedAccountInfo)
    if (linkedAccountInfo?.status !== 'APPROVED') {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Your account is not ready yet. Please try again later.',
        life: 3000,
      })
      return false
    }

    // let index = allAccountsInfo?.findIndex(
    //   (acct) => acct.id === selectedBankAcct
    // )

    // if (allAccountsInfo[index].status === 'PENDING') {
    //   setAcctPendingModel(true)
    //   return false
    // } else if (allAccountsInfo[index].status === 'REJECTED') {
    //   setAcctRejectedModel(true)
    //   return false
    // }
    // setSelectedBankInfo(allAccountsInfo[index])

    if (props?.type === 'withdraw') {
      if (achAmount > props?.selectedAccount?.cash_account) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Withdrawal amount must not exceed available balance',
          life: 3000,
        })

        return false
      }
    }

    return true
  }

  // ach deposit submit form action by vertify and go next phase
  const continuePage = () => {
    if (validatePrimaryForm()) {
      setConfirmationModel(true)
    }

    return false
  }

  // dynamic show deposit block based on ACH or WIRE way
  const DepositOptionsBlock = () => {
    if (props?.option === 1) {
      // ach option

      return <ACHDeposit />
    } else if (props?.option === 2) {
      // wire option
      return <WireDeposit />
    }
  }

  const unlinkBankAccount = async () => {
    try {
      console.log('unlink ach account', props?.selectedAccount?.accountId)
      toastErrorMsg.current.show({
        severity: 'success',
        summary: 'ACH unlinking',
        detail: 'Your bank account has been unlinked',
        life: 3000,
      })
      return true
    } catch (error) {
      console.log('unlink error', error)
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'ACH unlinking error',
        detail:
          'There was an issue when trying to unlink your ACH account. Refresh the page and try again.',
        life: 3000,
      })
      return false
    }
  }

  // ach deposit component
  const ACHDeposit = () => {
    // console.log(selectedBankAcct)

    return (
      <div className='content-container'>
        <div className='header-container'>
          <img
            src={BackIcon}
            height={25}
            width={25}
            onClick={() => props?.goBack()}
            style={{ cursor: 'pointer' }}
            alt=''
          />
        </div>
        <div className='deposit-input-container'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
            }}
          >
            <label>From</label>
            <div className=''>
              {bankAccounts.length > 0 ? (
                <>
                  <Button
                    label='Edit Bank Account'
                    className='p-button-info p-button-text'
                    onClick={() => setAddBankModel(true)}
                  />
                  <Button
                    label='Unlink Bank Account'
                    className='p-button-info p-button-text'
                    onClick={() => unlinkBankAccount()}
                  />
                </>
              ) : (
                <Button
                  label='Add Bank Account'
                  className='p-button-info p-button-text'
                  onClick={() => setAddBankModel(true)}
                />
              )}
            </div>
          </div>
          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon'>
              <AiOutlineBank />
            </span>
            <Dropdown
              value={selectedBankAcct}
              onChange={(e) => {
                const selectedValue = e.value
                const selectedItem = linkedAccounts
                  .flatMap((group) => group.items)
                  .find((item) => item.value === selectedValue)

                const selectedGroup =
                  selectedItem?.groupLabel || 'Unknown group'

                console.log(`Selected value: ${selectedValue}`)
                console.log(`Group: ${selectedGroup}`)
                if (selectedGroup === 'Linked') {
                  setSelectedBankAcct(selectedValue)
                } else {
                  setSelectedExternalAcct(selectedValue)
                  setShowChangeACHAccountModal(true)
                }
              }}
              optionGroupLabel='label'
              optionGroupChildren='items'
              options={linkedAccounts}
              itemTemplate={AccountItemTemplate}
              placeholder='Select a bank account'
            />
          </div>
        </div>
        <div className='deposit-input-container'>
          <label>To</label>

          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon' style={{ border: 'none' }}>
              <AiOutlineStock />
            </span>
            <InputText
              value={props?.selectedAccount?.product_assigned}
              disabled={true}
            />
          </div>
        </div>
        <div className='deposit-input-container'>
          <label>Amount</label>

          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon'>
              <MdAttachMoney />
            </span>
            <InputNumber
              placeholder='Enter the amount'
              value={achAmount === '' ? null : achAmount}
              onValueChange={(e) => setAchAmount(e.value)}
              min={0}
            />
          </div>
        </div>

        <div>
          <Button
            label={'Make Deposit'}
            onClick={() => continuePage()}
            style={{ width: '100%', padding: '1rem' }}
            // disabled={props?.accountType === 'Inactive'}
          />
        </div>
      </div>
    )
  }

  // wire deposit component

  const WireDeposit = () => {
    //wire variables
    const [bankName, setBankName] = useState('')
    const [bankAcctNumber, setBankAcctNumber] = useState('')
    const [bankAcctNickname, setBankAcctNickname] = useState('')
    const [wireDepositAmount, setWireDepositAmount] = useState('')

    const [wireDepositStep, setWireDepositStep] = useState(0)
    const [wireDepositDetails, setWireDepositDetails] = useState(null)

    const [isLoadingData, setIsLoadingData] = useState(false)

    // get the wire information api
    const fetchWireInstructionData = async () => {
      setIsLoadingData(true)

      let body = {
        amount: wireDepositAmount,
        account_id: props?.selectedAccount?.accountId,
        bank_account_number: bankAcctNumber,
        bank_name: bankName,
      }

      try {
        let res = await axios.post(
          `${BaseURL}/ibkr/wire-instructions`,
          JSON.stringify(body),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
            },
          }
        )
        console.log('response: ', res?.data)
        setWireDepositDetails(res?.data)
        setIsLoadingData(false)
      } catch (e) {
        console.log(e)
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Form Invalid',
          detail: 'Please make sure all the fields are valid',
          life: 3000,
        })
        setIsLoadingData(false)
      }
    }

    // validate the form for wire deposit
    const validateWireDepositForm = () => {
      if (!bankName || !bankAcctNumber || !wireDepositAmount) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Form Incomplete',
          detail: 'Please make sure all the fields are valid and filled',
          life: 3000,
        })

        return false
      }

      return true
    }

    // validate the wire deposit form for wire deposit and submit
    const submitForm = async () => {
      if (validateWireDepositForm()) {
        await fetchWireInstructionData()
        setWireDepositStep(1)
      }
    }

    return (
      <>
        {wireDepositStep === 0 && (
          <div className='content-container'>
            <div className='header-container'>
              <img
                src={BackIcon}
                height={25}
                width={25}
                onClick={() => props?.goBack()}
                style={{ cursor: 'pointer' }}
              />
              <Button
                label='Wire Instructions'
                className='p-button-info p-button-text'
                onClick={() => setWireDepositModel(true)}
              />
            </div>
            <div className='deposit-input-container'>
              <label>Bank Name / Institution</label>
              <div className='p-inputgroup'>
                <span className='p-inputgroup-addon'>
                  <AiOutlineBank />
                </span>
                <InputText
                  placeholder='Enter your bank name'
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  disabled={isLoadingData === true}
                />
              </div>
            </div>
            <div className='deposit-input-container'>
              <label>Bank Account Number</label>

              <div className='p-inputgroup'>
                <span className='p-inputgroup-addon'>
                  <RiBankCardLine />
                </span>
                <InputText
                  placeholder='Enter account number'
                  value={bankAcctNumber}
                  onChange={(e) => setBankAcctNumber(e.target.value)}
                  disabled={isLoadingData === true}
                />
              </div>
            </div>
            {/* <div className='deposit-input-container'>
          <label>Account Nickname</label>

          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon'>
              <MdAttachMoney />
            </span>
            <InputText
              placeholder='Enter account nickname'
              value={bankAcctNickname}
              onChange={(e) => setBankAcctNickname(e.value)}
            />
          </div>
        </div> */}

            <div className='wire-deposit-text-container'>
              <p>
                All fields are mandatory. Please provide accurate information to
                ensure proper matching of your deposit notification with the
                actual funds deposited.
              </p>
              <p>I will be sending the following amount:</p>
            </div>
            <div className='deposit-input-container'>
              <label>Deposit Amount</label>

              <div className='p-inputgroup'>
                <span className='p-inputgroup-addon'>
                  <MdAttachMoney />
                </span>
                <InputNumber
                  placeholder='Enter the amount'
                  value={wireDepositAmount === '' ? null : wireDepositAmount}
                  onValueChange={(e) => setWireDepositAmount(e.value)}
                  min={0}
                  disabled={isLoadingData === true}
                />
              </div>
            </div>

            <Button
              label={isLoadingData ? 'Processing' : 'Get Wire Instructions'}
              icon={`${isLoadingData ? 'pi pi-spin pi-spinner' : 'no-icon'}`}
              onClick={() => submitForm()}
              style={{ width: '100%', padding: '1rem' }}
              disabled={props?.accountType === 'Inactive' || isLoadingData}
              className='wire-deposit-button'
            />
          </div>
        )}

        {wireDepositStep === 1 && (
          <div className='content-container'>
            <div className='header-container'>
              <img
                src={BackIcon}
                height={25}
                width={25}
                onClick={() => props?.goBack()}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className='basic-wire-deposit-container'>
              <p>Bank Wire Instructions</p>

              <div className='wire-deposit-content'>
                <p className='wire-deposit-subtitle'>
                  Provide the following information to your bank to initiate the
                  transfer
                </p>
                <p className='wire-deposit-info'>
                  Please note that the below wire instructions can only be used
                  for wire transfers
                </p>
              </div>

              <div className='wire-deposit-content'>
                <p className='wire-deposit-subtitle'>Wire Funds to</p>
                <p className='wire-deposit-info'>
                  {wireDepositDetails?.payload?.wireDetails?.bankName} <br />
                  {
                    wireDepositDetails?.payload?.wireDetails?.bankAddress
                      ?.addressLine1
                  }
                  <br />
                  {`${wireDepositDetails?.payload?.wireDetails?.bankAddress?.city}, ${wireDepositDetails?.payload?.wireDetails?.bankAddress?.stateProvince} ${wireDepositDetails?.payload?.wireDetails?.bankAddress?.zipPostalCode}`}
                  <br />
                  {
                    wireDepositDetails?.payload?.wireDetails?.bankAddress
                      ?.country
                  }
                  <br />
                </p>
              </div>

              <div className='wire-deposit-content'>
                <p className='wire-deposit-subtitle'>ABA Routing Number</p>
                <p className='wire-deposit-info'>
                  {wireDepositDetails?.payload?.wireDetails?.bankRoutingNumber}
                </p>
              </div>

              <div className='wire-deposit-content'>
                <p className='wire-deposit-subtitle'>SWIFT/BIC Code</p>
                <p className='wire-deposit-info'>
                  {wireDepositDetails?.payload?.wireDetails?.bankSwiftCode}
                </p>
              </div>

              <div className='wire-deposit-content'>
                <p className='wire-deposit-subtitle'>
                  Bank Account Title & Address
                </p>
                <p className='wire-deposit-info'>
                  {wireDepositDetails?.payload?.wireDetails?.beneficiaryName}
                  <br />
                  {
                    wireDepositDetails?.payload?.wireDetails?.beneficiaryAddress
                      ?.addressLine1
                  }
                  <br />
                  {`${wireDepositDetails?.payload?.wireDetails?.beneficiaryAddress?.city}, ${wireDepositDetails?.payload?.wireDetails?.beneficiaryAddress?.stateProvince} ${wireDepositDetails?.payload?.wireDetails?.beneficiaryAddress?.zipPostalCode}`}
                  <br />

                  {
                    wireDepositDetails?.payload?.wireDetails?.beneficiaryAddress
                      ?.country
                  }
                  <br />
                </p>
              </div>

              <div className='wire-deposit-content'>
                <p className='wire-deposit-subtitle'>Bank Account Number</p>
                <p className='wire-deposit-info'>
                  {wireDepositDetails?.payload?.wireDetails?.bankAccountNumber}
                </p>
                <p className='wire-deposit-subInfo'>
                  {`${wireDepositDetails?.payload.wireDetails?.virtualaccountID} is a virtual
                  account number (VAN) assigned to you.`}
                  <br /> <br />
                  {`The VAN combines our ${
                    wireDepositDetails?.payload?.wireDetails?.bankName
                  } bank account number ${wireDepositDetails?.payload?.wireDetails?.bankAccountNumber?.replace(
                    wireDepositDetails?.payload?.accountNo,
                    ''
                  )} with your account
                  number ${
                    wireDepositDetails?.payload?.accountNo
                  }. If your banking platform does
                  not allow letters in the destination bank account, please send
                  the funds to ${
                    wireDepositDetails?.payload?.wireDetails?.bankName
                  } account ${wireDepositDetails?.payload?.wireDetails?.bankAccountNumber?.replace(
                    wireDepositDetails?.payload?.accountNo,
                    ''
                  )} and include your
                  IBKR account ${
                    wireDepositDetails?.payload?.accountNo
                  } in the 'Payment
                  Reference/For Further Credit' field in your bank’s platform.`}
                </p>
              </div>

              <div className='wire-deposit-content'>
                <p className='wire-deposit-subtitle'>
                  Payment Reference/For Further Credit to
                </p>
                <p className='wire-deposit-info'>
                  {wireDepositDetails?.payload?.accountNo}
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  // dynamic display withdraw block either ach withdraw or wire withdraw
  const WithdrawOptionsBlock = () => {
    if (props?.option === 1) {
      // ach option
      return <ACHWithdraw />
    } else if (props?.option === 2) {
      // wire option
      return <WireWithdraw />
    }
  }

  // ach withdraw component
  const ACHWithdraw = () => {
    console.log(selectedBankAcct)

    return (
      <div className='content-container'>
        <div className='header-container'>
          <img
            src={BackIcon}
            height={25}
            width={25}
            onClick={() => props?.goBack()}
            style={{ cursor: 'pointer' }}
          />
          <div>
            {bankAccounts.length > 0 ? (
              <>
                <Button
                  label='Edit Bank Account'
                  className='p-button-info p-button-text'
                  onClick={() => setAddBankModel(true)}
                />
                <Button
                  label='Unlink Bank Account'
                  className='p-button-info p-button-text'
                  onClick={() => unlinkBankAccount()}
                />
              </>
            ) : (
              <Button
                label='Add Bank Account'
                className='p-button-info p-button-text'
                onClick={() => setAddBankModel(true)}
              />
            )}
          </div>
        </div>
        <div className='deposit-input-container'>
          <label>From</label>

          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon' style={{ border: 'none' }}>
              <AiOutlineStock />
            </span>
            <InputText
              value={`Available to withdraw: $${props?.selectedAccount?.cash_account} (${props?.accountType}) `}
              disabled={true}
            />
          </div>
        </div>
        <div className='deposit-input-container'>
          <label>To</label>
          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon'>
              <AiOutlineBank />
            </span>
            <Dropdown
              value={selectedBankAcct}
              onChange={(e) => {
                const selectedValue = e.value
                const selectedItem = linkedAccounts
                  .flatMap((group) => group.items)
                  .find((item) => item.value === selectedValue)

                const selectedGroup =
                  selectedItem?.groupLabel || 'Unknown group'

                console.log(`Selected value: ${selectedValue}`)
                console.log(`Group: ${selectedGroup}`)
                if (selectedGroup === 'Linked') {
                  setSelectedBankAcct(selectedValue)
                } else {
                  setSelectedExternalAcct(selectedValue)
                  setShowChangeACHAccountModal(true)
                }
              }}
              optionGroupLabel='label'
              optionGroupChildren='items'
              options={linkedAccounts}
              itemTemplate={AccountItemTemplate}
              placeholder='Select a bank account'
            />
          </div>
        </div>
        <div className='deposit-input-container'>
          <label>Amount</label>

          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon'>
              <MdAttachMoney />
            </span>
            <InputNumber
              placeholder='Enter the amount'
              value={achAmount === '' ? null : achAmount}
              onValueChange={(e) => setAchAmount(e.value)}
              min={0}
            />
          </div>
        </div>
        {/* <div className='message-container' style={{ width: '100%' }}>
          <p className='message'>{props?.t('youcanonlydeposit5000perday')}</p>
        </div> */}

        <div>
          <Button
            label={'Make Withdraw'}
            onClick={() => continuePage()}
            style={{ width: '100%', padding: '1rem' }}
            disabled={props?.accountType === 'Inactive'}
          />
        </div>
      </div>
    )
  }

  // const [wireWithdrawModel, setWireWithdrawModel] = useState(true)

  // wire withdraw component
  const WireWithdraw = () => {
    // useEffect(() => {
    //   if (wireWithdrawModel) {
    //     setWireWithdrawModel(true)
    //   }
    // }, [wireWithdrawModel])
    // return (
    //   <div>
    //     {wireWithdrawModel && (
    //       <WireWithdrawModal
    //         wireWithdrawModel={wireWithdrawModel}
    //         setWireWithdrawModel={setWireWithdrawModel}
    //       />
    //     )}
    //   </div>
    // )
    return <div>withdraw</div>
  }

  return (
    <>
      {props?.type === 'deposit' && <DepositOptionsBlock />}
      {props?.type === 'withdraw' && <WithdrawOptionsBlock />}
      {addBankModel && <AddBankModel mainToastErrorMsg={toastErrorMsg} />}
      <AcctRejectedModel />
      <AcctPendingModel />
      <FundsConfirmationModel toastErrorMsg={toastErrorMsg} />
      <TransferVertificationModel
        userInfo={userInfo}
        user={props?.user}
        transferModel={transferModel}
        setTransferModel={setTransferModel}
        selectedAccount={props?.selectedAccount}
        achAmount={achAmount}
        selectedBankAcct={selectedBankAcct}
        selectedBankInfo={selectedBankInfo}
        fetchUserInfo={fetchUserInfo}
        type={props?.type}
      />
      <WireDepositInstructionModel
        wireDepositModel={wireDepositModel}
        setWireDepositModel={setWireDepositModel}
      />
      <Dialog
        visible={showChangeACHAccountModal}
        onHide={() => setShowChangeACHAccountModal(false)}
        className='link-external-account-dialog'
        draggable={false}
        header={'Link External Account'}
      >
        <p>
          The account you selected will be linked as the new primary ACH
          account. This will replace the current primary ACH account already in
          use. Please note that linking the account may take a minute or two and
          will not be ready immediately.
        </p>
        <p>
          If you wish to proceed with this change, select 'Yes.' If you do not
          want to continue, you can close this window or click 'No.'
        </p>
        <div className='buttons'>
          <Button
            className=''
            onClick={() => createACHWithPlaidAccount()}
            loading={callingExternalLinkAPI}
            label='Yes'
          ></Button>
          <Button
            className='p-button-danger'
            onClick={() => setShowChangeACHAccountModal(false)}
            disabled={callingExternalLinkAPI}
            label='No'
          ></Button>
        </div>
      </Dialog>
      <Toast ref={toastErrorMsg} position='top-center' />
    </>
  )
}

export default IBTransferFunds
