import React, { useState } from 'react'
import formatCurrency from '../../../../../utility-functions/formatCurrency'

export default function PartialInvestmentForm(props) {
  const {
    totalAccountValue,
    startingAmount,
    accounts,
    setSelectedTransferAccount,
    selectedTransferAccount,
    selectedBankAccount,
    addHistory, // Handler for the "Continue" button
  } = props

  // Handler for selecting a transfer account
  const handleSelectTransferAccount = (acc) => {
    setSelectedTransferAccount({
      id: acc.accountId,
      type: 'transfer',
    })
  }

  // Handler for selecting the withdraw account
  const handleSelectWithdrawAccount = () => {
    setSelectedTransferAccount({
      id: selectedBankAccount.id,
      type: 'withdraw',
    })
  }

  // Generic AccountItem component
  const AccountItem = ({ acc, type }) => {
    const [isHovered, setIsHovered] = useState(false)

    // Determine if this account is selected
    const isSelected =
      selectedTransferAccount?.id ===
        (type === 'transfer' ? acc.accountId : selectedBankAccount.id) &&
      selectedTransferAccount?.type === type

    // Dynamic styles based on hover and selection
    const accountStyle = {
      padding: '16px 10px',
      backgroundColor: isSelected
        ? '#ffffff' // Darker gray when selected
        : isHovered
        ? '#f0f0f0' // Slightly darker gray on hover
        : '#ffffff', // Default white background
      cursor: 'pointer',
      borderRadius: isSelected || isHovered ? '8px' : '4px', // Rounded borders on hover or selected
      transition: 'background-color 0.3s, border-radius 0.3s, box-shadow 0.3s',
      marginBottom: type === 'transfer' ? '8px' : '0', // Space between transfer items
      border: isSelected ? '1px solid #3627e4' : '1px solid transparent', // Highlight border when selected
      boxShadow: isSelected
        ? '0 2px 4px rgba(0, 0, 0, 0.2)'
        : '0 1px 2px rgba(0, 0, 0, 0.1)', // Subtle shadow
    }

    const textStyle = {
      padding: '4px',
      margin: 0,
      fontSize: '16px',
      color: '#333',
    }

    return (
      <div
        onClick={
          type === 'transfer'
            ? () => handleSelectTransferAccount(acc)
            : handleSelectWithdrawAccount
        }
        style={accountStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role='button'
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            type === 'transfer'
              ? handleSelectTransferAccount(acc)
              : handleSelectWithdrawAccount()
          }
        }}
        aria-pressed={isSelected}
      >
        <p style={textStyle}>
          <span>
            {type === 'transfer' && acc?.product_assigned
              ? acc.product_assigned.charAt(0).toUpperCase() +
                acc.product_assigned.slice(1)
              : selectedBankAccount?.nickname}
          </span>{' '}
          {type === 'transfer' ? 'Account' : ''}
        </p>
      </div>
    )
  }

  // Define the parent container style with additional padding
  const parentStyle = {
    paddingTop: '40px',
    paddingLeft: '10px', // Slight padding inside the parent
    paddingRight: '10px',
    maxWidth: '600px', // Optional: Limit width for better readability
    margin: '0 auto', // Center the container
  }

  const instructionTextStyle = {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '16px',
    color: '#333',
  }

  const sectionTitleStyle = {
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '16px',
    color: '#333',
  }

  const accountsContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '6px',
    padding: '5px', // Slight padding inside the container
    backgroundColor: '#fafafa', // Optional: Light background for the container
  }

  const continueButtonStyle = {
    padding: '12px 20px',
    backgroundColor: selectedTransferAccount ? '#3627e4' : '#cccccc',
    color: '#ffffff',
    border: 'none',
    borderRadius: '6px',
    cursor: selectedTransferAccount ? 'pointer' : 'not-allowed',
    opacity: selectedTransferAccount ? 1 : 0.6,
    transition: 'background-color 0.3s, opacity 0.3s',
    marginTop: '30px',
    width: '100%',
    fontSize: '16px',
  }

  return (
    <div style={parentStyle}>
      <p style={instructionTextStyle}>
        In order to start Robo, the excess funds of{' '}
        {formatCurrency(totalAccountValue - startingAmount)} must be transferred
        or withdrawn. Please select an account to Transfer/Withdraw funds to.
      </p>

      <p style={sectionTitleStyle}>Transfer to accounts:</p>
      <div style={accountsContainerStyle}>
        {accounts
          ?.filter((acc) => acc.product_assigned !== 'robo')
          .map((acc) => (
            <AccountItem acc={acc} type='transfer' key={acc.accountId} />
          ))}
      </div>

      <p style={{ ...sectionTitleStyle, marginTop: '20px' }}>
        Withdraw to account:
      </p>
      <div style={accountsContainerStyle}>
        <AccountItem type='withdraw' />
      </div>

      <button
        style={continueButtonStyle}
        disabled={!selectedTransferAccount}
        onClick={
          selectedTransferAccount ? () => addHistory('Summary') : () => null
        }
      >
        Continue
      </button>

      {selectedTransferAccount?.type === 'withdraw' && (
        <p style={{ marginTop: '20px', fontSize: '14px', color: '#666' }}>
          *Funds will be transferred to your bank account once they are settled.
        </p>
      )}
    </div>
  )
}
