import React from 'react'
import '../../scss/components/IBStatusScreen.scss'
import Pending1 from '../../assets/dashboard/ib_pending_1.svg'
import Pending2 from '../../assets/dashboard/ib_pending_2.svg'
import Pending3 from '../../assets/dashboard/ib_pending_3.svg'
import IBLiquidateDWAccountsCard from './IBLiquidateDWAccountsCard'

export default function IBAccountPendingScreen(props) {
  return (
    <div className='ib-status-main-container'>
      <div className='ib-pending-icons'>
        <img src={Pending1} alt='' />
        <img src={Pending2} alt='' />
        <img src={Pending3} alt='' />
      </div>
      <p className='ib-status-main-text'>Your account will be ready shortly</p>
      <p className='ib-status-sub-text'>
        Please check back within 2 business days
      </p>
      <div className='dw-accounts-card'>
        {props?.driveWealthStatus && (
          <IBLiquidateDWAccountsCard
            {...props}
            user={props.user}
            filteredAccounts={props.accounts?.filter(
              (account) =>
                account?.inst === 'DriveWealth' || account?.inst === 'Apex'
            )}
          />
        )}
      </div>
    </div>
  )
}
