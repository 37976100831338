import React, { Component } from 'react'
import InactiveBasicInfo from './inactiveProduct/InactiveBasicInfo'
import InactivePendingTransfers from './inactiveProduct/InactivePendingTransfers'
import TransferFundsACH from './TransferFundsACH'
import ActivateProduct from './inactiveProduct/ActiveProduct'
import Loader from './Loader'
import ProductActivationBackIcon from '../../../assets/product-activation-back-icon.svg'
import TransferFundsDW from './TransferFundsDW'
import AccountActivity from '../AccountActivity'
import Statements from '../Statements'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../../utility-functions/tabStyles'
import PersonalizationForm from '../robo/PersonalizationForm'
import Axios from 'axios'
import { Button } from 'primereact/button'
import RoboActivation from './product-activation/robo-activation/RoboActivation'
import Intro from './product-activation/robo-activation/Intro'
import Endpoint from '../../Endpoint'
import AccountStatusMessage from './AccountStatusMessage'
export default class InactiveAccountInfo extends Component {
  state = {
    showInfo: true,
    activeTab: 'General Info',
    isLoading: false,
    showPersonalizationForm: false,
    personalizationComplete: false,
    strategySelected: false,
    showRoboStrategyInfo: false,
    hideButtons: false,
  }

  componentDidMount() {
    if (this?.props?.selectedName === 'ROBO') {
      this.getPersonalizationData()
    }
  }

  getPersonalizationData = async () => {
    try {
      let res = await Axios.get(Endpoint + '/mobile/personalized/output', {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
      })
      console.log('res', res)

      if (res?.data?.payload?.strategy_type === 0) {
        // Check if strategy has been selected
        // If questionnaire has been filled out
        if (
          Object.keys(res?.data?.payload?.questionnaire_output).length === 0
        ) {
          this.setState({
            showPersonalizationForm: true,
            strategySelected: false,
            personalizationComplete: false,
          })
        }
        // If questionnaire has not been filled out yet
        else {
          this.setState({
            showPersonalizationForm: true,
            strategySelected: false,
            personalizationComplete: true,
          })
        }
      }
      // if strategy has been selected
      else {
        // if questionnaire was not filled out
        if (
          Object.keys(res?.data?.payload?.questionnaire_output).length === 0
        ) {
          this.setState({
            showPersonalizationForm: true,
            strategySelected: true,
            personalizationComplete: false,
          })
        }
        // if questionnaire was filled out
        else {
          if (
            Object.keys(res?.data?.payload?.customized_strategies).length === 0
          ) {
            if (res?.data?.payload?.strategy_type === 2) {
              this.setState({
                showPersonalizationForm: false,
                strategySelected: true,
                personalizationComplete: true,
              })
            } else {
              this.setState({
                showPersonalizationForm: true,
                strategySelected: false,
                personalizationComplete: true,
              })
            }
          } else {
            this.setState({
              showPersonalizationForm: false,
              strategySelected: true,
              personalizationComplete: true,
            })
          }
        }
      }
    } catch (error) {
      console.log('error', error)
      this.setState({ showPersonalizationForm: true, strategySelected: true })
    }
  }

  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading })
  }
  tabs() {
    let color
    switch (this.props.selectedName) {
      case 'GOALS':
        color = '#3D32E7'
        break
      case 'ROBO':
        color = '#7147E9'
        break
      case 'STOCK':
        color = '#3F46F6'
        break
      case 'CRYPTO':
        color = '#3F46F6'
        break
      default:
        color = '#5C21D8'
        break
    }
    const customSelectedTab = { ...selectedTab, backgroundColor: color }
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '100%',
          flexWrap: 'wrap',
        }}
      >
        <div
          onClick={() =>
            this.setState({ activeTab: 'General Info', showInfo: true })
          }
          style={
            this.state.activeTab === 'General Info'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'General Info'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t(this.props.selectedName.toLowerCase())}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Transfer Funds' })}
          style={
            this.state.activeTab === 'Transfer Funds'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Transfer Funds'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('transferfunds')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Account Activity' })}
          style={
            this.state.activeTab === 'Account Activity'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Account Activity'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('accountactivity')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Documents' })}
          style={
            this.state.activeTab === 'Documents'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Documents'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('documents')}
          </p>
        </div>
      </div>
    )
  }

  showSelectedTab = () => {
    if (this.state.activeTab === 'General Info') return this.generalInfo()
    else if (this.state.activeTab === 'Transfer Funds') {
      if (this.props.selectedName === 'AI CRYPTO TRADER') {
        return (
          <TransferFundsACH
            accounts={this.props.accounts}
            isSmallScreen={this.props.isSmallScreen}
            formatCurrency={this.props.formatCurrency}
            user={this.props.user}
            getAllAccountInfo={this.props.getAllAccountInfo}
            cryptoAccount={this.props.selectedAccount}
            t={this.props.t}
          />
        )
      } else {
        return (
          <TransferFundsDW
            accounts={this.props.accounts}
            isSmallScreen={this.props.isSmallScreen}
            formatCurrency={this.props.formatCurrency}
            user={this.props.user}
            getAllAccountInfo={this.props.getAllAccountInfo}
            t={this.props.t}
            selectedAccount={this.props.selectedAccount}
            alpacaStatus={this.props?.alpacaStatus}
            accountType={'Inactive'}
          />
        )
      }
    } else if (this.state.activeTab === 'Account Activity')
      return (
        <div style={{ height: this.props.isSmallScreen ? '100vh' : '50vh' }}>
          {this.props.accountTransactionsData ? (
            <AccountActivity {...this.props} />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else if (this.state.activeTab === 'Documents')
      return (
        <div>
          {this.props.statements ? (
            <Statements
              statements={this.props.statements}
              selectedAccount={this.props.selectedAccount}
              user={this.props.user}
              t={this.props.t}
            />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else return null
  }

  toggleShowInfo = () => {
    this.setState({ showInfo: !this.state.showInfo })
  }

  generalInfo = () => {
    return (
      <div
        style={{
          height: '100%',
        }}
      >
        {this.state.showInfo ? (
          <>
            <InactiveBasicInfo {...this.props} />
          </>
        ) : null}
        {this?.props?.selectedName === 'ROBO' && !this.state.hideButtons && (
          <div
            className='edit-btn'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifySelf: 'end',
            }}
          >
            <p>
              Based on your{' '}
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() =>
                  this.setState({
                    personalizationComplete: false,
                    showPersonalizationForm: true,
                  })
                }
              >
                Risk Profile
              </span>
            </p>
            {/* <Button
              icon='pi pi-pencil'
              className='p-button-rounded p-button-text'
              aria-label='Edit'
            /> */}
          </div>
        )}
        {this.props.selectedAccount.status === 'OPEN_NO_NEW_TRADES' ||
        this.props.selectedAccount.status === 'OPEN_LIQUIDATE_ONLY' ? null : (
          <ActivateProduct
            {...this.props}
            showPersonalizationForm={this.state.showPersonalizationForm}
            strategySelected={this.state.strategySelected}
            personalizationComplete={this.state.personalizationComplete}
            displayPersonalizationForm={() =>
              this.setState({ showPersonalizationForm: true })
            }
            getPersonalizationData={() => this.getPersonalizationData()}
            toggleShowInfo={this.toggleShowInfo}
            hideButtons={() =>
              this.setState({
                hideButtons: true,
              })
            }
            showButtons={() =>
              this.setState({
                hideButtons: false,
              })
            }
          />
        )}

        {this?.props?.selectedName === 'ROBO' && !this.state.hideButtons && (
          <div
            style={{
              width: this.props.isSmallScreen ? '100%' : '100%',
              boxShadow:
                '-3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21)',
              borderRadius: '19px',
              padding: '25px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginTop: 40,
              // backgroundColor: '#3e53b4',
              backgroundColor: '#3f46f6',
              color: 'white',
              textAlign: 'center',
              cursor: 'pointer',
              gap: 20,
            }}
            onClick={() => this.setState({ showRoboStrategyInfo: true })}
          >
            <p style={{ fontWeight: 'bold', color: 'white' }}>
              Current Strategy
            </p>
            <p style={{ color: 'white' }}>
              {this.props.productInstanceData?.strategyInfo?.name}
            </p>
          </div>
        )}
        {this.state.showInfo ? (
          <>
            {this.props.accountTransactionsData ? (
              <InactivePendingTransfers {...this.props} />
            ) : (
              <Loader
                isSmallScreen={this.props.isSmallScreen}
                logoLoader={false}
              />
            )}
          </>
        ) : null}
      </div>
    )
  }

  render() {
    if (this.props.t) {
      if (this.state.isLoading) {
        return (
          <div>
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          </div>
        )
      } else {
        return (
          <div>
            <div
              style={{
                width: this.props.isSmallScreen ? '100%' : '60%',
              }}
            >
              {(this?.props?.selectedName !== 'ROBO' ||
                (this?.props?.selectedName === 'ROBO' &&
                  !this.state.showRoboStrategyInfo)) && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    <img
                      src={ProductActivationBackIcon}
                      height={25}
                      width={25}
                      onClick={() => {
                        this.props.resetSelectedAccount()
                        if (this.props?.toggleAccount) {
                          this.props.toggleAccount()
                        }
                      }}
                      style={{ cursor: 'pointer', marginBottom: '25px' }}
                      alt=''
                    />
                    <p style={{ paddingTop: '0.2rem' }}>
                      {' '}
                      {this.props?.t('allaccounts')}
                    </p>
                  </div>

                  {this.tabs()}
                  <AccountStatusMessage
                    status={this.props.selectedAccount?.status}
                    t={this.props.t}
                  />
                  <div
                    style={{
                      margin: 'auto',
                      height: '100%',
                      width: this.props?.isSmallScreen ? '100%' : '80%',
                    }}
                  >
                    {this.showSelectedTab()}
                  </div>
                </>
              )}

              {/* New Robo Flow */}

              {this?.props?.selectedName === 'ROBO' &&
                this.state.showRoboStrategyInfo && (
                  <Intro
                    {...this.props}
                    toggleShowRoboStrategyInfo={() =>
                      this.setState({
                        showRoboStrategyInfo: !this.state.showRoboStrategyInfo,
                      })
                    }
                  />
                )}
            </div>

            {this?.props?.selectedName === 'ROBO' &&
              this.state.showPersonalizationForm && (
                <PersonalizationForm
                  user={this.props.user}
                  personalizationComplete={this.state.personalizationComplete}
                  strategySelected={this.state.strategySelected}
                  productInstanceData={this.props.productInstanceData}
                  showPersonalizationForm={this.state.showPersonalizationForm}
                  hidePersonalizationForm={() =>
                    this.setState({ showPersonalizationForm: false })
                  }
                  strategyInfo={this.props.strategyInfo}
                  userInfo={this.props.userInfo}
                  isSmallScreen={this.props?.isSmallScreen}
                  getRoboInstance={() => this.props.getRoboInstance()}
                />
              )}
          </div>
        )
      }
    } else return null
  }
}
