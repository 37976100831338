import React, { Component } from 'react'
import '../../../../scss/pages/account-page/inactive-account-info/InactiveBasicInfo.scss'
export default class InactiveBasicInfo extends Component {
  displayInactiveBasicInfo() {
    const name = this.props.selectedAccount.name
      ? this.props.selectedAccount.name
      : '-'
    const totalAccountValue =
      this.props.selectedAccount.balances[0].virtualAccountValue !== null
        ? this.props.selectedAccount.balances[0].virtualAccountValue?.toFixed(2)
        : '-'
    const equityValue =
      this.props.selectedAccount.balances[0].equityValue !== null
        ? this.props.selectedAccount.balances[0].equityValue?.toFixed(2)
        : '-'
    const netPL =
      this.props.selectedAccount.balances[0]['profit-loss'] !== null
        ? this.props.selectedAccount.balances[0]['profit-loss']?.toFixed(2)
        : '-'

    const cashAvailableForWithdraw = this.props.selectedAccount.balances[0]
      ?.cashAvailableForWithdrawal
      ? this.props.selectedAccount.balances[0]?.cashAvailableForWithdrawal?.toFixed(
          2
        )
      : '-'

    const settledCash = this.props.selectedAccount.balances[0]?.fundsInvestable
      ? this.props.selectedAccount.balances[0]?.fundsInvestable?.toFixed(2)
      : '-'

    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '100%',
        }}
        className='inactive-basic-info-container'
      >
        <div style={{}}>
          <p className='inactive-name'>
            {this.props.t(this.props.selectedName.toLowerCase())}
          </p>
          <p className={`inactive-value ${this.props.selectedName}`}>
            {this.props.formatCurrency(totalAccountValue)}
          </p>
        </div>
        <div className='details-container'>
          <div className='inner-container'>
            {/* <div className='inner-row'>
              <p className='inner-label'>{this.props.t('equityvalue')}:</p>
              <p className='inner-value'>
                {this.props.formatCurrency(equityValue)}
              </p>
            </div>
            <div className='inner-row'>
              <p className='inner-label'>{this.props.t('netpl')}:</p>
              {netPL === '-' ? (
                <p className='inner-value'>{'-'}</p>
              ) : (
                <>
                  {netPL >= 0 ? (
                    <p className='inner-value'>
                      {this.props.formatCurrency(netPL)}
                    </p>
                  ) : (
                    <p style={{ fontSize: '17px', color: '#3D5062' }}>
                      {this.props.formatCurrency(netPL)}
                    </p>
                  )}
                </>
              )}
            </div>
            {this.props.selectedAccount.gfv_count ? (
              <div className='inner-row'>
                <p className='inner-label'>Good faith Violation:</p>
                <p style={{ fontSize: '17px', color: '#3D5062' }}>
                  {this.props.selectedAccount.gfv_count}
                </p>
              </div>
            ) : null} */}
            {this.props.selectedName === 'AI STOCK TRADER' && (
              <div className='inner-row'>
                <p className='inner-label'>{this.props.t('settledcash')}:</p>
                <p style={{ fontSize: '17px', color: '#3D5062' }}>
                  {settledCash === '-'
                    ? '-'
                    : this.props.formatCurrency(settledCash)}
                </p>
              </div>
            )}
            <div className='inner-row'>
              <p className='inner-label' style={{ margin: 0 }}>
                {this.props.t('cashavailableforwithdraw')}:
              </p>
              <p style={{ fontSize: '17px', color: '#3D5062', margin: 0 }}>
                {cashAvailableForWithdraw === '-'
                  ? '-'
                  : this.props.formatCurrency(cashAvailableForWithdraw)}
              </p>
            </div>
          </div>
        </div>
        {/* <div className='details-container'>
          <div className='inner-container'>
            <div className='inner-row'>
              <p className='inner-label'>{this.props.t('equityvalue')}:</p>
              <p className='inner-value'>
                {this.props.formatCurrency(equityValue)}
              </p>
            </div>
            <div className='inner-row'>
              <p className='inner-label'>{this.props.t('netpl')}:</p>
              {netPL === '-' ? (
                <p className='inner-value'>{'-'}</p>
              ) : (
                <>
                  {netPL >= 0 ? (
                    <p className='inner-value'>
                      {this.props.formatCurrency(netPL)}
                    </p>
                  ) : (
                    <p style={{ fontSize: '17px', color: '#3D5062' }}>
                      {this.props.formatCurrency(netPL)}
                    </p>
                  )}
                </>
              )}
            </div>
            {this.props.selectedAccount.gfv_count ? (
              <div className='inner-row'>
                <p className='inner-label'>Good faith Violation:</p>
                <p style={{ fontSize: '17px', color: '#3D5062' }}>
                  {this.props.selectedAccount.gfv_count}
                </p>
              </div>
            ) : null}
          </div>
        </div> */}
      </div>
    )
  }
  render() {
    if (this.props.t) {
      return <div>{this.displayInactiveBasicInfo()}</div>
    } else return null
  }
}
