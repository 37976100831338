import React, { Component, createRef } from 'react'
import AllocationPieChart from '../../AllocationPieChart'
import StrategyInfoGraph from '../StrategyInfoGraph'
import ContributionEditModal from '../../ContributionEditModal'
import { InputSwitch } from 'primereact/inputswitch'
import Axios from 'axios'
import Endpoint from '../../../Endpoint'
import ConfirmationDialog from '../../ConfirmationDialog'
import '../../../../scss/pages/account-page/robo-account-info/RoboSpecificInfo.scss'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import formatCurrency from '../../../../utility-functions/formatCurrency'
import { Toast } from 'primereact/toast'

export default class RoboSpecificInfo extends Component {
  // Initialize state using class field syntax
  state = {
    // Edit contribution modal
    showEditContributionModal: false,
    showConfirmationDialog: false,
    showAllocationPieChart: false,
    nonTradingHoursDialog: false,
    liquidateRoboDialog: false,
    liquidateAmount:
      this.props.selectedAccount?.balances?.[0]?.virtualAccountValue ?? 0,
    selectedTransferAccount: null,
    showPartialLiquidationForm: false,
    partialLiquidateFormStep: 0,
    showPartialLiquidationConfirmation: false,
  }

  // Initialize the Toast ref using class field syntax
  toast = createRef()

  // Uncomment and update if needed
  // componentDidUpdate = (prevProps, prevState) => {
  //   if (prevProps.accounts !== this.props.accounts) {
  //     this.props.toggleLoading();
  //   }
  // };

  partialLiquidateRobo = async () => {
    const transfer_amount = this.state.liquidateAmount
    const body = {
      account_id: this.props.selectedAccount.accountId,
      transfer_to: this.state.selectedTransferAccount?.id,
      type: this.state.selectedTransferAccount?.type,
      amount: transfer_amount,
    }
    console.log('partial', body)
    return await Axios.post(
      `${Endpoint}/mobile/alpaca/partial-liquidate-robo`,
      body,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        },
      }
    )
      .then((response) => {
        if (response.data) {
          this.props.getAllAccountInfo()
          this.setState({ liquidateRoboDialog: false })
          // Optionally, show a success toast
          this.toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Partial liquidation initiated successfully',
            life: 3000,
          })
          return true
        } else return false
      })
      .catch((response) => {
        // Optionally, show an error toast
        this.toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to initiate partial liquidation',
          life: 3000,
        })
        return false
      })
  }

  deactivateRobo = async () => {
    return await Axios.get(
      `${Endpoint}/mobile/alpaca/liquidate-robo?dw_account_id=${this.props.selectedAccount.accountId}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        },
      }
    )
      .then((response) => {
        if (response.data) {
          this.props.getAllAccountInfo()
          this.setState({ liquidateRoboDialog: false })
          // Optionally, show a success toast
          this.toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Robo account deactivated successfully',
            life: 3000,
          })
          return true
        } else return false
      })
      .catch((response) => {
        // Optionally, show an error toast
        this.toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to deactivate Robo account',
          life: 3000,
        })
        return false
      })
  }

  toggleShowConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: !this.state.showConfirmationDialog,
    })
    // Uncomment and update if needed
    // if (this.props.canTrade?.status) {
    //   this.setState({
    //     showConfirmationDialog: !this.state.showConfirmationDialog,
    //   });
    // } else {
    //   this.toggleNonTradingHoursDialog();
    // }
  }

  toggleNonTradingHoursDialog = () => {
    this.setState({ nonTradingHoursDialog: !this.state.nonTradingHoursDialog })
  }

  toggleEditContributionModal = () => {
    this.setState({
      showEditContributionModal: !this.state.showEditContributionModal,
    })
  }

  toggleAllocationPieChart = () => {
    this.setState({
      showAllocationPieChart: !this.state.showAllocationPieChart,
    })
  }

  intro() {
    const isCustom =
      this.props.productInstanceData?.strategyInfo?.id === 0 ? true : false
    const strategyInfo =
      this.props.strategyInfo[this.props.productInstanceData.tracker.stratId]
    let seriesData = []
    if (strategyInfo && strategyInfo.graph) {
      strategyInfo.graph.forEach((ele) => {
        seriesData.push([
          Date.parse(ele.date),
          parseFloat((ele.aggregate_return * 100).toFixed(2)),
        ])
      })
    }
    return (
      <div>
        <p
          style={{
            color: '#7147E9',
            fontWeight: 'bold',
            fontSize: '25px',
            textAlign: 'center',
          }}
        >
          {strategyInfo?.fullname}
        </p>
        <p>{strategyInfo?.description}</p>
        {!isCustom && <StrategyInfoGraph seriesData={seriesData} />}
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontSize: '15px',
              color: 'rgba(128,128,128,.5)',
              textAlign: 'right',
              margin: 0,
            }}
          >
            {strategyInfo?.disclaimer}
          </p>
          {!isCustom && (
            <div>
              <a
                style={{
                  fontSize: '15px',
                  color: '#3627e4',
                  width: '100%',
                  textDecoration: 'underline',
                }}
                href='/Legal/General-Disclaimer.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                <p
                  style={{
                    textAlign: 'right',
                    margin: 0,
                  }}
                >
                  DISCLAIMER
                </p>
              </a>
              <a
                style={{
                  fontSize: '15px',
                  color: '#3627e4',
                  width: '100%',
                  textDecoration: 'underline',
                }}
                href='/Legal/ForeSCITE-GIPS-Report-Firm-Wide4Q2020.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                <p
                  style={{
                    textAlign: 'right',
                    margin: 0,
                  }}
                >
                  GIPS VERIFIED
                </p>
              </a>
              {strategyInfo?.name === 'USDOM' && (
                <a
                  style={{
                    fontSize: '15px',
                    color: '#3627e4',
                    width: '100%',
                    textDecoration: 'underline',
                  }}
                  href='/Legal/ForeSCITE-GIPS-Report-US-Domestic-Composite-4Q2020.pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <p
                    style={{
                      textAlign: 'right',
                      margin: 0,
                    }}
                  >
                    USDOM GIPS REPORT
                  </p>
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  closeAndResetEditContributionModal = () => {
    // if (
    //   this.props.editContributionStatus &&
    //   this.props.editContributionStatus === "success"
    // )
    this.props.resetEditContributionModal()
    this.toggleEditContributionModal()
  }

  overview() {
    return (
      <div>
        <p
          style={{
            color: 'black',
            fontFamily: 'Akkurat-Bold',
            fontSize: '15px',
            textAlign: 'center',
          }}
        >
          {this.props.t('overview')}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>{this.props.t('active')}</p>
          </div>
          <InputSwitch
            checked={true}
            onChange={(e) => {
              this.setState({
                liquidateRoboDialog: true,
              })
              // Uncomment and update if needed
              // if (this.props.canTrade?.status) {
              //   this.setState({
              //     liquidateRoboDialog: true,
              //   });
              // } else {
              //   this.toggleNonTradingHoursDialog();
              // }
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('startdate')}</p>
          <p>{this.props.productInstanceData.tracker.createdAt}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('investmentamount')}</p>
          <p>
            {this.props.formatCurrency(
              this.props.productInstanceData.tracker.totalContributed
            )}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <p>{this.props.t('contribution')}</p>
            <p
              onClick={() => {
                this.toggleEditContributionModal()
              }}
              style={{
                border: '1px solid #7147E9',
                borderRadius: '25px',
                padding: '0px 3px',
                marginLeft: '5px',
                cursor: 'pointer',
                background: '#7147E9',
                color: 'white',
                fontSize: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {this.props.t('edit')}
            </p>
          </div>
          {this.props.productInstanceData.roundUpData.roundUp ? (
            <p>{this.props.t('roundup')}</p>
          ) : (
            <p>
              {this.props.formatCurrency(
                this.props.productInstanceData.roundUpData.recurringDepositData
                  .amount
              )}{' '}
              {this.props.t('monthly')}
            </p>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('currentvalue')}</p>
          <p>
            {this.props.formatCurrency(
              this.props.productInstanceData.cashData?.cashAvailable +
                this.props.productInstanceData.cashData?.equityValue
            )}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('return')}</p>
          <p>${this.props.productInstanceData.profit?.toFixed(2)}</p>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(128,128,128,.5)",
          }}
        >
          <p>Return</p>
          <PL
            formatCurrency={this.props.formatCurrency}
            value={this.props.productInstanceData.tracker.profit}
            percentage={
              this.props.productInstanceData.tracker.profit_percentage
            }
          />
        </div> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '25px',
          }}
        >
          <p>{this.props.t('monthtodate')}</p>
          {this.props.productInstanceData.strategyInfo.mtd > 0 ? (
            <p>
              {(this.props.productInstanceData.strategyInfo.mtd * 100).toFixed(
                2
              )}
              %
            </p>
          ) : (
            <p style={{ color: '#3D5062' }}>
              {(this.props.productInstanceData.strategyInfo.mtd * 100).toFixed(
                2
              )}
              %
            </p>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('yeartodate')}</p>
          {this.props.productInstanceData.strategyInfo.ytd > 0 ? (
            <p>
              {(this.props.productInstanceData.strategyInfo.ytd * 100).toFixed(
                2
              )}
              %
            </p>
          ) : (
            <p style={{ color: '#3D5062' }}>
              {(this.props.productInstanceData.strategyInfo.ytd * 100).toFixed(
                2
              )}
              %
            </p>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p style={{ maxWidth: '55%' }}>
            {this.props.t('expectedpotentialrisk')}
          </p>
          <p>
            {Math.abs(
              this.props.productInstanceData.strategyInfo.cvar * 100
            ).toFixed(2)}
            %
          </p>
        </div>
      </div>
    )
  }

  rebalanceInformation() {
    return (
      <div>
        <p
          style={{
            color: 'black',
            fontFamily: 'Akkurat-Bold',
            fontSize: '15px',
            textAlign: 'center',
          }}
        >
          {this.props.t('rebalanceinformation')}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('nextrebalance')}</p>
          <p>{this.props.productInstanceData.rebalanceInfo.nextRebalance}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('lastrebalancedate')}</p>
          <p>
            {this.props.productInstanceData.rebalanceInfo.lastRebalanceDate}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('daysuntilnextrebalance')}</p>
          <p>
            {this.props.productInstanceData.rebalanceInfo.daysUntilRebalance}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('intramonth')}</p>
          {this.props.productInstanceData.rebalanceInfo.isIntraMonth ? (
            <p>YES</p>
          ) : (
            <p>NO</p>
          )}
        </div>
      </div>
    )
  }

  allocation() {
    if (
      this.props.productInstanceData.allocations &&
      this.props.productInstanceData.allocations.length > 0
    ) {
      let seriesData = []
      this.props.productInstanceData.allocations.forEach((allocation) => {
        if (allocation.allocation > 0) {
          seriesData.push({
            name: allocation.symbol,
            value: parseFloat((allocation.allocation * 100).toFixed(2)),
          })
        }
      })
      return (
        <div className='robo-allocation-container'>
          <p
            style={{
              fontSize: '15px',
              fontFamily: 'Akkurat-Bold',
              textAlign: 'center',
              marginBottom: '0',
              cursor: 'pointer',
              color: '#7147E9',
            }}
            onClick={() => {
              this.toggleAllocationPieChart()
            }}
          >
            {this.props.t('viewallocations')}
          </p>
          <Dialog
            visible={this.state.showAllocationPieChart}
            style={{
              width: this.props.isSmallScreen ? '90vw' : '30vw',
              height: this.props.isSmallScreen ? '90vh' : '50vh',
            }}
            onHide={() => this.toggleAllocationPieChart()}
            header={
              <p className='allocation-dialog-header'>
                {this.props.t('allocation')}
              </p>
            }
          >
            <AllocationPieChart
              seriesData={seriesData}
              t={this.props.t}
              verticalAlign={false}
              isSmallScreen={this.props.isSmallScreen}
            />
          </Dialog>
        </div>
      )
    } else {
      return null
    }
  }

  partialLiquidationForm() {
    switch (this.state.partialLiquidateFormStep) {
      case 1:
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: 18,
              width: '100%',
            }}
          >
            <button
              onClick={() =>
                this.setState({
                  partialLiquidateFormStep: 0,
                })
              }
              style={{ backgroundColor: 'transparent', border: 'none' }}
            >
              {'<'} Back
            </button>
            <p>
              <span style={{ color: '#3627e4' }}>
                {formatCurrency(this.state.liquidateAmount)}{' '}
              </span>
              must be transferred or withdrawn. Please select an account to
              Transfer/Withdraw funds to.
            </p>
            <p>Transfer to accounts:</p>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#f0f0f0',
                padding: 6,
                borderRadius: 6,
              }}
            >
              {this.props.accounts
                ?.filter((acc) => acc.product_assigned !== 'robo')
                .map((acc, i) => (
                  <div
                    key={i}
                    className='robo-account-card'
                    onClick={() =>
                      this.setState({
                        showPartialLiquidationConfirmation: true,
                        selectedTransferAccount: {
                          id: acc.accountId,
                          type: 'transfer',
                        },
                      })
                    }
                  >
                    {acc.product_assigned.charAt(0).toUpperCase() +
                      acc.product_assigned.slice(1)}{' '}
                    Account
                  </div>
                ))}
            </div>
            <p>Deposit to account:</p>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#f0f0f0',
                padding: 6,
                borderRadius: 6,
              }}
            >
              <div
                className='robo-account-card'
                onClick={() =>
                  this.setState({
                    showPartialLiquidationConfirmation: true,
                    selectedTransferAccount: {
                      id: this.props.achLinkedAccount.id,
                      type: 'withdraw',
                    },
                  })
                }
              >
                {this.props.achLinkedAccount?.nickname}
              </div>
            </div>
          </div>
        )
      case 0:
      default:
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: 18,
            }}
          >
            <button
              onClick={() =>
                this.setState({
                  showPartialLiquidationForm: false,
                })
              }
              style={{ backgroundColor: 'transparent', border: 'none' }}
            >
              {'<'} Back
            </button>
            <p>
              This will trim your holdings proportionally to maintain the target
              allocations in your portfolio.
            </p>
            <p>Enter amount you wish to liquidate:</p>
            <InputNumber
              value={this.state.liquidateAmount}
              min={0}
              max={this.state.liquidateAmount}
              onValueChange={(e) =>
                this.setState({
                  liquidateAmount: e.value,
                })
              }
              mode='currency'
              currency='USD'
              minFractionDigits={0}
              locale='en-US'
              style={{ width: '100%' }}
            />
            <Button
              label='Continue'
              onClick={() => {
                const newBalance =
                  this.props.selectedAccount?.balances?.[0]
                    ?.virtualAccountValue - this.state.liquidateAmount
                console.log('new balance', newBalance)
                if (newBalance < 500) {
                  // Show error toast
                  this.toast.current.show({
                    severity: 'error',
                    summary: 'Insuffient Funds after liquidation',
                    detail: `Resulting balance will be ${formatCurrency(
                      newBalance
                    )} after liquidation. Your balance cannot be less than $500`,
                    life: 3000,
                  })
                } else {
                  this.setState({
                    partialLiquidateFormStep: 1,
                  })
                }
              }}
              style={{ width: '100%' }}
            />
          </div>
        )
    }
  }

  render() {
    if (this.props.t) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '100%',
          }}
          className='robo-specific-info-container'
        >
          {this.intro()}
          {this.overview()}
          {this.rebalanceInformation()}
          {this.allocation()}
          {this.state.showEditContributionModal ? (
            <ContributionEditModal
              toggleEditContributionModal={this.toggleEditContributionModal}
              showDialog={this.state.showEditContributionModal}
              closeAndResetEditContributionModal={
                this.closeAndResetEditContributionModal
              }
              editContributionStatus={this.props.editContributionStatus}
              editContributionRoundUp={this.props.editContributionRoundUp}
              editContributionPeriodic={this.props.editContributionPeriodic}
              accounts={this.props.accounts}
              periodicContributions={
                this.props.productInstanceData.roundUpData.roundUp
                  ? 0
                  : this.props.productInstanceData.roundUpData
                      .recurringDepositData.amount
              }
              roundUp={this.props.productInstanceData.roundUpData.roundUp}
              isSmallScreen={this.props.isSmallScreen}
              selectedAccount={this.props.selectedAccount}
              t={this.props.t}
            />
          ) : null}
          <Dialog
            visible={this.state.liquidateRoboDialog}
            onHide={() =>
              this.setState({
                liquidateRoboDialog: false,
              })
            }
            header={'Liquidate Robo'}
            style={{ width: '90vw', maxWidth: 440 }}
          >
            <div
              style={{
                paddingBottom: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: 18,
              }}
            >
              {this.state.showPartialLiquidationForm ? (
                this.partialLiquidationForm()
              ) : (
                <>
                  <Button
                    label='Partially Liquidate Robo'
                    style={{ width: '100%' }}
                    onClick={() => {
                      this.setState({
                        showPartialLiquidationForm: true,
                      })
                    }}
                  />
                  <Button
                    label='Fully Liquidate Robo'
                    style={{ width: '100%' }}
                    onClick={() => {
                      this.toggleShowConfirmationDialog()
                    }}
                  />
                </>
              )}
            </div>
            <Toast ref={this.toast} position='top-center' />
          </Dialog>
          <ConfirmationDialog
            isSmallScreen={this.props.isSmallScreen}
            toggleShowConfirmationDialog={() =>
              this.setState({
                showPartialLiquidationConfirmation: false,
              })
            }
            showConfirmationDialog={
              this.state.showPartialLiquidationConfirmation
            }
            name={'Liquidate'}
            customHeader={'Partially Liquidate Robo'}
            customMessage={
              'Are you sure you want to proceed with partially liquidating your Robo Account?'
            }
            action={this.partialLiquidateRobo}
            successMessage={
              'Partial liquidation has been initiated successfully'
            }
            t={this.props.t}
          />
          <ConfirmationDialog
            isSmallScreen={this.props.isSmallScreen}
            toggleShowConfirmationDialog={this.toggleShowConfirmationDialog}
            showConfirmationDialog={this.state.showConfirmationDialog}
            name={this.props.t('deactivaterobo')}
            action={this.deactivateRobo}
            successMessage={this.props.t('robohasbeensuccessfullydeactivated')}
            t={this.props.t}
          />
          <Dialog
            visible={this.state.nonTradingHoursDialog}
            onHide={this.toggleNonTradingHoursDialog}
            header={this.props.t('cannotdeactivateroboduringnontradinghours')}
          ></Dialog>
          {/* Use this.toast instead of toast */}
        </div>
      )
    } else return null
  }
}
