//--------WHICH Environment? ---------------------------------
//                                                            |
export default 'https://ticker.allocaterite.com' //prod
// export default 'https://dev-api.allocaterite.com/search' //dev
//                                                            |
//-----------------------------------------------------------

//////////////////////////////////////////////////////////////////////
/*
import ENDPOINT from '../Endpoint'

${ENDPOINT}
*/
