import React from 'react'
import Header from '../PublicHeader'
import Footer from '../Footer'
import TradeModal from '../dashboard/TradeModal'
import ChangeSinceYest from '../companyPage3/ChangeSinceYest2'
import axios from 'axios'
import '../../app.css'
import BuyOrSellForm from '../components/BuyOrSellForm'
import SearchBar from '../components/SearchBar'
import WatchListIcon from '../../assets/watchlist2_icon.svg'
import WatchListIconActive from '../../assets/watchlist_active_icon.svg'
import BellIcon from '../../assets/bell_icon.svg'
import BellActiveIcon from '../../assets/bell_active_icon.svg'
import PL from '../components/accountInfo/PL'
import moment from 'moment'
import '../../scss/pages/Cp3.scss'
import { NavLink /*Redirect*/ } from 'react-router-dom'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../utility-functions/tabStyles'
import {
  FaArrowLeft,
  FaRegShareSquare,
  FaArrowRight,
  FaPlus,
  FaLongArrowAltLeft,
} from 'react-icons/fa'
// import { Container, Row, Col, Button } from "react-bootstrap";
import Ptp from '../companyPage3/Ptp'
import RsCi from '../companyPage3/RsCi'
import Chart from '../companyPage3/Chart'
import Analysis from '../companyPage3/Analysis'
import Forecast from '../companyPage3/Forecast'
import Stats from '../companyPage3/Stats'
import SearchGlass from '../../assets/newUI/searchGlass.png'
import upArrow from '../../assets/single_up.svg'
import downArrow from '../../assets/single_down.svg'
import strongUpArrow from '../../assets/double_up.svg'
import strongDownArrow from '../../assets/double_down.svg'
import flatArrow from '../../assets/flat.svg'
import NoImage from '../../assets/newUI/logo_notfound.png'
import UserDashboard from './Dashboardv2'
import { withRouter } from 'react-router-dom'
import ENDPOINT from '../Endpoint'
import Axios from 'axios'
import { Button } from 'primereact/button'
import AlertsForm from '../components/AlertsForm'
import { Dialog } from 'primereact/dialog'
import { TailSpin } from 'react-loader-spinner'
import SearchIcon from '../../assets/search-icon.png'
import SearchEndpoint from '../SearchEndpoint'
import Loader from '../components/accountInfo/Loader'
import CheckCircle from '../../assets/check-circle-icon.svg'
import PlusCircle from '../../assets/plus-circle-icon.svg'
import RemoveCircle from '../../assets/remove-circle-icon.svg'
import {
  getForecastChartData,
  getPrices,
  getSingleStockData2,
} from '../../services/stockService'
import Events from '../companyPage3/Events'
import StockOptions from '../companyPage3/StockOptions'
import WatchlistSelection from '../WatchlistSelection'
import WatchlistSelectionRemove from '../WatchlistSelectionRemove'
import { Checkbox } from 'primereact/checkbox'

class Cp3 extends React.Component {
  state = {
    activeTab: 'Chart',
    sourceData: false,
    symbol: this.props.symbol,
    searchActive: true,
    searchResults: [],
    showTrade: false,
    imgURL: NoImage,
    canTrade: {
      status: true,
    },
    noTicker: 'loading',
    isSmallScreen: null,
    selectedStock: null,
    alertsData: null,
    showAlertsForm: false,
    errorMessage: null,
    successMessage: null,
    stockData: null,
    descriptionModal: false,
    watchlistModal: false,
    watchlistRemoveModal: false,
    type: '',
    forecastChartData: null,
    showExtendedHoursModal: false,
    loading: false,
    prefillOrderType: this.props.location.state
      ? this.props.location.state.order_type
      : null,
    prefillTotal: this.props.location.state
      ? this.props.location.state.total
      : null,
    prefillInvest: this.props.location.state
      ? this.props.location.state.invest
      : null,
    prefillLimitPrice: this.props.location.state
      ? this.props.location.state.order_price
      : null,
    prefillActiveTab: this.props.location.state
      ? this.props.location.state.tab
      : null,
    usingPrefill: false,
  }

  interval = 0

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    if (this.props.user) {
      // this.fetchDataLoggedIn() // general stock data
      this.fetchSingleStockData()
      this.getMarketStatus() // is the market open
      this.excessSearchForDing() //instant fire
      //this.interval = setInterval(() => this.excessSearchForDing(), 5000) //USED TO REFRESH PRICES
      this.interval = setInterval(() => this.refreshPrices(), 3000) //USED TO REFRESH PRICES
      //this.getAnalysis()
      this.getStockAlerts()
      this.getStockForecastChartData()
      console.log('sad', this.state.symbol)
      this.setState({
        prefillOrderType: this.props.location.state
          ? this.props.location.state.order_type
          : null,
        prefillTotal: this.props.location.state
          ? this.props.location.state.total
          : null,
        prefillInvest: this.props.location.state
          ? this.props.location.state.invest
          : null,
        prefillLimitPrice: this.props.location.state
          ? this.props.location.state.order_price
          : null,
        prefillActiveTab: this.props.location.state
          ? this.props.location.state.tab
          : null,
        usingPrefill: true,
      })
    } else {
      this.fetchDataLoggedOut()
    }
    document.title = `Investall - ${this.state.symbol}`
    if (window.innerWidth > 850 && window.innerWidth < 1295) {
      document.body.style.zoom = 0.9
    }
    this.setState({ symbol: this.state.symbol })
    this.setImage()
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({ symbol: window.location.pathname.split('/')[3] })
      console.log('sad2', this.state.symbol)
    })
    this.checkWatchlist()
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('sad3 this.props.symbol', this.props.symbol)
    // console.log('sad4 prevProps.symbol', prevProps.symbol)
    // console.log('sad5', this.state.symbol)

    // console.log(prevProps)
    // console.log(this.props)
    // console.log('breakpoint 0', this.props.location.state)

    if (this.props.location.pathname !== prevProps.location.pathname) {
      console.log('breakpoint 0.5 Different Route')

      this.setState({
        prefillOrderType: this.props.location.state
          ? this.props.location.state.order_type
          : null,
        prefillTotal: this.props.location.state
          ? this.props.location.state.total
          : null,
        prefillInvest: this.props.location.state
          ? this.props.location.state.invest
          : null,
        prefillLimitPrice: this.props.location.state
          ? this.props.location.state.order_price
          : null,
        prefillActiveTab: this.props.location.state
          ? this.props.location.state.tab
          : null,
        usingPrefill: true,
      })
    }
    if (
      this.props.location.state !== prevProps.location.state &&
      this.props.location.pathname === prevProps.location.pathname
    ) {
      console.log('breakpoint 0.5 Same Route-------------------')
      this.setState({
        prefillOrderType: this.props.location.state
          ? this.props.location.state.order_type
          : null,
        prefillTotal: this.props.location.state
          ? this.props.location.state.total
          : null,
        prefillInvest: this.props.location.state
          ? this.props.location.state.invest
          : null,
        prefillLimitPrice: this.props.location.state
          ? this.props.location.state.order_price
          : null,
        prefillActiveTab: this.props.location.state
          ? this.props.location.state.tab
          : null,
        usingPrefill: true,
      })
    }

    if (prevProps != this.props) {
      console.log(this.props.location.state)

      if (this.props.user) {
        if (this.props.symbol !== prevProps.symbol) {
          // this.fetchDataLoggedIn() // general stock data
          this.fetchSingleStockData()
          //this.getMarketStatus() // is the market open
          clearInterval(this.interval)
          this.excessSearchForDing() //instant fire
          //this.interval = setInterval(() => this.excessSearchForDing(), 5000) //USED TO REFRESH PRICES
          this.interval = setInterval(() => this.refreshPrices(), 5000) //USED TO REFRESH PRICES
          //this.getAnalysis()
          this.getStockAlerts()
          this.checkWatchlist()
          this.getStockForecastChartData()
        }
      } else {
        this.fetchDataLoggedOut()
      }
      this.setState({ symbol: window.location.pathname.split('/')[3] })
      this.setState({ activeTab: 'Chart' })
      document.title = `ForeSCITE - ${this.state.symbol}`
      if (window.innerWidth > 850 && window.innerWidth < 1295) {
        document.body.style.zoom = 0.9
      }
      this.setImage()

      // this.setState({
      //   prefillOrderType: this.props.location.state
      //     ? this.props.location.state.order_type
      //     : null,
      //   prefillTotal: this.props.location.state
      //     ? this.props.location.state.total
      //     : null,
      //   prefillInvest: this.props.location.state
      //     ? this.props.location.state.invest
      //     : null,
      //   prefillLimitPrice: this.props.location.state
      //     ? this.props.location.state.order_price
      //     : null,
      //   prefillActiveTab: this.props.location.state
      //     ? this.props.location.state.tab
      //     : null,
      // })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    clearInterval(this.interval)
    this.unlisten()
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  restartInterval = () => {
    this.interval = setInterval(() => {
      this.refreshPrices()
    }, 3000)
  }

  stopInterval = () => {
    clearInterval(this.interval)
  }

  componentWillReceiveProps() {
    if (this.state.symbol !== this.state.symbol) {
      if (this.props.user) {
        // this.fetchDataLoggedIn()
      } else {
        this.fetchDataLoggedOut()
      }
      this.setImage()
      document.title = `ForeSCITE - ${this.state.symbol}`
      this.setState({ symbol: this.state.symbol })
    }
  }

  refreshPrices = async () => {
    const result = await getPrices(
      this.props.user?.idToken?.jwtToken,
      this.state.symbol
    )
    this.setState({
      selectedStock: {
        ...this.state.selectedStock,
        high: result?.high,
        low: result?.low,
        open: result?.open,
      },
      sourceData: {
        ...this.state.sourceData,
        price: result?.price,
        priceUSD: result?.price,
        priceChange: result?.price_change_percentage,
        priceChangeValue: result?.price_change,
      },
    })
  }

  getStockForecastChartData = async () => {
    const res = await getForecastChartData(
      this.props.symbol,
      this.props.user.idToken.jwtToken
    )
    this.setState({ forecastChartData: res })
  }

  checkWatchlist = () => {
    for (let i = 0; i < this.props.myWatchlist?.length; i++) {
      if (this.props.myWatchlist[i]?.symbol === this.props.symbol) {
        this.setState({
          sourceData: { ...this.state.sourceData, watchlisted: true },
        })
        return
      }
    }
    this.setState({
      sourceData: { ...this.state.sourceData, watchlisted: false },
    })
  }

  formatCurrency(val) {
    const options = { style: 'currency', currency: 'USD' }
    const numberFormat = new Intl.NumberFormat('en-US', options)
    if (!val) return numberFormat.format(0)
    return numberFormat.format(val)
  }

  toggleSuccessMessage = (msg, msg2, msg3, msg4) => {
    clearTimeout(this.timeout)
    this.setState({ successMessage: msg, sm2: msg2, sm3: msg3, sm4: msg4 })
    this.timeout = setTimeout(() => {
      this.setState({ successMessage: null })
    }, 15000)
  }

  toggleErrorMessage = (msg) => {
    clearTimeout(this.timeout)
    this.setState({ errorMessage: msg })
    this.timeout = setTimeout(() => {
      this.setState({ errorMessage: null })
    }, 15000)
  }

  getStockAlerts = async () => {
    await Axios.get(
      `${ENDPOINT}/mobile/fibonacci-alerts/view?stock=${this.state.symbol}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
      }
    )
      .then((response) => {
        let alertsData = new Map()
        if (response?.data?.data) {
          response.data.data.forEach((data) => {
            const key = data.stock
            if (alertsData.has(key)) {
              alertsData.get(key).push(data)
            } else {
              alertsData.set(key, [data])
            }
          })
        }
        if (response?.data?.indicators_data) {
          response.data.indicators_data.forEach((data) => {
            const key = data.stock
            if (alertsData.has(key)) {
              alertsData.get(key).push(data)
            } else {
              alertsData.set(key, [data])
            }
          })
        }
        this.setState({ alertsData: alertsData })
      })
      .catch((err) => {
        console.log('get alerts error: ', err)
      })
  }

  excessSearchForDing = (sd) => {
    if (this.state.noTicker !== 'noTicker') {
      if (window.location.pathname.split('/')[2] === 'Trade') {
        let sym = this.state.symbol
        // let url = `${ENDPOINT}/search/ticker-bulk-live?search=${sym.toUpperCase()}`;
        let url = `${SearchEndpoint}/ticker-bulk-live?search=${sym.toUpperCase()}`
        fetch(url)
          .then((res) => res.json())
          .then((responseJson) => {
            if (
              responseJson.content.length === 0 &&
              !this.state.sourceData.priceUSD
            ) {
              this.setState({ noTicker: 'noTicker' })
            } else {
              let combo

              if (sd) {
                combo = { ...sd, ...responseJson.content[0][sym] }
              } else {
                combo = {
                  ...this.state.sourceData,
                  ...responseJson.content[0][sym],
                }
              }
              this.setState({
                sourceData: combo,
                noTicker: false,
                selectedStock: responseJson.content[0][this.state.symbol],
              })
            }
          })
          .catch((err) => {
            this.setState({ sourceData: sd })
            console.log(err)
          })
      }
    }
  }

  // excessSearchForDing = (sd) => {
  //   if (this.state.noTicker !== "noTicker") {
  //     if (window.location.pathname.split("/")[1] === "CompanyPage") {
  //       let sym = this.cutURL();
  //       let url = `${ENDPOINT}/search/ticker-bulk-live?search=${sym.toUpperCase()}`;
  //       Axios.get(url, {
  //         headers: {
  //           Authorization: "Bearer " + this.props.user?.idToken?.jwtToken,
  //         },
  //       })
  //         .then((res) => {
  //           res.json();
  //         })
  //         .then((responseJson) => {
  //           if (
  //             responseJson.content.length === 0 &&
  //             !this.state.sourceData.priceUSD
  //           ) {
  //             this.setState({ noTicker: "noTicker" });
  //           } else {
  //             let combo;

  //             if (sd) {
  //               combo = { ...sd, ...responseJson.content[0][sym] };
  //             } else {
  //               combo = {
  //                 ...this.state.sourceData,
  //                 ...responseJson.content[0][sym],
  //               };
  //             }
  //             this.setState({ sourceData: combo, noTicker: false });
  //           }
  //         });
  //     }
  //   }
  // };

  addCommas(x) {
    if (x === '') {
      return ''
    } else {
      let arr = x.toString().split('.')
      if (arr[1]) {
        return arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + arr[1]
      } else {
        return arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    }
  }

  getMarketStatus = () => {
    fetch(`${SearchEndpoint}/istradinghour`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((responseJson) => {
        this.setState({ canTrade: responseJson })
      })
      .catch((err) => {
        console.log('get market status error', err)
      })
  }

  fetchDataLoggedOut = () => {
    const symbol = this.state.symbol
    // let url = `${ENDPOINT}/risk-monkey/ticker/${symbol}`
    let url = `${SearchEndpoint}/ticker-details?ticker=${symbol}`
    fetch(url, {
      'Access-Control-Allow-Headers': {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Request-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        let noTicker = false
        if (Object.keys(responseJson).length < 3) {
          this.setState({ noTicker: false })
        } else {
          this.excessSearchForDing(responseJson)
        }
        // var sourceData = responseJson
        // // console.log('LENGTH:',Object.keys(sourceData))
        // this.setState({noTicker })
      })
      .catch((error) => {
        this.excessSearchForDing()
        console.log(error)
      })
  }
  fetchDataLoggedIn = () => {
    const symbol = this.state.symbol
    // let url = `${ENDPOINT}/risk-monkey/ticker/mobile/${symbol}`
    let url = `${SearchEndpoint}/ticker-details?ticker=${symbol}`
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      'Access-Control-Allow-Headers': {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Request-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
      },
    })
      .then((res) => {
        res.json()
      })
      .then((responseJson) => {
        let noTicker = false
        if (Object.keys(responseJson).length < 3) {
          this.setState({ noTicker: false })
        } else {
          this.excessSearchForDing(responseJson)
        }
        // var sourceData = responseJson
        // // console.log('LENGTH:',Object.keys(sourceData))
        // this.setState({noTicker })
      })
      .catch((error) => {
        this.excessSearchForDing()
        console.log(error)
      })
  }

  fetchSingleStockData = async () => {
    const res = await getSingleStockData2(this.props.symbol)
    this.setState({ stockData: res })
  }

  cutURL() {
    return window.location.pathname.split('/Dashboard/Trade/')[1]
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  setImage = () => {
    let symbol = this.state.symbol.toLowerCase()
    let url = `https://drivewealth.imgix.net/symbols/${symbol}.png`
    var http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    http.send()
    if (http.status != 404) {
      this.setState({
        imgURL: `https://drivewealth.imgix.net/symbols/${symbol}.png`,
      })
    } else {
      this.setState({ imgURL: `not-found` })
    }
  }

  search = (event) => {
    let searchText = event.target.value.toUpperCase()
    this.setState({ searchText }, () => {
      const { isSearching } = this.state
      if (isSearching) {
        return
      } else {
        this.setState({ isSearching: true })
        setTimeout(() => {
          this.setState({ isSearching: false }, this.onSubmitSearchMomentum)
        }, 2000)
      }
    })
  }

  onSubmitSearchMomentum = () => {
    const { searchText } = this.state
    this.setState({ dataLoading: true })
    // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchText}`;
    let url = `${SearchEndpoint}/ticker?search=${searchText.toUpperCase()}`

    fetch(url, {
      'Access-Control-Allow-Headers': {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Request-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        var data = responseJson.content
        for (var i = 0; i < data.length; i++) {
          var item = data[i]
        }
        this.setState({
          dataLoading: false,
          searchResults: data,
        })
      })
      .catch((err) => {
        this.setState({ searchResults: false })
        console.log(err)
      })
  }

  showResults = () => {
    let arr = []
    // this.state.searchResults.forEach(ele=>{
    for (let i = 0; i < 4; i++) {
      arr.push(
        <NavLink /* onClick={()=>this.setState({searchActive:false})} */
          onMouseLeave={() => this.setState({ hover: false })}
          onMouseEnter={() =>
            this.setState({ hover: this.state.searchResults[i] })
          }
          onClick={() =>
            this.setState({
              searchText: '',
              searchResults: [],
              sourceData: false,
              noTicker: 'loading',
            })
          }
          to={`/Dashboard/Trade/${this.state.searchResults[i].symbol}`}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor:
              this.state.hover === this.state.searchResults[i]
                ? '#f1f1f1'
                : '#fafafa',
            cursor: 'pointer',
            textDecoration: 'none',
            color: 'rgb(17,17,17)',
            borderBottom: 'solid',
            borderWidth: 0.25,
            borderColor: '#cccccc',
            padding: 8,
          }}
        >
          <div
            style={{
              width: '100%',
              margin: 'auto',
              display: 'flex',
              // justifyContent: "space-between",
            }}
          >
            <div style={{ width: '30%' }}>
              <p style={{ margin: 0, marginRight: '15px' }}>
                {this.state.searchResults[i].symbol}
              </p>
            </div>
            <div style={{ width: '70%' }}>
              <p style={{ margin: 0, textAlign: 'right' }}>
                {this.state.searchResults[i].company}
              </p>
            </div>
            {/* <div style={{ width: "30%" }}>
              <p style={{ margin: 0 }}>
                {this.state.searchResults[i].priceUSD
                  ? `$${this.state.searchResults[i].priceUSD.toFixed(2)}`
                  : "$00.00"}
              </p>
            </div> */}
            {/* <div style={{ width: "30%", textAlign: "right" }}>
              <img
                src={this.getArrow(this.state.searchResults[i].direction)}
                style={{
                  // marginTop: 5,
                  width: 20,
                  height: 20,
                }}
              />
            </div> */}
          </div>
        </NavLink>
      )
      if (i + 1 >= this.state.searchResults.length) {
        i += 5
      }
    }
    return (
      <div style={{ position: 'absolute', width: '100%', zIndex: 9 }}>
        {this.state.searchText.length > 0 ? arr : null}
      </div>
    )
  }

  getArrow = (direction) => {
    switch (direction) {
      case -1:
        return downArrow
      case 1:
        return upArrow
      case 2:
        return strongUpArrow
      case -2:
        return strongDownArrow
      default:
        return flatArrow
    }
  }

  alterWatchlist(symbol, addRemove) {
    fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify({ [addRemove]: [symbol] }),
    })
      .then(() => {
        this.props.refreshWl()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  cutURL() {
    return window.location.pathname.split('/Dashboard/Trade/')[1]
  }

  turnOnExternalHours = async () => {
    try {
      this.setState({ loading: true })
      let res = await Axios.get(`${ENDPOINT}/mobile/dw/patch/user?extend=1`, {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      console.log(res)
      await this.props.updateUserHoldings()
      this.setState({ loading: false, showExtendedHoursModal: false })
    } catch (error) {
      console.log(error)
    }
  }

  turnOffExternalHours = async () => {
    try {
      this.setState({ loading: true })
      let res = await Axios.get(`${ENDPOINT}/mobile/dw/patch/user?extend=0`, {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      console.log(res)
      await this.props.updateUserHoldings()
      this.setState({ loading: false, showExtendedHoursModal: false })
    } catch (error) {
      console.log(error)
    }
  }

  searchbar = () => {
    if (this.state.searchActive) {
      if (this.state.isSmallScreen) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '',
                borderRadius:
                  this.state.searchResults.length > 0 ? '20px 20px 0 0 ' : 20,
                backgroundColor: 'rgb(231,232,233)',
                paddingLeft: this.state.isSmallScreen ? 0 : 20,
                padding: this.state.isSmallScreen ? 8 : '',
              }}
            >
              <img
                src={SearchIcon}
                style={{ height: 15, marginRight: 5 }}
                onClick={() =>
                  this.setState({ searchActive: !this.state.searchActive })
                }
              />
              <input
                autoFocus
                value={this.state.searchText}
                id='headerSearch'
                onChange={(e) => this.search(e)}
                type='text'
                placeholder='Search'
                style={{
                  border: 'none',
                  outline: 'none',
                  backgroundColor: 'rgb(231,232,233)',
                }}
              />
            </div>
            {this.state.searchResults.length > 0 ? (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 999,
                  width: '60%',
                  marginTop: 20,
                }}
              >
                {this.showResults()}
              </div>
            ) : null}
          </div>
        )
      }
      return (
        <div style={{ marginRight: '1.5rem', position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 40,
              width: '',
              // borderRadius:
              //   this.state.searchResults.length > 0 ? "20px 20px 0 0 " : 20,
              borderRadius: '5px',
              backgroundColor: 'rgb(231,232,233)',
              paddingLeft: 20,
            }}
          >
            <img
              src={SearchGlass}
              style={{ height: 20, marginRight: 5 }}
              onClick={() =>
                this.setState({ searchActive: !this.state.searchActive })
              }
            />
            <input
              autoFocus
              value={this.state.searchText}
              id='headerSearch'
              onChange={(e) => this.search(e)}
              type='text'
              placeholder='Search'
              style={{
                border: 'none',
                outline: 'none',
                backgroundColor: 'rgb(231,232,233)',
                width: '90%',
              }}
            />
          </div>
          {this.state.searchResults.length > 0 ? (
            <div style={{ position: 'relative' }}>{this.showResults()}</div>
          ) : null}
        </div>
      )
    } else {
      if (this.state.isSmallScreen) {
        return (
          <div style={{ position: 'relative' }}>
            <img
              src={SearchGlass}
              style={{ height: 20 }}
              onClick={() => {
                this.setState({ searchActive: !this.state.searchActive })
              }}
            />
          </div>
        )
      }
      return (
        <div style={{ marginRight: '1.5rem', position: 'relative' }}>
          <img
            src={SearchGlass}
            style={{ height: 20, cursor: 'pointer' }}
            onClick={() => {
              this.setState({ searchActive: !this.state.searchActive })
            }}
          />
        </div>
      )
    }
  }

  // getAnalysis = async () => {
  //   await axios
  //     .get('https://ticker.allocaterite.com/ticker-bulk-live', {
  //       params: {
  //         search: this.state.symbol,
  //       },
  //       headers: {
  //         Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then((res) => {
  //       // this.setState({ this.state.selected: res.data.content[0].AAPL });
  //       if (res.data.content.length > 0) {
  //         this.setState({
  //           selectedStock: res.data.content[0][this.state.symbol],
  //         })
  //       }
  //     })
  // }

  tabs() {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <div
          style={this.state.activeTab === 'Chart' ? selectedTab : unSelectedTab}
          onClick={() => this.setState({ activeTab: 'Chart' })}
        >
          <p
            style={
              this.state.activeTab === 'Chart' ? selectedText : unSelectedText
            }
          >
            {this.props.t('chart')}
          </p>
        </div>
        <div
          style={
            this.state.activeTab === 'Forecast' ? selectedTab : unSelectedTab
          }
          onClick={() => this.setState({ activeTab: 'Forecast' })}
        >
          <p
            style={
              this.state.activeTab === 'Forecast'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('forecast')}
          </p>
        </div>
        <div
          style={
            this.state.activeTab === 'Analysis' ? selectedTab : unSelectedTab
          }
          onClick={() => this.setState({ activeTab: 'Analysis' })}
        >
          <p
            style={
              this.state.activeTab === 'Analysis'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('analysis')}
          </p>
        </div>
        <div
          style={this.state.activeTab === 'Stats' ? selectedTab : unSelectedTab}
          onClick={() => this.setState({ activeTab: 'Stats' })}
        >
          <p
            style={
              this.state.activeTab === 'Stats' ? selectedText : unSelectedText
            }
          >
            {this.props.t('stats')}
          </p>
        </div>
        <div
          style={
            this.state.activeTab === 'Options' ? selectedTab : unSelectedTab
          }
          onClick={() => this.setState({ activeTab: 'Options' })}
        >
          <p
            style={
              this.state.activeTab === 'Options' ? selectedText : unSelectedText
            }
          >
            {this.props.t('options')}
          </p>
        </div>
        {!this.state.selectedStock?.isCrypto && (
          <div
            style={
              this.state.activeTab === 'News' ? selectedTab : unSelectedTab
            }
            onClick={() => this.setState({ activeTab: 'News' })}
          >
            <p
              style={
                this.state.activeTab === 'News' ? selectedText : unSelectedText
              }
            >
              {this.props.t('news')}
            </p>
          </div>
        )}
      </div>
    )
  }

  showSelectedTab = () => {
    if (this.state.activeTab === 'Chart') {
      let selected = {
        symbol: this.state.sourceData.symbol,
        priceUSD: this.state.sourceData.priceUSD,
        company: this.state.sourceData.company,
        industry: this.state.sourceData.industry,
        imageURL: this.state.imgURL,
        returnForecast: this.state.sourceData.returnForecast,
        needOptimization: this.state.sourceData.needOptimization,
      }
      return (
        <Chart
          {...this.props}
          symbol={this.state.symbol}
          isDwSupported={this.state.sourceData.isDwSupported}
          isApexSupported={this.state.sourceData.isApexSupported}
          canTrade={this.state.canTrade}
          selected={selected}
          isSmallScreen={this.state.isSmallScreen}
          formatCurrency={this.formatCurrency}
        />
      )
    } else if (this.state.activeTab === 'Analysis')
      return (
        <Analysis
          {...this.props}
          selectedStock={this.state.selectedStock}
          isSmallScreen={this.state.isSmallScreen}
          formatCurrency={this.formatCurrency}
        />
      )
    else if (this.state.activeTab === 'Forecast')
      return (
        <Forecast
          {...this.props}
          sourceData={this.state.sourceData}
          isSmallScreen={this.state.isSmallScreen}
          formatCurrency={this.formatCurrency}
          stockData={this.state.stockData}
          forecastChartData={this.state.forecastChartData}
        />
      )
    else if (this.state.activeTab === 'Stats')
      return (
        <Stats
          {...this.props}
          selectedStock={this.state.selectedStock}
          stockData={this.state.stockData}
          isSmallScreen={this.state.isSmallScreen}
        />
      )
    else if (this.state.activeTab === 'News')
      return (
        <Events
          t={this.props.t}
          stockData={this.state.stockData}
          isSmallScreen={this.state.isSmallScreen}
        />
      )
    else if (this.state.activeTab === 'Options')
      return (
        <StockOptions
          t={this.props.t}
          stockData={this.state.stockData}
          isSmallScreen={this.state.isSmallScreen}
          user={this.props.user}
          stockSymbol={this.state.symbol}
          alpacaStatus={this.props.alpacaStatus}
          stockSource={this.state.sourceData}
          allAccounts={this.props.allAccounts}
          restartInterval={this.restartInterval}
          stopInterval={this.stopInterval}
        />
      )
    else return null
  }

  addButton = () => {
    if (this.props.user) {
      if (this.state.sourceData.watchlisted) {
        return (
          <div
            id={'CP3_AddRemoveButton'}
            onClick={() => {
              let sourceData = this.state.sourceData
              sourceData.watchlisted = !this.state.sourceData.watchlisted
              this.alterWatchlist(this.state.symbol, 'delete')
              this.setState({ sourceData })
            }}
            style={{
              height: 40,
              cursor: 'pointer',
              borderRadius: '29px',
              paddingTop: '1%',
              paddingBottom: '1%',
              paddingLeft: '3%',
              paddingRight: '3%',
              border: 'solid 1px rgb(227,154,146)',
              minWidth: 120,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 'smaller',
                opacity: 1,
                fontSize: '12px',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: '0.34px',
                textAlign: 'center',
                color: 'rgb(227,154,146)',
              }}
            >
              Remove from Watchlist
            </p>
          </div>
        )
      } else {
        return (
          <div
            onClick={() => {
              let sourceData = this.state.sourceData
              sourceData.watchlisted = !this.state.sourceData.watchlisted
              this.alterWatchlist(this.state.symbol, 'add')
              this.setState({ sourceData })
            }}
            style={{
              cursor: 'pointer',
              height: 40,
              borderRadius: '29px',
              paddingTop: '1%',
              paddingBottom: '1%',
              paddingLeft: '3%',
              paddingRight: '3%',
              border: 'solid 1px #669890',
              minWidth: 120,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 'smaller',
                opacity: 1,
                fontSize: '12px',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: '0.34px',
                textAlign: 'center',
                color: '#669890',
              }}
            >
              Add to Watchlist
            </p>
          </div>
        )
      }
    } else {
      if (this.state.isSmallScreen) {
        return (
          <NavLink
            to='/Login'
            /*onClick={()=>this.alterWatchlist(this.state.symbol, 'add')}*/ style={{
              cursor: 'pointer',
              borderRadius: '29px',
              paddingTop: '1%',
              paddingBottom: '1%',
              paddingLeft: '3%',
              paddingRight: '3%',
              border: 'solid 1px #669890',
              minWidth: 120,
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 22,
                opacity: 1,
                fontSize: '12px',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: '0.34px',
                textAlign: 'center',
                color: '#669890',
              }}
            >
              Add to Watchlist
            </p>
          </NavLink>
        )
      } else {
        return (
          <NavLink
            to='/Login'
            /*onClick={()=>this.alterWatchlist(this.state.symbol, 'add')}*/ style={{
              cursor: 'pointer',
              borderRadius: '29px',
              paddingTop: '1%',
              paddingBottom: '1%',
              paddingLeft: '3%',
              paddingRight: '3%',
              border: 'solid 1px #669890',
              minWidth: 120,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 22,
                opacity: 1,
                fontSize: '12px',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: '0.34px',
                textAlign: 'center',
                color: '#669890',
              }}
            >
              Add to Watchlist
            </p>
          </NavLink>
        )
      }
    }
  }

  toggleShowAlertsForm = () => {
    this.setState({ showAlertsForm: !this.state.showAlertsForm })
  }

  wlButtons = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {this.props.user ? (
          <>
            {this.state.sourceData.watchlisted ? (
              // <img
              //   src={WatchListIconActive}
              //   height='25px'
              //   width='25px'
              // onClick={() => {
              //   let sourceData = this.state.sourceData
              //   sourceData.watchlisted = !this.state.sourceData.watchlisted
              //   this.alterWatchlist(this.state.symbol, 'delete')
              //   this.setState({ sourceData })
              // }}
              //   style={{ cursor: 'pointer' }}
              // />
              <div
                style={{
                  display: 'flex',
                  borderRadius: 15,
                  backgroundColor: '#3627e4',
                  padding: 10,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // let sourceData = this.state.sourceData
                  // sourceData.watchlisted = !this.state.sourceData.watchlisted
                  // this.alterWatchlist(this.state.symbol, 'delete')
                  // this.setState({ sourceData })
                  this.setState({ watchlistRemoveModal: true, type: 'remove' })
                }}
              >
                <img src={RemoveCircle} width={20} height={20} />
                <p
                  style={{
                    margin: 0,
                    color: 'white',
                    fontSize: 15,
                    marginLeft: 5,
                  }}
                >
                  {this.props.t('removefromwatchlist')}
                </p>
              </div>
            ) : (
              // <img
              //   src={WatchListIcon}
              //   height='25px'
              //   width='25px'
              // onClick={() => {
              //   let sourceData = this.state.sourceData
              //   sourceData.watchlisted = !this.state.sourceData.watchlisted
              //   this.alterWatchlist(this.state.symbol, 'add')
              //   this.setState({ sourceData })
              // }}
              //   style={{ cursor: 'pointer' }}
              // />
              <div
                style={{
                  display: 'flex',
                  borderRadius: 15,
                  backgroundColor: '#3627e4',
                  padding: 10,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // let sourceData = this.state.sourceData
                  // sourceData.watchlisted = !this.state.sourceData.watchlisted
                  // this.setState({ sourceData })
                  // this.alterWatchlist(this.state.symbol, 'add')
                  // let sourceData = this.state.sourceData
                  // sourceData.watchlisted = !this.state.sourceData.watchlisted
                  // this.setState({ sourceData })
                  this.setState({ watchlistModal: true, type: 'add' })
                }}
              >
                <img src={PlusCircle} width={20} height={20} />
                <p
                  style={{
                    margin: 0,
                    color: 'white',
                    fontSize: 15,
                    marginLeft: 5,
                  }}
                >
                  {this.props.t('addtowatchlist')}
                </p>
              </div>
            )}
            <Dialog
              visible={this.state.watchlistModal}
              header={
                this.state.type === 'add'
                  ? `${this.props.t('add')} ${this.state.symbol} ${this.props.t(
                      'towatchlist'
                    )}`
                  : `${this.props.t('remove')} ${
                      this.state.symbol
                    } ${this.props.t('fromwatchlist')}`
              }
              onHide={() => this.setState({ watchlistModal: false })}
              style={{ width: this.state.isSmallScreen ? '90vw' : '20vw' }}
            >
              <WatchlistSelection
                names={this.props.myWatchlistGroup?.names}
                symbol={this.state.symbol}
                isSmallScreen={this.state.isSmallScreen}
                user={this.props.user}
                refreshWl={this.props.refreshWl}
                t={this.props.t}
                type={this.state.type}
                myWatchlistGroup={this.props.myWatchlistGroup?.watchlist}
                hideModal={() =>
                  this.setState({
                    watchlistModal: false,
                  })
                }
              />
            </Dialog>
            <Dialog
              visible={this.state.watchlistRemoveModal}
              header={
                this.state.type === 'add'
                  ? `Add ${this.state.symbol} to watchlist`
                  : `Remove ${this.state.symbol} from watchlist`
              }
              onHide={() => this.setState({ watchlistRemoveModal: false })}
              style={{ width: this.state.isSmallScreen ? '90vw' : '20vw' }}
            >
              <WatchlistSelectionRemove
                names={this.props.myWatchlistGroup?.names}
                symbol={this.state.symbol}
                isSmallScreen={this.state.isSmallScreen}
                user={this.props.user}
                refreshWl={this.props.refreshWl}
                t={this.props.t}
                type={this.state.type}
                myWatchlistGroup={this.props.myWatchlistGroup?.watchlist}
                hideModal={() =>
                  this.setState({
                    watchlistModal: false,
                  })
                }
              />
            </Dialog>
          </>
        ) : (
          <NavLink to='/Login'>
            <img src={WatchListIcon} height='25px' width='25px' />
          </NavLink>
        )}
        {this.state.alertsData ? (
          <>
            {this.state.alertsData.has(this.state.symbol) ? (
              <img
                src={BellActiveIcon}
                height='25px'
                width='25px'
                style={{ cursor: 'pointer', marginLeft: '15px' }}
                onClick={() => this.toggleShowAlertsForm()}
              />
            ) : (
              <img
                src={BellIcon}
                height='25px'
                width='25px'
                style={{ cursor: 'pointer', marginLeft: '15px' }}
                onClick={() => this.toggleShowAlertsForm()}
              />
            )}
          </>
        ) : null}
      </div>
    )
    // if (this.state.isSmallScreen) {
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         width: this.state.isSmallScreen ? "100%" : "25%",
    //         marginTop: 20,
    //         marginBottom: 20,
    //       }}
    //     >
    //       {this.props.user && this.state.sourceData.isDwSupported ? (
    //         <div
    //           id="CP3_TradeButton"
    //           onClick={() => {
    //             this.getMarketStatus();
    //             this.setState({ showTrade: true });
    //           }}
    //           style={{
    //             cursor: "pointer",
    //             borderRadius: "29px",
    //             paddingTop: "1%",
    //             paddingBottom: "1%",
    //             paddingLeft: "3%",
    //             paddingRight: "3%",
    //             border: "solid 1px #669890",
    //             minWidth: 120,
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //           }}
    //         >
    //           <p
    //             style={{
    //               margin: 0,
    //               fontSize: 22,
    //               opacity: 1,
    //               fontSize: "12px",
    //               fontWeight: "bold",
    //               fontStretch: "normal",
    //               fontStyle: "normal",
    //               letterSpacing: "0.34px",
    //               textAlign: "center",
    //               color: "#669890",
    //             }}
    //           >
    //             Trade
    //           </p>
    //         </div>
    //       ) : null}
    //       <NavLink
    //         id="CP3_WatchlistButton"
    //         to="/Login"
    //         style={{
    //           cursor: "pointer",
    //           borderRadius: "29px",
    //           paddingTop: "1%",
    //           paddingBottom: "1%",
    //           paddingLeft: "3%",
    //           paddingRight: "3%",
    //           border: "solid 1px rgb(36, 42, 56)",
    //           marginRight: "1.5%",
    //           minWidth: 120,
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //         }}
    //       >
    //         <p
    //           style={{
    //             margin: 0,
    //             fontSize: 22,
    //             opacity: 1,
    //             fontSize: "12px",
    //             fontWeight: "bold",
    //             fontStretch: "normal",
    //             fontStyle: "normal",
    //             letterSpacing: "0.34px",
    //             textAlign: "center",
    //             color: "rgb(36, 42, 56)",
    //           }}
    //         >
    //           My Watchlist
    //         </p>
    //       </NavLink>
    //       {this.addButton()}
    //     </div>
    //   );
    // } else {
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "flex-end",
    //         width: "70%",
    //         marginTop: 20,
    //         marginBottom: 20,
    //       }}
    //     >
    //       {this.props.user && this.state.sourceData.isDwSupported ? (
    //         <div
    //           id="CP3_TradeButton"
    //           onClick={() => {
    //             this.getMarketStatus();
    //             this.setState({ showTrade: true });
    //           }}
    //           style={{
    //             cursor: "pointer",
    //             borderRadius: "29px",
    //             paddingTop: "1%",
    //             paddingBottom: "1%",
    //             paddingLeft: "3%",
    //             paddingRight: "3%",
    //             border: "solid 1px #669890",
    //             minWidth: 120,
    //             marginRight: 5,
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             height: "100%",
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             height: 40,
    //           }}
    //         >
    //           <p
    //             style={{
    //               margin: 0,
    //               fontSize: "smaller",
    //               opacity: 1,
    //               fontSize: "12px",
    //               fontWeight: "bold",
    //               fontStretch: "normal",
    //               fontStyle: "normal",
    //               letterSpacing: "0.34px",
    //               textAlign: "center",
    //               color: "#669890",
    //             }}
    //           >
    //             Trade
    //           </p>
    //         </div>
    //       ) : null}
    //       <NavLink
    //         id="CP3_WatchlistButton"
    //         to="/Login"
    //         style={{
    //           cursor: "pointer",
    //           borderRadius: "29px",
    //           paddingTop: "1%",
    //           paddingBottom: "1%",
    //           paddingLeft: "3%",
    //           paddingRight: "3%",
    //           border: "solid 1px rgb(36, 42, 56)",
    //           marginRight: 5,
    //           minWidth: 120,
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           height: "100%",
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           height: 40,
    //         }}
    //       >
    //         <p
    //           style={{
    //             margin: 0,
    //             fontSize: "smaller",
    //             opacity: 1,
    //             fontSize: "12px",
    //             fontWeight: "bold",
    //             fontStretch: "normal",
    //             fontStyle: "normal",
    //             letterSpacing: "0.34px",
    //             textAlign: "center",
    //             color: "rgb(36, 42, 56)",
    //           }}
    //         >
    //           My Watchlist
    //         </p>
    //       </NavLink>
    //       {this.addButton()}
    //     </div>
    //   );
    // }
  }

  closeTrade = () => {
    this.setState({ showTrade: false })
  }

  checkLogo = (symbol) => {
    let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
    var http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    http.send()
    if (http.status != 404) {
      return (
        <div
          style={{
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgb(255,255,255)',
            borderRadius: 7,
            overflow: 'hidden',
            width: 100,
            height: 100,
            justifySelf: 'flex-end',
          }}
        >
          <img
            // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
            src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
            onError='this.onerror=null; this.remove();'
            style={{ objectFit: 'contain', width: 100, height: 100 }}
          />
        </div>
      )
    } else {
      return (
        <div
          style={{
            borderRadius: 8,
            padding: 10,
            backgroundColor: 'rgb(255,255,255)',
          }}
        >
          <p
            style={{
              fontSize: 32,
              fontWeight: 'bold',
              color: '#242A38',
              margin: 0,
              textAlign: 'center',
            }}
          >
            {symbol}
          </p>
        </div>
      )
    }
  }

  toggleDescriptionModal = () => {
    this.setState({ descriptionModal: false })
  }

  showErrorMessage() {
    if (this.state.errorMessage) {
      return (
        <div className='error-message-container'>
          <p className='error-message'>{this.state.errorMessage}</p>
          <Button
            className='error-message-delete-btn'
            icon='pi pi-times'
            onClick={() => {
              this.setState({ errorMessage: null })
            }}
          />
        </div>
      )
    }
  }

  showSuccessMessage() {
    if (this.state.successMessage) {
      return (
        <div className='success-message-container'>
          <div className='succes-messages'>
            <p className='success-message'>{this.state.successMessage}</p>
            <p className='success-message'>{this.state.sm2}</p>
            <p className='success-message'>{this.state.sm3}</p>
            <p className='success-message'>{this.state.sm4}</p>
          </div>
          <Button
            className='success-message-delete-btn'
            icon='pi pi-times'
            onClick={() => {
              this.setState({ successMessage: null })
            }}
          />
        </div>
      )
    }
  }

  render() {
    // const orderTypeValue = order_type ?? null
    // const totalValue = total ?? null
    // const investValue = invest ?? null

    if (this.state.isSmallScreen !== null) {
      if (this.state.noTicker === 'loading') {
        return (
          <div style={{ height: '100%' }}>
            {/* <UserDashboard {...this.props} showOnlyNavBar='true' /> */}
            <div
              style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loader logoLoader={true} />
            </div>
          </div>
        )
      } else if (!this.state.noTicker && this.state.sourceData) {
        const { sourceData } = this.state
        const { currentPrice } = sourceData
        let currentPriceInt
        let hiPriceInt
        let loPriceInt
        let openPriceInt
        let prevCloseChange
        let prevClosePrice
        let dollarChange
        let textColor
        if (sourceData) {
          if (currentPrice) {
            currentPriceInt = parseFloat(currentPrice.currentPrice)
            hiPriceInt = parseFloat(currentPrice.hiPrice)
            loPriceInt = parseFloat(currentPrice.loPrice)
            openPriceInt = parseFloat(currentPrice.openPrice)
            prevCloseChange = parseFloat(currentPrice.prevClose)
            prevClosePrice = parseFloat(currentPrice.closePrice)
            dollarChange = currentPriceInt - prevClosePrice
            textColor = dollarChange < 0 ? 'rgb(181,23,28)' : 'rgb(32,179,125)'
          }
          let selected = {
            symbol: sourceData.symbol,
            priceUSD: sourceData.priceUSD,
            company: sourceData.company,
            industry: sourceData.industry,
            imageURL: this.state.imgURL,
            returnForecast: sourceData.returnForecast,
            needOptimization: sourceData.needOptimization,
            isCrypto: sourceData.isCrypto,
          }

          return (
            <div
              style={{
                width: this.state.isSmallScreen ? '100%' : '93%',
                left: this.state.isSmallScreen ? '0' : '7%',
                height: '100%',
                backgroundColor: '#f7f6fd',
                position: 'fixed',
                overflowY: 'scroll',
              }}
            >
              {/* <UserDashboard {...this.props} showOnlyNavBar='true' /> */}
              <div
                style={{
                  paddingTop: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  backgroundColor: 'rgb(247, 247, 247)',
                  paddingBottom: 175,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: this.state.isSmallScreen ? '90%' : '100%',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        width: '95%',
                        margin: 'auto',
                        marginBottom: '25px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: this.state.isSmallScreen
                            ? 'center'
                            : 'space-between',
                          alignItems: 'center',
                          flexDirection: this.state.isSmallScreen
                            ? 'column'
                            : 'row',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            flexDirection: this.state.isSmallScreen
                              ? 'column'
                              : 'row',
                          }}
                        >
                          {this.state.isSmallScreen ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                alignItems: 'center',
                                marginBottom: '15px',
                              }}
                            >
                              {this.wlButtons()}
                            </div>
                          ) : null}
                          <div
                            style={{
                              width: this.state.isSmallScreen ? '100%' : '60%',
                            }}
                          >
                            <SearchBar
                              user={this.props.user}
                              isSmallScreen={this.state.isSmallScreen}
                              t={this.props.t}
                              myWatchlist={this.props.myWatchlist}
                              refreshWl={this.props.refreshWl}
                              history={this.props.history}
                              myWatchlistGroup={this.props.myWatchlistGroup}
                            />
                          </div>
                        </div>

                        {!this.state.isSmallScreen ? (
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {this.wlButtons()}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        width: '95%',
                        display: 'flex',
                        flexDirection: this.state.isSmallScreen
                          ? 'column'
                          : 'row',
                        justifyContent: this.state.isSmallScreen
                          ? 'center'
                          : 'space-between',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          width: this.state.isSmallScreen ? '100%' : '60%',
                          height: '100%',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 10,
                          }}
                        >
                          <div style={{ display: 'block' }}>
                            <div style={{ display: 'flex', zIndex: 0 }}>
                              <div
                                style={{
                                  display: 'flex',
                                  cursor: 'pointer',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: 'rgb(255,255,255)',
                                  borderRadius: 7,
                                  overflow: 'hidden',
                                  width: this.state.isSmallScreen ? 80 : 100,
                                  height: this.state.isSmallScreen ? 80 : 100,
                                  justifySelf: 'flex-end',
                                  marginRight: 15,
                                }}
                              >
                                {this.state.imgURL === 'not-found' ? (
                                  <div
                                    style={{
                                      objectFit: 'contain',
                                      width: this.state.isSmallScreen ? 60 : 80,
                                      height: this.state.isSmallScreen
                                        ? 60
                                        : 80,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      backgroundColor: 'rgb(17,17,17)',
                                      borderRadius: 10,
                                    }}
                                  >
                                    <p
                                      style={{
                                        margin: 0,
                                        fontSize: 32,
                                        fontWeight: 'bold',
                                        color: 'rgb(255,255,255)',
                                      }}
                                    >
                                      {this.state.sourceData.symbol[0]}
                                    </p>
                                  </div>
                                ) : (
                                  <img
                                    // src={this.checkLogo(ele.symbol)?`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`:NoImage}
                                    src={this.state.imgURL}
                                    style={{
                                      objectFit: 'contain',
                                      width: this.state.isSmallScreen ? 60 : 80,
                                      height: this.state.isSmallScreen
                                        ? 60
                                        : 80,
                                      padding: 10,
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  margin: 'auto',
                                  marginLeft: this.state.isSmallScreen ? 0 : 20,
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: this.state.isSmallScreen
                                      ? '15px'
                                      : '28px',
                                    fontFamily: 'Akkurat-Bold',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 1,
                                    letterSpacing: '1.2px',
                                    textAlign: 'left',
                                    color: '#242A38',
                                    margin: 0,
                                    marginBottom: 10,
                                  }}
                                >
                                  {sourceData.company} ({this.props.symbol})
                                </p>
                                {this.state.stockData?.description && (
                                  <>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontSize: 15,
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          descriptionModal: true,
                                        })
                                      }}
                                    >
                                      {`${sourceData.company} >`}
                                    </p>
                                    {/* {this.state.stockData?.description.length <
                                    50 ? (
                                      <p style={{ margin: 0, fontSize: 15 }}>
                                        {this.state.stockData?.description}
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: 15,
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            descriptionModal: true,
                                          })
                                        }}
                                      >
                                        {this.state.stockData?.description.substring(
                                          0,
                                          50
                                        )}
                                        {'... >'}
                                      </p>
                                    )} */}
                                  </>
                                )}
                                {/* <p style={{ margin: 0, fontSize: 12 }}>
                                  {this.state.stockData?.description}
                                </p> */}
                                {this.state.selectedStock &&
                                this.state.sourceData?.isDwSupported ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      padding: '5px',
                                      marginTop: '10px',
                                    }}
                                  >
                                    <p
                                      style={{
                                        margin: 0,
                                        marginRight: '12px',
                                        fontSize: this.state.isSmallScreen
                                          ? '10px'
                                          : '12px',
                                        color: 'rgba(128,128,128,.5)',
                                      }}
                                    >
                                      {this.props.t('open')}:{' '}
                                      {this.formatCurrency(
                                        this.state.selectedStock.open
                                      )}
                                    </p>
                                    <p
                                      style={{
                                        margin: 0,
                                        marginRight: '12px',
                                        fontSize: this.state.isSmallScreen
                                          ? '10px'
                                          : '12px',
                                        color: 'rgba(128,128,128,.5)',
                                      }}
                                    >
                                      {this.props.t('high')}:{' '}
                                      {this.formatCurrency(
                                        this.state.selectedStock.high
                                      )}
                                    </p>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontSize: this.state.isSmallScreen
                                          ? '10px'
                                          : '12px',
                                        marginRight: '12px',
                                        color: 'rgba(128,128,128,.5)',
                                      }}
                                    >
                                      {this.props.t('low')}:{' '}
                                      {this.formatCurrency(
                                        this.state.selectedStock.low
                                      )}
                                    </p>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            {this.state.selectedStock?.isEarningsUpcoming && (
                              <div style={{ margin: '15px 0' }}>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>
                                  {this.props.t('earningsexpectedon')}{' '}
                                  {moment
                                    .unix(
                                      this.state.selectedStock?.nextEarningsDate
                                    )
                                    .format('MMM D, YYYY')}
                                </p>
                              </div>
                            )}
                            <div style={{ display: 'flex', padding: '15px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    margin: 'auto',
                                    fontSize: this.state.isSmallScreen
                                      ? '36px'
                                      : '48px',
                                    fontFamily: 'Akkurat-Bold',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: '1.09',
                                    letterSpacing: 'normal',
                                    textAlign: 'left',
                                    color: '',
                                  }}
                                >
                                  $
                                  {sourceData.tickerCurrency === 'USD' &&
                                  sourceData.price
                                    ? sourceData.price <= 0.00009
                                      ? this.addCommas(
                                          sourceData.price.toFixed(8)
                                        )
                                      : sourceData.price < 1
                                      ? this.addCommas(
                                          sourceData.price.toFixed(4)
                                        )
                                      : this.addCommas(
                                          sourceData.price.toFixed(2)
                                        )
                                    : sourceData.priceUSD
                                    ? sourceData.priceUSD <= 0.00009
                                      ? this.addCommas(
                                          sourceData.priceUSD.toFixed(8)
                                        )
                                      : sourceData.priceUSD < 1
                                      ? this.addCommas(
                                          sourceData.priceUSD.toFixed(4)
                                        )
                                      : this.addCommas(
                                          sourceData.priceUSD.toFixed(2)
                                        )
                                    : '0.00'}
                                </p>
                              </div>
                              <div
                                style={{
                                  fontSize: this.state.isSmallScreen
                                    ? '15px'
                                    : '25px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginLeft: '15px',
                                }}
                              >
                                {sourceData.tickerCurrency === 'USD' &&
                                sourceData.price ? (
                                  <PL
                                    percentage={
                                      this.state.sourceData.priceChange
                                    }
                                    value={
                                      this.state.sourceData.priceChangeValue
                                    }
                                    small={sourceData.price < 1}
                                  />
                                ) : sourceData.priceUSD ? (
                                  <PL
                                    percentage={
                                      this.state.sourceData.priceChange
                                    }
                                    value={
                                      this.state.sourceData.priceChangeValue
                                    }
                                    small={sourceData.priceUSD < 1}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: '100%',
                            height: 'auto',
                            margin: 'auto',
                            marginBottom: 25,
                          }}
                        >
                          {this.tabs()}
                        </div>
                        <div style={{ width: '100%', height: '100%' }}>
                          <div
                            style={{
                              width: this.state.isSmallScreen ? '100%' : '100%',
                              height: '100%',
                              display: this.state.isSmallScreen
                                ? 'block'
                                : 'flex',
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                            >
                              {this.state.sourceData
                                ? this.showSelectedTab()
                                : null}
                            </div>
                            {/* <div
                          style={{
                            width: this.state.isSmallScreen ? "100%" : "35%",
                          }}
                        >
                          <BuyOrSellForm
                            {...this.props}
                            symbol={this.state.symbol}
                            isDwSupported={this.state.sourceData.isDwSupported}
                            isApexSupported={
                              this.state.sourceData.isApexSupported
                            }
                            canTrade={this.state.canTrade}
                            selected={selected}
                            isSmallScreen={this.state.isSmallScreen}
                          />
                        </div> */}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: this.state.isSmallScreen ? '100%' : '30%',
                        }}
                      >
                        <BuyOrSellForm
                          {...this.props}
                          symbol={this.state.symbol}
                          isDwSupported={this.state.sourceData.isDwSupported}
                          isApexSupported={
                            this.state.sourceData.isApexSupported
                          }
                          refreshPrices={this.refreshPrices}
                          isIbSupported={this.state.sourceData.isIbSupported}
                          type={this.state.sourceData.type}
                          canTrade={this.state.canTrade}
                          selected={selected}
                          isSmallScreen={this.state.isSmallScreen}
                          formatCurrency={this.formatCurrency}
                          toggleSuccessMessage={this.toggleSuccessMessage}
                          toggleErrorMessage={this.toggleErrorMessage}
                          allAccounts={this.props.allAccounts}
                          getAllAccountsInfo={this.props.getAllAccountsInfo}
                          showExtendedHoursModal={() =>
                            this.setState({ showExtendedHoursModal: true })
                          }
                          orderType={this.state.prefillOrderType}
                          AmountTotal={this.state.prefillTotal}
                          investMode={this.state.prefillInvest}
                          limitedPrice={this.state.prefillLimitPrice}
                          activeTab={this.state.prefillActiveTab}
                          usingPrefill={this.state.usingPrefill}
                          disablePrefillData={() => {
                            console.log('calling disble prefil')
                            this.setState({ usingPrefill: false })
                          }}
                        />
                        {this.showErrorMessage()}
                        {this.showSuccessMessage()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.selectedStock ? (
                <Dialog
                  visible={this.state.showAlertsForm}
                  onHide={this.toggleShowAlertsForm}
                  style={{
                    width: this.state.isSmallScreen ? '95vw' : '35vw',
                    maxHeight: this.state.isSmallScreen ? '80vh' : '70vh',
                  }}
                  header={
                    <p className='title'>
                      {this.state.selectedStock.symbol}{' '}
                      <span>
                        {this.formatCurrency(this.state.selectedStock.price)}
                      </span>
                    </p>
                  }
                >
                  <AlertsForm
                    selectedStock={this.state.selectedStock}
                    user={this.props.user}
                    formatCurrency={this.formatCurrency}
                    alertsData={this.state.alertsData?.get(
                      this.state.selectedStock.symbol
                    )}
                    getStockAlerts={this.getStockAlerts}
                    isSmallScreen={this.state.isSmallScreen}
                    t={this.props.t}
                  />
                </Dialog>
              ) : null}
              <Dialog
                visible={this.state.descriptionModal}
                onHide={this.toggleDescriptionModal}
                style={{
                  width: this.state.isSmallScreen ? '95vw' : '75vw',
                  maxHeight: this.state.isSmallScreen ? '80vh' : '70vh',
                }}
                header={
                  <p className='title'>{this.state.sourceData?.company}</p>
                }
              >
                <p style={{ fontSize: 15, textAlign: 'justify' }}>
                  {this.state.stockData?.description}
                </p>
              </Dialog>
              <Dialog
                header={
                  <div className='unremoveable-header-container'>
                    <p className='unremoveable-header'>
                      {this.props.t('extended_hours_trading_for_stock')}
                    </p>
                    <p
                      onClick={() => {
                        this.setState({ showExtendedHoursModal: false })
                      }}
                      className='unremoveable-signout'
                    >
                      X
                    </p>
                  </div>
                }
                visible={this.state.showExtendedHoursModal}
                style={{ width: '50vw' }}
                closeOnEscape={false}
                closable={false}
              >
                <div className='extended-hours-agreement-container'>
                  {this.props.dwExtendedHours === 0 ? (
                    <p>{this.props.t('enable_extended_trading_description')}</p>
                  ) : (
                    <p>
                      {this.props.t('disable_extended_trading_description')}
                    </p>
                  )}
                  {this.props.dwExtendedHours === 0 && (
                    <div className='agreement-row'>
                      <Checkbox
                        onChange={(e) => {
                          this.setState({
                            extendedHoursAgreement:
                              !this.state.extendedHoursAgreement,
                          })
                        }}
                        checked={this.state.extendedHoursAgreement}
                      ></Checkbox>
                      <p className='agreement-label'>
                        {this.props.t('i_agree_to_the')}{' '}
                        <a
                          href='https://legal.drivewealth.com/extended-hours-trading-disclosures'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {this.props.t(
                            'drivewealth_extended_hours_trading_disclosures'
                          )}
                        </a>
                      </p>
                    </div>
                  )}
                  <div className='button-container'>
                    {this.props.dwExtendedHours === 0 ? (
                      <Button
                        label={this.props.t('disable_for_now')}
                        className='dw-apex-submit-btn'
                        disabled={!this.state.extendedHoursAgreement}
                        loading={this.state.loading}
                        onClick={() => this.turnOnExternalHours()}
                      />
                    ) : (
                      <Button
                        label='Disable for Now'
                        className='dw-apex-submit-btn'
                        loading={this.state.loading}
                        onClick={() => this.turnOffExternalHours()}
                      />
                    )}
                  </div>
                </div>
              </Dialog>
            </div>
          )
        }
      } else {
        return (
          <div>
            {/* <Header
              cp={true}
              user={this.props.user}
              signOut={this.props.signOut}
            /> */}
            <div
              style={{
                marginTop: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: 'rgb(247, 247, 247)',
                height: 700,
              }}
            >
              {/* <div style={{ width: "80%", margin: "auto" }}>
                <h1>
                  We do not currently support '{this.state.symbol}'.
                </h1>
                <h3>
                  Please make sure ‘{this.state.symbol}’ is the right
                  symbol. Our team will look to find all information about ‘
                  {this.state.symbol}’ and add it to our system.
                </h3>
              </div> */}
              <div style={{ width: '80%', margin: 'auto' }}>
                <h1 style={{ textAlign: 'center' }}>
                  Request Timed out. Please try again.
                </h1>
                <h3>
                  {/* Please make sure ‘{this.state.symbol}’ is the right
                  symbol. Our team will look to find all information about ‘
                  {this.state.symbol}’ and add it to our system. */}
                </h3>
              </div>
            </div>
            {/* <Footer isSmallScreen={this.state.isSmallScreen} /> */}
          </div>
        )
      }
    } else return null
  }
}

export default withRouter(Cp3)
