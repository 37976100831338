import React, { Component } from 'react'
import PL from '../PL'
import '../../../../scss/pages/account-page/non-product-account-info/NonProductBasicInfo.scss'

export default class NonProductBasicInfo extends Component {
  displayNonProductBasicInfo() {
    const name = this.props.selectedAccount.name
      ? this.props.selectedAccount.name
      : '-'
    const totalAccountValue =
      this.props.selectedAccount.balances[0].virtualAccountValue !== null
        ? this.props.selectedAccount.balances[0].virtualAccountValue?.toFixed(2)
        : '-'
    const equityValue =
      this.props.selectedAccount.balances[0].equityValue !== null
        ? this.props.selectedAccount.balances[0].equityValue?.toFixed(2)
        : '-'
    const netPL =
      this.props.selectedAccount.balances[0]['profit-loss'] !== null
        ? this.props.selectedAccount.balances[0]['profit-loss']?.toFixed(2)
        : '-'

    const buyingPower = this.props.selectedAccount.balances[0]?.buyingPower
      ? this.props.selectedAccount.balances[0]?.buyingPower?.toFixed(2)
      : '-'

    const cashAvailableForWithdraw = this.props.selectedAccount.balances[0]
      ?.cashAvailableForWithdrawal
      ? this.props.selectedAccount.balances[0]?.cashAvailableForWithdrawal?.toFixed(
          2
        )
      : '-'

    const settledCash = this.props.selectedAccount.balances[0]?.fundsInvestable
      ? this.props.selectedAccount.balances[0]?.fundsInvestable?.toFixed(2)
      : '-'

    const cashSettlementDate = this.props.selectedAccount?.balances[0]
      ?.settlementDate
      ? this.props.selectedAccount?.balances[0]?.settlementDate
      : '-'

    return (
      <div className='non-product-basic-info-container'>
        <div className='header-container'>
          <p className='header'>
            {this.props.t(`${this.props.selectedName.toLowerCase()}account`)}
          </p>
          <p
            className={`total-value ${
              totalAccountValue < 0 ? 'negative' : 'positive'
            }`}
          >
            {this.props.formatCurrency(totalAccountValue)}
          </p>
        </div>
        <div className='details-container'>
          <div className='details-content'>
            <div className='details-row first'>
              <p className='details-label'>{this.props.t('equityvalue')}: </p>
              <p className='details-value'>
                {this.props.formatCurrency(equityValue)}
              </p>
            </div>
            <div className='details-row first'>
              <p className='details-label'>{this.props.t('netpl')}: </p>
              <p className='details-value'>
                {netPL === '-' ? (
                  <p style={{ margin: 0 }}>{'-'}</p>
                ) : (
                  <PL
                    formatCurrency={this.props.formatCurrency}
                    value={netPL}
                  />
                )}
              </p>
            </div>
            <div className='details-row first'>
              <p className='details-label'>{this.props.t('buyingpower')}: </p>
              <p className='details-value'>
                {buyingPower === '-'
                  ? '-'
                  : this.props.formatCurrency(buyingPower)}
              </p>
            </div>
            {this.props.selectedName === 'STOCK' && (
              <div className='details-row first'>
                <p className='details-label'>{this.props.t('settledcash')}:</p>
                <p className='details-value'>
                  {settledCash === '-'
                    ? '-'
                    : this.props.formatCurrency(settledCash)}
                </p>
              </div>
            )}
            {this.props.selectedName === 'STOCK' && (
              <div className='details-row first'>
                <p className='details-label'>
                  {this.props.t('unsettledcashavailableby')}:
                </p>
                <p className='details-value'>
                  {cashSettlementDate === '-' ? '-' : cashSettlementDate}
                </p>
              </div>
            )}
            <div className='details-row'>
              <p className='details-label'>
                {this.props.t('cashavailableforwithdraw')}:
              </p>
              <p className='details-value'>
                {cashAvailableForWithdraw === '-'
                  ? '-'
                  : this.props.formatCurrency(cashAvailableForWithdraw)}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="non-product-basic-info-container">
          <p>{this.props.selectedName}</p>
        </div>
        <div>
          <div>
            <p>Total Account Value: </p>
            <p>{this.props.formatCurrency(totalAccountValue)}</p>
          </div>
          <div>
            <p>Equity Value: </p>
            <p style={{ fontSize: "17px", color: "grey" }}>
              {this.props.formatCurrency(equityValue)}
            </p>
          </div>
          <div>
            <p>Net P/L: </p>
            {netPL === "-" ? (
              <p>{"-"}</p>
            ) : (
              <PL formatCurrency={this.props.formatCurrency} value={netPL} />
            )}
          </div>
        </div> */}
      </div>
    )
  }
  render() {
    if (this.props.t) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '100%',
          }}
        >
          {this.displayNonProductBasicInfo()}
        </div>
      )
    }
  }
}
