/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import ProductActivationBackIcon from '../../../../../assets/product-activation-back-icon.svg'
import Loader from '../../Loader'
import InvestmentForm from './InvestmentForm'
import Summary from './Summary'
import axios from 'axios'
import Endpoint from '../../../../Endpoint'
import PartialInvestmentForm from './PartialInvestmentForm'

export default function RoboActivation2(props) {
  const [pageHistory, setPageHistory] = useState([])

  const [startingAmount, setStartingAmount] = useState(0)
  const [contributionAmount, setContributionAmount] = useState(0)
  const [roundUp, setRoundUp] = useState(false)
  const [selectedBankAccount, setSelectedBankAccount] = useState(null)
  const [selectedTransferAccount, setSelectedTransferAccount] = useState(null)

  const addHistory = (page) => {
    let temp_pageHistory = pageHistory
    temp_pageHistory.push(page)
    setPageHistory([...temp_pageHistory])
  }

  const goPrevious = () => {
    let temp_pageHistory = pageHistory
    temp_pageHistory.pop()
    setPageHistory([...temp_pageHistory])
  }

  const updateInvestmentDetails = (
    startingAmount,
    contributionAmount,
    roundUp
  ) => {
    setStartingAmount(startingAmount)
    setContributionAmount(contributionAmount)
    setRoundUp(roundUp)
  }

  const handleNextStep = () => {
    if (
      startingAmount !== props.selectedAccount?.balances[0]?.virtualAccountValue
    ) {
      addHistory('Partial Investment')
    } else {
      addHistory('Summary')
    }
  }

  const getAlpacaACHAccounts = async () => {
    try {
      let res = await axios.get(
        Endpoint +
          '/mobile/alpaca/ach-relationship/list?account_id=' +
          props.selectedAccount?.accountId,
        {
          headers: {
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )
      console.log('ach list res', res?.data?.output)
      setSelectedBankAccount(res?.data?.output[0])
    } catch (error) {
      console.error(error)
      return []
    }
  }

  useEffect(() => {
    getAlpacaACHAccounts()
  }, [])

  useEffect(() => {
    if (startingAmount > 0) {
      handleNextStep()
    }
  }, [startingAmount])

  const currentRoboPage = () => {
    switch (pageHistory[pageHistory.length - 1]) {
      case 'Partial Liquidation':
      case 'Partial Investment':
        return (
          <PartialInvestmentForm
            {...props}
            startingAmount={startingAmount}
            totalAccountValue={
              props?.selectedAccount?.balances?.[0]?.virtualAccountValue
            }
            selectedBankAccount={selectedBankAccount}
            addHistory={addHistory}
            selectedTransferAccount={selectedTransferAccount}
            setSelectedTransferAccount={setSelectedTransferAccount}
          />
        )
      case 'Summary':
        return (
          <Summary
            {...props}
            selectedTransferAccount={selectedTransferAccount}
            roundUp={roundUp}
            contributionAmount={contributionAmount}
            selectedBankAccount={selectedBankAccount}
            toggleActivation={props.toggleActivation}
            startingAmount={startingAmount}
          />
        )
      default:
        return (
          <InvestmentForm
            {...props}
            updateInvestmentDetails={updateInvestmentDetails}
            handleNextStep={handleNextStep}
          />
        )
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: 6,
          alignItems: 'center',
          marginTop: 10,
          cursor: 'pointer',
        }}
        onClick={() => {
          if (props.selectedName === 'ROBO' && pageHistory.length === 0) {
            props.showButtons()
          }
          if (pageHistory.length > 0) {
            goPrevious()
          } else {
            props.toggleActivation()
          }
        }}
      >
        <img src={ProductActivationBackIcon} height={25} width={25} alt='' />
        <p>Back</p>
      </div>

      {props.productInstanceData && props.strategyInfo ? (
        currentRoboPage()
      ) : (
        <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false} />
      )}
    </div>
  )
}
