import React, { Component } from 'react'
import ENDPOINT from '../Endpoint'
import NonProductAccountInfo from './accountInfo/NonProductAccountInfo'
import GoalsAccountInfo from './accountInfo/GoalsAccountInfo'
import RoboAccountInfo from './accountInfo/RoboAccountInfo'
import GroupAccountInfo from './accountInfo/GroupAccountInfo'
import InactiveAccountInfo from './accountInfo/InactiveAccountInfo'
import GroupCryptoAccountInfo from './accountInfo/GroupCryptoAccountInfo'

export default class AccountInfo extends Component {
  state = {
    isProduct:
      this.props.selectedName === 'STOCK' ||
      this.props.selectedName === 'CRYPTO'
        ? false
        : true,
    showAllInvestmentList: false,
    showAllRecurringList: false,
    showAllPendingOrders: false,
    showAllPendingTransfers: false,
    showStats: null,
    accountTransactionsData: null,
    productInstanceData: null,
    deleteRecurBuyStatus: null,
    cancelOrderStatus: null,
    editRecBuyStatus: null,
    strategyInfo: null,
    editContributionStatus: null,
  }

  componentDidMount() {
    this.getStats()
    this.getAccountTransactionsData()
    this.getStrategyInfo()
    this.getStatements()
    //GET THE RIGHT INSTACE DATA DEPENDING ON PASSED IN PROP NAME
    if (this.state.isProduct) {
      if (this.props.selectedName === 'AI STOCK TRADER')
        this.getAItraderInstance()
      else if (this.props.selectedName === 'AI CRYPTO TRADER')
        this.getAItraderCryptoInstance()
      else if (this.props.selectedName === 'ROBO') this.getRoboInstance()
      else this.getGoalInstance()
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.selectedAccount !== this.props.selectedAccount) {
      this.getStats()
      this.getAccountTransactionsData()
      this.getStrategyInfo()
      this.getStatements()
      if (this.state.isProduct) {
        if (this.props.selectedName === 'AI STOCK TRADER')
          this.getAItraderInstance()
        else if (this.props.selectedName === 'AI CRYPTO TRADER')
          this.getAItraderCryptoInstance()
        else if (this.props.selectedName === 'ROBO') this.getRoboInstance()
        else this.getGoalInstance()
      }
    }
  }

  getStatements = (id) => {
    let url = `${ENDPOINT}/mobile/alp/documents/${this.props.selectedAccount.accountId}`
    fetch(url, {
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        this.setState({
          statements: responseJson?.payload,
        })
      })
      .catch((err) => {
        console.log('get statements error', err)
      })
  }

  resetEditContributionModal = () => {
    this.setState({
      editContributionStatus: null,
    })
  }

  editContributionRoundUp = (dw_account_id, plaid_account_id) => {
    this.setState({
      editContributionStatus: 'loading',
    })

    let params = {
      account_id: dw_account_id,
      plaid_account_id: plaid_account_id,
    }
    fetch(
      `${ENDPOINT}/mobile/roundup/activate?account_id=${dw_account_id}&plaid_account_id=${plaid_account_id}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((responseJson) => {
        this.getRoboInstance()
        this.setState({
          editContributionStatus: 'success',
        })
      })
      .catch((err) => {
        console.log('get round up activate error message: ', err)
        this.setState({
          editContributionStatus: 'error',
        })
      })
  }

  editContributionPeriodic = (dw_account_id, plaid_account_id, amount) => {
    this.setState({
      editContributionStatus: 'loading',
    })

    let body = {
      dwAccountID: dw_account_id,
      newAmount: parseFloat(amount),
      plaidAccountID: plaid_account_id,
    }
    fetch(`${ENDPOINT}/mobile/adjust/recurring`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((responseJson) => {
        this.getRoboInstance()
        this.setState({
          editContributionStatus: 'success',
        })
      })
      .catch((err) => {
        console.log('get periodic error message: ', err)
        this.setState({
          editContributionStatus: 'error',
        })
      })
  }

  formatCurrency(val) {
    const options = { style: 'currency', currency: 'USD' }
    const numberFormat = new Intl.NumberFormat('en-US', options)
    if (!val) return numberFormat.format(0)
    return numberFormat.format(val)
  }

  getStats = () => {
    this.setState({ showStats: 'loading' })
    let body = {
      portfolio: [],
      data: {},
      request: {
        type: 'portfolio',
        id: this.props.selectedAccount.accountId,
      },
    }

    // fetch(`${ENDPOINT}/risk-monkey`, {
    //   method: 'POST',
    //   headers: {
    //     Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(body),
    // })
    //   .then((res) => res.json())
    //   .then((responseJson) => {
    //     this.setState({ showStats: responseJson })
    //   })
    //   .catch((err) => console.log('get stats error message: ', err))
  }

  getStrategyInfo = () => {
    fetch(`${ENDPOINT}/mobile/strategy-returns`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((responseJson) => {
        this.setState({ strategyInfo: responseJson })
      })
      .catch((err) => console.log('get strategy info error message: ', err))
  }

  getAccountTransactionsData = () => {
    fetch(
      `${ENDPOINT}/mobile/user/account-info?account_id=${this.props.selectedAccount.accountId}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
      }
    )
      .then((response) => response.json())
      .then((resp) => {
        this.setState({ accountTransactionsData: resp.payload })
      })
      .catch((err) => {
        console.log('get instance data error message: ', err)
      })
  }

  getAItraderInstance = () => {
    fetch(
      `${ENDPOINT}/mobile/get-trade-group/${this.props.selectedAccount.accountId}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
      }
    )
      .then((response) => response.json())
      .then((resp) => this.setState({ productInstanceData: resp.payload }))
      .catch((err) => console.log('get instance data error message: ', err))
  }

  getAItraderCryptoInstance = () => {
    fetch(
      `${ENDPOINT}/mobile/get-trade-group/${this.props.selectedAccount.accountId}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
      }
    )
      .then((response) => response.json())
      .then((resp) => this.setState({ productInstanceData: resp.payload }))
      .catch((err) => console.log('get instance data error message: ', err))
  }

  getRoboInstance = () => {
    fetch(`${ENDPOINT}/mobile/robo/stats`, {
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((resp) => this.setState({ productInstanceData: resp }))
      .catch((err) => console.log('get instance data error message: ', err))
  }

  getGoalInstance = () => {
    fetch(`${ENDPOINT}/mobile/goal-planning/data`, {
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((resp) => this.setState({ productInstanceData: resp }))
      .catch((err) => console.log('get instance data error message: ', err))
  }

  deleteRec = (sym) => {
    this.setState({ deleteRecurBuyStatus: 'loading' })
    let body = {
      dw_account_id: this.props.selectedAccount.accountId,
      stock: sym,
    }
    fetch(`${ENDPOINT}/mobile/recurring/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((responseJson) => {
        if (responseJson.Status === 'Success') {
          // let rts = this.state.rts.filter((r) => r.ticker !== sym);
          // this.setState({ rts });
          this.setState({
            deleteRecurBuyStatus: 'success',
          })
          this.getAccountTransactionsData()
        }
      })
      .catch((err) => {
        this.setState({
          deleteRecurBuyStatus: 'error',
          errors: 'Something went wrong, please try again later',
        })
      })
  }

  resetDeleteModal = () => {
    this.setState({
      deleteRecurBuyStatus: null,
    })
  }

  setRecBuy = (
    dw_account_id,
    plaid_account_id,
    periodic,
    stock,
    roundup,
    frequency
  ) => {
    this.setState({ editRecBuyStatus: 'loading' })
    let body = {
      dw_account_id: dw_account_id,
      plaid_account_id: plaid_account_id,
      periodic: periodic,
      stock: stock,
      roundup: roundup,
      frequency: frequency,
    }
    return fetch(`${ENDPOINT}/mobile/recurring/buy-new`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((responseJson) => {
        if (responseJson.Status === 'Success') {
          this.setState({ editRecBuyStatus: 'success' })
          this.getAccountTransactionsData()
          return true
        } else {
          this.setState({ editRecBuyStatus: 'error' })
          this.setState({ errors: responseJson.status })
          return false
        }
      })
      .catch((err) => {
        this.setState({
          errors: 'Something went wrong, please try again later',
        })
        return false
      })
  }

  resetEditRecurBuyModal = () => {
    this.setState({
      editRecBuyStatus: null,
    })
  }

  cancelOrder = (id) => {
    this.setState({ cancelOrderStatus: 'loading' })
    let url = `${ENDPOINT}/mobile/order-cancel?order_id=${id}`
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        if (responseJson.status === 'Success') {
          // let filtered = this.state.ordersResp.pendingOrders.filter(
          //   (ele) => ele.orderID !== id
          // );
          // let ordersResp = {
          //   ...this.state.ordersResp,
          //   pendingOrders: filtered,
          // };
          // this.setState({ ordersResp });
          this.setState({ cancelOrderStatus: 'success' })
          this.getAccountTransactionsData()
        }
      })
      .catch((err) => {
        this.setState({ cancelOrderStatus: 'error' })
        console.log('cancel error: ', err)
      })
  }

  resetCancelOrderModal = () => {
    this.setState({
      cancelOrderStatus: null,
    })
  }

  allocationChart() {
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '30%',
          boxShadow: '0px 5px 15px 0px #c4c4c4',
          borderRadius: '25px',
          padding: '25px',
          display: 'block',
        }}
      >
        {/* <AllocationPieChart showStats = {this.state.showStats} /> */}
      </div>
    )
  }

  activateProduct() {
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '30%',
          boxShadow: '0px 5px 15px 0px #c4c4c4',
          borderRadius: '25px',
          padding: '25px',
          justifyContent: 'center',
        }}
      >
        <p
          style={{ fontWeight: 'bold', fontSize: '15px', textAlign: 'center' }}
        >
          Product
        </p>
        <button
          style={{
            border: '1px solid grey',
            borderRadius: '25px',
            background: 'transparent',
            width: '80%',
            margin: '0 auto',
            display: 'block',
          }}
        >
          + {this.props.selectedName}
        </button>
      </div>
    )
  }

  renderAccountPage() {
    if (
      this.props.selectedName === 'CRYPTO' ||
      this.props.selectedName === 'STOCK'
    ) {
      return (
        <NonProductAccountInfo
          {...this.props}
          {...this.state}
          deleteRec={this.deleteRec}
          resetDeleteModal={this.resetDeleteModal}
          cancelOrder={this.cancelOrder}
          resetCancelOrderModal={this.resetCancelOrderModal}
          setRecBuy={this.setRecBuy}
          resetEditRecurBuyModal={this.resetEditRecurBuyModal}
          formatCurrency={this.formatCurrency}
          toggleAccount={this.props?.toggleAccount}
          alpacaStatus={this.props?.alpacaStatus}
        />
      )
    } else {
      if (this.props.selectedAccount.product) {
        if (this.props.selectedName === 'AI STOCK TRADER') {
          return (
            <GroupAccountInfo
              {...this.props}
              {...this.state}
              formatCurrency={this.formatCurrency}
              toggleAccount={this.props?.toggleAccount}
              alpacaStatus={this.props?.alpacaStatus}
            />
          )
        } else if (this.props.selectedName === 'AI CRYPTO TRADER') {
          return (
            <GroupCryptoAccountInfo
              {...this.props}
              {...this.state}
              formatCurrency={this.formatCurrency}
              toggleAccount={this.props?.toggleAccount}
              alpacaStatus={this.props?.alpacaStatus}
            />
          )
        } else if (this.props.selectedName === 'ROBO') {
          return (
            <RoboAccountInfo
              {...this.props}
              {...this.state}
              resetEditContributionModal={this.resetEditContributionModal}
              editContributionRoundUp={this.editContributionRoundUp}
              editContributionPeriodic={this.editContributionPeriodic}
              formatCurrency={this.formatCurrency}
              toggleAccount={this.props?.toggleAccount}
              alpacaStatus={this.props?.alpacaStatus}
            />
          )
        } else {
          return (
            <GoalsAccountInfo
              {...this.props}
              {...this.state}
              formatCurrency={this.formatCurrency}
              toggleAccount={this.props?.toggleAccount}
              alpacaStatus={this.props?.alpacaStatus}
            />
          )
        }
      } else {
        return (
          <InactiveAccountInfo
            {...this.props}
            {...this.state}
            getRoboInstance={() => this.getRoboInstance()}
            formatCurrency={this.formatCurrency}
            toggleAccount={this.props?.toggleAccount}
            alpacaStatus={this.props?.alpacaStatus}
          />
        )
      }
    }
  }

  render() {
    if (this.props.t) {
      return <div style={{ height: '100%' }}>{this.renderAccountPage()}</div>
    } else return null
  }
}

// const mapStateToProps = (state) => {
//   return {
//     accounts: state.account.accounts
//   };
// };

// export default connect(mapStateToProps, actions)(AccountInfo);
