import React, { Component } from 'react'
import Loader from './Loader'
import NonProductBasicInfo from './nonProduct/NonProductBasicInfo'
import NonProdInvestments from './nonProduct/NonProdInvestments'
import NonProdRiskGraph from './nonProduct/NonProdRiskGraph'
import NonProdHistGraph from './nonProduct/NonProdHistGraph'
import NonProdRecurBuys from './nonProduct/NonProdRecurBuys'
import NonProdPendingTransfers from './nonProduct/NonProdPendingTransfers'
import NonProdPendingOrders from './nonProduct/NonProdPendingOrders'
import OrderHistory from './OrderHistory'
import TransferHistory from './TransferHistory'
import TransferFundsDW from './TransferFundsDW'
import Statements from '../Statements'
import TransferFundsACH from './TransferFundsACH'
import ProductActivationBackIcon from '../../../assets/product-activation-back-icon.svg'
import AccountActivity from '../AccountActivity'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../../utility-functions/tabStyles'
import AccountStatusMessage from './AccountStatusMessage'
import NonProdLimitOrders from './nonProduct/NonProdLimitOrders'

export default class NonProductAccountInfo extends Component {
  state = {
    activeTab: 'General Info',
  }

  tabs() {
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
          alignItems: 'center',
          height: '30px',
          margin: 'auto',
        }}
      >
        <div
          onClick={() => this.setState({ activeTab: 'General Info' })}
          style={
            this.state.activeTab === 'General Info'
              ? selectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'General Info'
                ? selectedText
                : unSelectedText
            }
          >
            {/* {this.props.selectedName} */}
            {this.props.t('summary')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Transfer Funds' })}
          style={
            this.state.activeTab === 'Transfer Funds'
              ? selectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Transfer Funds'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('transferfunds')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Account Activity' })}
          style={
            this.state.activeTab === 'Account Activity'
              ? selectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Account Activity'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('accountactivity')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Documents' })}
          style={
            this.state.activeTab === 'Documents' ? selectedTab : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Documents'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('documents')}
          </p>
        </div>
      </div>
    )
  }

  showSelectedTab = () => {
    if (this.state.activeTab === 'General Info') return this.generalInfo()
    else if (this.state.activeTab === 'Transfer Funds') {
      if (this.props.selectedName === 'STOCK')
        return (
          <TransferFundsDW
            accounts={this.props.accounts}
            isSmallScreen={this.props.isSmallScreen}
            formatCurrency={this.props.formatCurrency}
            user={this.props.user}
            getAllAccountInfo={this.props.getAllAccountInfo}
            userType={this.props.userType}
            t={this.props.t}
            selectedAccount={this.props.selectedAccount}
            alpacaStatus={this.props?.alpacaStatus}
            accountType={'Stock'}
          />
        )
      else
        return (
          <TransferFundsACH
            isSmallScreen={this.props.isSmallScreen}
            formatCurrency={this.props.formatCurrency}
            user={this.props.user}
            accounts={this.props.accounts}
            getAllAccountInfo={this.props.getAllAccountInfo}
            cryptoAccount={this.props.selectedAccount}
            t={this.props.t}
          />
        )
    } else if (this.state.activeTab === 'Account Activity')
      return (
        <div style={{ height: this.props.isSmallScreen ? '100vh' : '50vh' }}>
          {this.props.accountTransactionsData ? (
            <AccountActivity {...this.props} />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else if (this.state.activeTab === 'Documents')
      return (
        <div>
          {this.props.statements ? (
            <Statements
              statements={this.props.statements}
              selectedAccount={this.props.selectedAccount}
              user={this.props.user}
              t={this.props.t}
            />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else return null
  }

  generalInfo() {
    return (
      <div>
        <AccountStatusMessage
          status={this.props.selectedAccount?.status}
          t={this.props.t}
        />
        <NonProductBasicInfo {...this.props} />
        <NonProdInvestments {...this.props} />
        {this.props.accountTransactionsData ? (
          <>
            <NonProdLimitOrders {...this.props} />
            <NonProdPendingOrders {...this.props} />
            <NonProdPendingTransfers {...this.props} />
            <NonProdRecurBuys {...this.props} />
          </>
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false} />
        )}
        {/* {this.props.accountTransactionsData ? (
          <NonProdPendingOrders {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )}
        {this.props.accountTransactionsData ? (
          <NonProdPendingTransfers {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )}
        {this.props.accountTransactionsData ? (
          <NonProdRecurBuys {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )} */}
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return (
        <div>
          <div
            style={{
              width: this.props.isSmallScreen ? '100%' : '60%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                gap: '0.5rem',
              }}
            >
              <img
                src={ProductActivationBackIcon}
                height={25}
                width={25}
                onClick={() => {
                  this.props.resetSelectedAccount()
                  if (this.props?.toggleAccount) {
                    this.props.toggleAccount()
                  }
                }}
                style={{ cursor: 'pointer', marginBottom: '25px' }}
              />
              <p style={{ paddingTop: '0.2rem' }}>
                {' '}
                {this.props?.t('allaccounts')}
              </p>
            </div>
            {this.tabs()}
            <div
              style={{
                width: this.props.isSmallScreen ? '100%' : '80%',
                margin: 'auto',
                height: '100%',
              }}
            >
              {this.showSelectedTab()}
            </div>
          </div>
        </div>
      )
    }
  }
}

// const mapStateToProps = (state) => {
//   return {
//     accounts: state.account.accounts
//   };
// };

// export default connect(mapStateToProps, actions)(NonProductAccountInfo);
