import React, { Component } from 'react'
import '../../../../../scss/pages/account-activation/robo/Summary.scss'
import { Button } from 'primereact/button'
import Axios from 'axios'
import Endpoint from '../../../../Endpoint'
import ConfirmationDialog from '../../../ConfirmationDialog'
import PersonalizationForm from '../../../robo/PersonalizationForm'

export default class Summary extends Component {
  state = {
    successMessage: '',
    showConfirmationDialog: false,
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (prevProps.accounts !== this.props.accounts) {
  //     this.setState({ isLoading: false });
  //     this.props.toggleActivation();
  //   }
  // };

  startRoboAPI = async () => {
    let body = {
      dw_account_id: this.props.selectedAccount.accountId,
      strategyID: this.props.productInstanceData.strategyInfo?.id,
      amount: this.props.startingAmount,
    }
    if (this.props.selectedBankAccount) {
      body.plaid_account_id = this.props.selectedBankAccount.id
      if (this.props.roundUp) {
        body.roundup = this.props.roundUp
      } else body.periodic = this.props.contributionAmount
    }

    // check if this is partial investment
    if (
      this.props.selectedAccount?.balances?.[0]?.virtualAccountValue !==
      this.props.startingAmount
    ) {
      body.transfer_to = this.props.selectedTransferAccount?.id
      body.type = this.props.selectedTransferAccount?.type
      body.liquidate_amount =
        this.props.selectedAccount?.balances?.[0]?.virtualAccountValue -
        this.props.startingAmount
    }
    console.log('robo body', body)
    return await Axios.post(`${Endpoint}/mobile/alpaca/robo/activate`, body, {
      headers: {
        Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
      },
    })
      .then((response) => {
        if (response?.data?.activation) {
          // window.location.reload()
          this.props.getAllAccountInfo()
          return true
        } else {
          return false
        }
      })
      .catch((error) => {
        console.log('robo activation error', error)
        return false
      })
  }

  toggleShowConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: !this.state.showConfirmationDialog,
    })
  }

  showPeriodicContribution = () => {
    if (this.props.selectedBankAccount) {
      if (this.props.roundUp) {
        return 'Weekly Roundup'
      } else {
        return `${this.props.formatCurrency(
          this.props.contributionAmount
        )} (Monthly)`
      }
    } else {
      return '-'
    }
  }

  submitButton = () => {
    return (
      <div className='submit-button-container'>
        <Button
          label={this.props.t('activaterobo')}
          className='submit-button'
          onClick={() => {
            this.toggleShowConfirmationDialog()
          }}
        />
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return (
        <div className='summary-container'>
          <p className='header'>{this.props.t('investallrobo')}</p>
          <div className='summary-row'>
            <p className='sumary-label'>{this.props.t('investmentamount')}: </p>
            <p className='summary-value'>
              {this.props.formatCurrency(this.props.startingAmount)}
            </p>
          </div>
          <div className='summary-row'>
            <p className='sumary-label'>
              {this.props.t('periodiccontribution')}:{' '}
            </p>
            <p className='summary-value'>{this.showPeriodicContribution()}</p>
          </div>
          <div className='summary-row'>
            <p className='sumary-label'>
              {this.props.t('strategyusedbyrobo')}:{' '}
            </p>
            <p className='summary-value'>
              {
                this.props.strategyInfo[
                  this.props.productInstanceData.strategyInfo?.id
                ]?.fullname
              }
            </p>
          </div>
          {this.submitButton()}
          <ConfirmationDialog
            isSmallScreen={this.props.isSmallScreen}
            toggleShowConfirmationDialog={this.toggleShowConfirmationDialog}
            showConfirmationDialog={this.state.showConfirmationDialog}
            name={this.props.t('startrobo')}
            action={this.startRoboAPI}
            successMessage={this.props.t('producthasbeensuccessfullyactivated')}
            btnClassName='robo'
            t={this.props.t}
          />
        </div>
      )
    } else return null
  }
}
